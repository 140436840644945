import Notification from '../../Molecules/Global/Notification';
import ConfirmModal from './ConfirmModal';

interface OverlayContainerProps {}
const OverlayContainer = (props: OverlayContainerProps) => {
  return (
    <>
      <ConfirmModal />
      <Notification />
    </>
  );
};

export default OverlayContainer;
