import ContextMenu from '../../Molecules/Global/ContextMenu';
import useMoveTo from '../../Hooks/Moveto';
import { definitions } from '../../../Utils/types';
import useTranslation from '../../Hooks/Translate';
import useSearchUrlParam from '../../Hooks/SearchUrlParam';

type Dashboard = definitions['dashboards'];

interface AnalyticsMenuProps {
  currentDashboard?: Dashboard;
  className?: string;
  onClose?: () => void;
}
const AnalyticsMenu = (props: AnalyticsMenuProps) => {
  const t = useTranslation('Analytics');
  const moveTo = useMoveTo();
  const searchUrlParam = useSearchUrlParam();

  const onClickMoveDashboardAdmin = () => {
    const { onClose, currentDashboard } = props;

    onClose?.();

    moveTo(
      'dashboard-admin',
      currentDashboard ? [{ key: 'id', value: currentDashboard.id }] : undefined
    );
  };

  const onClickMoveLayoutEdit = async () => {
    moveTo('analytics', [{ key: 'edit', value: '1' }]);

    props.onClose?.();
  };

  const onClickSaveLayoutEdit = async () => {
    moveTo('analytics');

    props.onClose?.();
  };

  const onClickPrint = async () => {
    props.onClose?.();
  };

  const onClickDownloadCSV = async () => {
    props.onClose?.();
  };

  return (
    <ContextMenu
      items={[
        {
          title: t('menu.dashboardedit'),
          onClick: onClickMoveDashboardAdmin,
        },
        searchUrlParam('edit') === '1'
          ? {
              title: t('menu.layouteditOff'),
              onClick: onClickSaveLayoutEdit,
            }
          : {
              title: t('menu.layouteditOn'),
              onClick: onClickMoveLayoutEdit,
            },
        // {
        //   title: t('menu.print'),
        //   onClick: onClickPrint,
        // },
        // {
        //   title: t('menu.csv'),
        //   onClick: onClickDownloadCSV,
        // },
      ]}
      width={300}
      top={70}
      right={20}
      onClose={props.onClose}
    />
  );
};

export default AnalyticsMenu;
