import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MUICheckbox from '@material-ui/core/Checkbox';

interface CheckboxProps {
  label?: string;
  defaultChecked?: boolean;
  disabled?: boolean;
  onChange?: (checked: boolean, event: React.ChangeEvent) => void;
  checked?: boolean;
  color?: 'primary' | 'secondary' | 'default';
  horizon?: boolean;
}

const styles = makeStyles({
  checkboxlabelHorizon: {
    display: 'contents',
  },
  checkedlabelVertical: {
    display: 'block',
    textIndent: '-44px',
    paddingLeft: '44px',
  },
});

const Checkbox = (props: CheckboxProps) => {
  const classes = styles();

  return (
    <FormControlLabel
      className={
        props.horizon
          ? classes.checkboxlabelHorizon
          : classes.checkedlabelVertical
      }
      disabled={props.disabled}
      label={props.label}
      control={
        <MUICheckbox
          onChange={(ev, checked) => props.onChange?.(checked, ev)}
          color={props.color}
        />
      }
      checked={props.checked}
      defaultChecked={props.defaultChecked}
    />
  );
};

export default Checkbox;
