/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles } from '@material-ui/core';
import React from 'react';
import ModalBase from '../../Molecules/Global/ModalBase';
import { Container, Tab, Tabs } from '@material-ui/core';
import GadgetList from '../../Molecules/Analytics/GadgetList';
import { definitions } from '../../../Utils/types';
import {
  GadgetCategory,
  useGetGadgets,
} from '../../Hooks/AccessGadgetFacetMaster';
import useTranslation from '../../Hooks/Translate';

type Gadget = definitions['dashboard_gadgets']['gadget'];

interface EditGadgetModalProps {
  alreadyExistings: Gadget[];
  onCancel: () => void;
  onAdd: (selected: Gadget) => void;
  open: boolean;
}

const styles = makeStyles({
  tabs: {
    width: '630px',
    margin: 'auto',
  },
});
const EditGadgetModal = (props: EditGadgetModalProps) => {
  const classes = styles();
  const getGadgets = useGetGadgets();
  const t = useTranslation('Analytics');

  const categories: GadgetCategory[] = ['chart', 'table', 'kpi', 'pie'];

  const [tabIdx, setTabIdx] = React.useState(0);

  const onClickAdd = (gadget: Gadget) => {
    props.onAdd(gadget);
  };
  const onClickCancel = () => {
    props.onCancel();
  };
  const onChangeTab = (ev: any, newValue: number) => {
    setTabIdx(newValue);
  };

  return (
    <ModalBase
      open={props.open}
      title={t('gadgets.edit.title')}
      onClickCancel={onClickCancel}
      maxWidth="md"
      footerColor="#bbb"
    >
      <Container>
        <Tabs className={classes.tabs} value={tabIdx} onChange={onChangeTab}>
          <Tab label={t('gadgets.edit.label.chart')} key={categories[0]} />
          <Tab label={t('gadgets.edit.label.table')} key={categories[1]} />
          <Tab label={t('gadgets.edit.label.kpi')} key={categories[2]} />
          <Tab label={t('gadgets.edit.label.pie')} key={categories[3]} />
        </Tabs>
        <GadgetList
          alreadyExistings={props.alreadyExistings}
          gadgets={getGadgets(categories[tabIdx])}
          onSelect={onClickAdd}
        />
      </Container>
    </ModalBase>
  );
};
export default EditGadgetModal;
