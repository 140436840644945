import { HeadsetMic, Equalizer, Settings, Person } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import React, { ReactElement } from 'react';
import UserMenu from '../../Organisms/Global/UserMenu';
import useMoveTo from '../../Hooks/Moveto';
import useConfirm from '../../Hooks/Confirm';
import { AgentContext, PermissionContext } from '../../../WithContext';
import useTranslation from '../../Hooks/Translate';

const style = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      height: '100%',
    },
    nav: {
      width: '50px',
      height: '50px',
    },
    accountUserMenu: {
      position: 'absolute',
      top: '10px',
      right: '10px',
    },
  })
);

export type NavigationType = 'session' | 'analytics' | 'settings' | 'account';

interface GlobalNavigationProps {
  onClick?: (navigatedTo: NavigationType) => void;
}
const GlobalNavigation = (props: GlobalNavigationProps) => {
  const classes = style();
  const [userMenu, setUserMenu] = React.useState<ReactElement | null>();
  const moveTo = useMoveTo();
  const confirm = useConfirm();
  const agent = React.useContext(AgentContext);
  const permission = React.useContext(PermissionContext);

  const t = useTranslation('Global');

  const handelClick = async (navigatedTo: NavigationType) => {
    const { onClick } = props;

    if (navigatedTo !== 'session' && agent?.isSessionActive()) {
      if (
        (await confirm(
          t('confirm.disconnectWithMoveMessage'),
          t('confirm.disconnectWithMoveApprove')
        )) === false
      ) {
        return;
      } else {
        // 現在のセッションを終了する。
        agent?.endSession();
        agent?.resetSdk();
      }
    }

    // FIXME: アカウントページは未実装のため、ひとまずユーザメニューをだすだけ
    if (navigatedTo === 'account') {
      setUserMenu(
        <UserMenu
          className={classes.accountUserMenu}
          onClose={() => {
            setUserMenu(null);
          }}
        />
      );
      return;
    }

    onClick?.(navigatedTo);
    moveTo(navigatedTo);
  };

  return (
    <div className={classes.wrapper}>
      <IconButton
        className={classes.nav}
        onClick={() => handelClick('session')}
      >
        <HeadsetMic />
      </IconButton>
      {permission?.isAdmin() && (
        <IconButton
          className={classes.nav}
          onClick={() => handelClick('analytics')}
        >
          <Equalizer />
        </IconButton>
      )}
      {permission?.isAdmin() && (
        <IconButton
          className={classes.nav}
          onClick={() => handelClick('settings')}
        >
          <Settings />
        </IconButton>
      )}

      <IconButton
        className={classes.nav}
        onClick={() => handelClick('account')}
      >
        <Person />
      </IconButton>

      {userMenu}
    </div>
  );
};

export default GlobalNavigation;
