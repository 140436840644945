import { authProvider } from './authProvider';

export default class OperatorInfo {
  private _id: string;
  private _name: string;

  private static readonly GUEST_ID = '';
  private static readonly GUEST_NAME = 'guest';

  constructor(id: string, name: string) {
    this._id = id;
    this._name = name;
  }

  get id() {
    return this._id;
  }
  get name() {
    return this._name;
  }

  static createGuest() {
    return new OperatorInfo(this.GUEST_ID, this.GUEST_NAME);
  }

  static async load() {
    try {
      const info = await authProvider.getIdentity?.();
      if (info) {
        return new OperatorInfo(info.id.toString(), info.fullName || '');
      } else {
        throw new Error();
      }
    } catch (e) {
      throw e;
    }
  }
}
