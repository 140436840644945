import * as React from 'react';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    setInnerContent: {
      width: '100%',
      marginTop: '5px',
    },
    title: {
      fontSize: '16px',
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
  })
);

interface SelectedGroupsContentItem {
  displayName: string;
  id: string;
}

interface SelectGroupsContentProps {
  title?: string;
  caption: string;
  onChange: (event: { target: { value: any } }) => void;
  items: SelectedGroupsContentItem[];
  selectedItemIds: string[];
  disabled?: boolean;
}

const SelectGroupsContent = (props: SelectGroupsContentProps) => {
  const classes = useStyles();
  const { items, selectedItemIds, onChange } = props;

  const renderSelectedItem = (selectedId: string) => {
    return (
      <Box className={classes.chips}>
        {selectedItemIds.map((sjId) => {
          const item = items.find((j) => sjId === j.id);
          return (
            <Chip
              key={item?.id}
              label={item?.displayName}
              style={{ margin: '1px' }}
            />
          );
        })}
      </Box>
    );
  };

  return (
    <>
      {props.title && (
        <Typography variant="h3" className={classes.title}>
          {props.title}
        </Typography>
      )}
      <FormControl className={classes.setInnerContent}>
        <InputLabel
          id="demo-multiple-chip-label"
          style={{ marginLeft: '10px' }}
        >
          {props.caption}
        </InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={selectedItemIds}
          disabled={props.disabled}
          onChange={onChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selectedId: any) =>
            renderSelectedItem(selectedId as string)
          }
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 'max-content',
                maxWidth: '90%',
                minWidth: '256px',
              },
            },
          }}
        >
          {items.map((j) => (
            <MenuItem key={j.id} value={j.id}>
              <Checkbox
                checked={
                  selectedItemIds.findIndex((itemId) => itemId === j.id) >= 0
                }
              />
              <ListItemText primary={j.displayName} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default SelectGroupsContent;
