import { ReactElement } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box, CssBaseline } from '@material-ui/core';
import React from 'react';

const style = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      overflow: 'hidden',
    },
    header: {
      height: '60px',
      borderBottom: '1px solid gray',
    },
    body: {
      height: 'calc(100vh - 60px)',
    },
  })
);

interface WithLayoutProps {
  header?: ReactElement;
  sidebar?: ReactElement;
  footer?: ReactElement;
  children?: JSX.Element;
}
const WithLayout = (props: WithLayoutProps) => {
  const classes = style();

  return (
    <Box className={classes.wrapper}>
      <CssBaseline />
      {props.header && <Box className={classes.header}>{props.header}</Box>}
      <Box className={classes.body}>{props.children}</Box>
    </Box>
  );
};

export default WithLayout;
