const useSearchUrlParam = () => {
  return (key: string, separator?: string) => {
    const urlSplited = window.location.href.split(separator || '?');
    if (urlSplited.length <= 1) {
      return null;
    } else {
      const queries = urlSplited[1].split('&');
      for (let i = 0; i < queries.length; i++) {
        const q = queries[i];
        const param = q.split('=');
        if (param[0] === key) {
          return param[1];
        }
      }
      return null;
    }
  };
};

export default useSearchUrlParam;
