import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import PageLogs from './PageLogs';
import ActionLogs from './ActionLogs';
import { AgentContext } from '../../..//WithContext';

const style = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      position: 'relative',
      height: 'max-content',
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
    },
    loading: {
      margin: '10px auto',
    },
  })
);

interface ActionLogPaneProps {}
const ActionLogPane = (props: ActionLogPaneProps) => {
  const classes = style();

  const [actionLogStart, setActionLogStart] = React.useState(new Date());
  const [actionLogEnd, setActionLogEnd] = React.useState(new Date());

  const agent = React.useContext(AgentContext);
  const customerId = agent?.getContextDataAccessor.getUserInfo()?.userId!;

  const showDetail = (start: Date, end: Date) => {
    setActionLogStart(start);
    setActionLogEnd(end);
  };

  return (
    <div className={classes.wrapper}>
      <PageLogs customerId={customerId} showDetail={showDetail} />
      {/* <ActionLogs
        customerId={customerId}
        start={actionLogStart}
        end={actionLogEnd}
      /> */}
    </div>
  );
};

export default ActionLogPane;
