import React from 'react';
import { Sidebar } from 'react-admin';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import BackgroundTheme from '../../../Styles/BackgroundTheme';

const style = makeStyles((theme: Theme) =>
  createStyles({
    drawerPaper: {
      backgroundColor: BackgroundTheme.palette.secondary.main,
      color: '#fff',
      paddingTop: '20px',
    },
  })
);

const SettingsSidebar = (props: any) => {
  const classes = style();
  return <Sidebar classes={classes} {...props} />;
};

export default SettingsSidebar;
