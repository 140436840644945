/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { IconButton, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import useMoveTo from '../../Hooks/Moveto';
import BackgroundTheme from '../../../Styles/BackgroundTheme';
import { definitions } from '../../../Utils/types';
import { supabase } from '../../../Utils/supabase';
import { useHandleError } from '../../Hooks/HandleError';
import useTranslation from '../../Hooks/Translate';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import EditIcon from '@material-ui/icons/Edit';

type Dashboard = definitions['dashboards'];
type JobDashboard = definitions['jobs_dashboards'];
type Job = definitions['jobs'];

const style = makeStyles((theme: Theme) =>
  createStyles({
    tableHead: {
      backgroundColor: BackgroundTheme.palette.primary.main,
    },
    tableEntry: {
      color: '#fff',
    },
    tableBody: {
      background: '#F7FBFD',
    },
    tableRow: {
      cursor: 'pointer',
      position: 'relative',
      transition: '200ms',
      '&:hover': {
        backgroundColor: '#d4e0eb',
      },
    },
    editIcon: {
      position: 'absolute',
      right: '10px',
      top: '4px',
    },
    starIcon: {
      width: '20px',
      height: '20px',
    },
  })
);

const dbAccessor = {
  getJobDashboards: async (dashboardId: string) => {
    return await supabase
      .from<JobDashboard>('jobs_dashboards')
      .select()
      .eq('dashboardId', dashboardId);
  },
};

interface DashboardTableProps {
  dashboards: Dashboard[];
  jobCondidates: Job[];
  onRequestEdit: (dashboard: Dashboard, jobIds: string[]) => void;
}
const DashboardTable = (props: DashboardTableProps) => {
  const classes = style();

  return (
    <TableContainer component={Paper}>
      <Table>
        <DashboardTableHeader />
        <TableBody className={classes.tableBody}>
          {props.dashboards.map((d) => (
            <DashboardTableRow
              key={d.id}
              data={d}
              jobCondidates={props.jobCondidates}
              onRequestEdit={(jobIds) => {
                props.onRequestEdit(d, jobIds);
              }}
            />
          ))}
          {/* <TableRow>
            <TableCell></TableCell>
          </TableRow> */}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default DashboardTable;

interface DashboardTableHeaderProps {}
const DashboardTableHeader = (props: DashboardTableHeaderProps) => {
  const classes = style();
  const t = useTranslation('Analytics');
  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        <TableCell>
          <Typography className={classes.tableEntry}>
            {t('dashboardtable.title')}
          </Typography>
        </TableCell>
        <TableCell style={{ width: '40%' }}>
          <Typography className={classes.tableEntry}>
            {t('dashboardtable.share')}
          </Typography>
        </TableCell>
        <TableCell style={{ width: '15%' }}>
          <Typography className={classes.tableEntry}>
            {t('dashboardtable.default')}
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

interface DashboardTableRowProps {
  data: Dashboard;
  jobCondidates: Job[];
  onRequestEdit: (jobIds: string[]) => void;
}
const DashboardTableRow = (props: DashboardTableRowProps) => {
  const classes = style();
  const moveTo = useMoveTo();
  const handleError = useHandleError();

  const [jobsDashboards, setJobsDashboards] = React.useState<JobDashboard[]>(
    []
  );
  const [openEditIcon, setOpenEditIcon] = React.useState(false);

  const toDashboardEdit = (dashboardId: string) => {
    moveTo('dashboard-edit', [
      {
        key: 'did',
        value: dashboardId,
      },
    ]);
  };

  const fetchJobDashboards = async () => {
    const { data, error } = await dbAccessor.getJobDashboards(props.data.id);

    if (!error) {
      setJobsDashboards(data || []);
    } else {
      handleError('PostgresError', { message: error.message });
    }
  };

  React.useEffect(() => {
    fetchJobDashboards();
  }, [props.data]);

  const jobNames = jobsDashboards.map((jd) => {
    return props.jobCondidates.find((j) => j.id === jd.jobId)?.name || '';
  });

  const { id, name, default: isDefault } = props.data;
  return (
    <TableRow
      key={id}
      onClick={() => toDashboardEdit(id)}
      onMouseEnter={() => {
        setOpenEditIcon(true);
      }}
      onMouseLeave={() => {
        setOpenEditIcon(false);
      }}
      className={classes.tableRow}
    >
      <TableCell>
        <Typography>{name}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{jobNames.join(', ')}</Typography>
      </TableCell>
      <TableCell>
        {isDefault && <StarBorderIcon className={classes.starIcon} />}
        {openEditIcon && (
          <IconButton
            className={classes.editIcon}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              props.onRequestEdit(jobsDashboards.map((jd) => jd.jobId));
            }}
          >
            <EditIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};
