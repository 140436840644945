import * as React from 'react';
import { ThemeProvider } from '@material-ui/core';
import defaultTheme from './Styles/Theme';
import PageBuilder from './PageBuilder';
import { supabase, supabaseEnv } from './Utils/supabase';
import useMoveTo from './Components/Hooks/Moveto';
import useSearchUrlParam from './Components/Hooks/SearchUrlParam';
import OverlayContainer from './Components/Organisms/Global/OverlayContainer';

const App = () => {
  const moveTo = useMoveTo();
  const searchUrlParam = useSearchUrlParam();

  supabase.auth.onAuthStateChange((event, session) => {
    if (event === 'PASSWORD_RECOVERY') {
      const token = searchUrlParam(supabaseEnv.RESET_TOKEN_KEY_NAME, '#');
      moveTo('register', [
        { key: supabaseEnv.RESET_TOKEN_KEY_NAME, value: token || '' },
      ]);
    }
  });

  return (
    <ThemeProvider theme={defaultTheme}>
      <PageBuilder />
      <OverlayContainer />
    </ThemeProvider>
  );
};

export default App;
