import * as React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@mui/material/styles';

// const styles = makeStyles({
//   wrapper: {},
// });

interface AnalyticsDrawerMainContentsProps {
  children: React.ReactNode;
  drawerWidth: number;
  open: boolean;
}

const AnalyticsDrawerMainContents = (
  props: AnalyticsDrawerMainContentsProps
) => {
  // const classes = styles();
  const Main = styled('main', {
    shouldForwardProp: (prop: string) => prop !== 'open',
  })(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${props.drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }));

  return (
    <Main
      open={props.open}
      style={{ overflow: 'auto', marginTop: '65px', padding: '0px' }}
    >
      {/* <DrawerHeader /> */}
      {props.children}
    </Main>
  );
};

export default AnalyticsDrawerMainContents;
