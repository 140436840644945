import 'react-datepicker/dist/react-datepicker.css';
import { RangeChoosePeriod } from '../Components/Molecules/Analytics/ChoosePeriod';

export const convertFromPeriodExp = (props: RangeChoosePeriod['type']) => {
  let startDt;
  let endDt;

  const today = () => {
    defineDates(() => {});
  };

  const yesterday = () => {
    const theYesterday = (firstDay: Date, lastDay: Date) => {
      firstDay.setDate(firstDay.getDate() - 1);
      lastDay.setDate(lastDay.getDate() - 1);
    };
    defineDates(theYesterday);
  };

  const thisWeek = () => {
    const thisWeek = (firstDay: Date, lastDay: Date) => {
      firstDay.setDate(firstDay.getDate() - 7);
      firstDay.setDate(
        firstDay.getDate() + ((((7 - firstDay.getDay()) % 7) + 1) % 7)
      );
      lastDay.setDate(
        lastDay.getDate() + ((((7 - lastDay.getDay()) % 7) + 7) % 7)
      );
    };
    defineDates(thisWeek);
  };

  const lastWeek = () => {
    const theLastWeek = (firstDay: Date, lastDay: Date) => {
      firstDay.setDate(firstDay.getDate() - 14);
      firstDay.setDate(
        firstDay.getDate() + ((((7 - firstDay.getDay()) % 7) + 1) % 7)
      );
      lastDay.setDate(lastDay.getDate() - 7);
      lastDay.setDate(
        lastDay.getDate() + ((((7 - lastDay.getDay()) % 7) + 7) % 7)
      );
    };
    defineDates(theLastWeek);
  };

  const thisMonth = () => {
    const theThisMonth = (firstDay: Date, lastDay: Date) => {
      firstDay.setDate(1);
      lastDay.setMonth(lastDay.getMonth() + 1);
      lastDay.setDate(0);
    };
    defineDates(theThisMonth);
  };

  const lastMonth = () => {
    const theLastMonth = (firstDay: Date, lastDay: Date) => {
      firstDay.setMonth(firstDay.getMonth() - 1);
      firstDay.setDate(1);
      lastDay.setDate(0);
    };
    defineDates(theLastMonth);
  };

  const thisYear = () => {
    const theThisYear = (firstDay: Date, lastDay: Date) => {
      firstDay.setMonth(0);
      firstDay.setDate(1);
      lastDay.setFullYear(lastDay.getFullYear() + 1);
      lastDay.setMonth(0);
      lastDay.setDate(0);
    };
    defineDates(theThisYear);
  };

  const lastYear = () => {
    const theLastYear = (firstDay: Date, lastDay: Date) => {
      firstDay.setFullYear(firstDay.getFullYear() - 1);
      firstDay.setMonth(0);
      firstDay.setDate(1);
      lastDay.setMonth(0);
      lastDay.setDate(0);
    };
    defineDates(theLastYear);
  };

  const defineDates = (dates: (firstDay: Date, lastDay: Date) => void) => {
    const firstDay = new Date();
    const lastDay = new Date();
    dates(firstDay, lastDay);
    startDt = firstDay;
    endDt = lastDay;
  };

  const handleChange = (selectedChoice: RangeChoosePeriod['type']) => {
    switch (selectedChoice) {
      case 'today':
        today();
        break;
      case 'yesterday':
        yesterday();
        break;
      case 'thisWeek':
        thisWeek();
        break;
      case 'lastWeek':
        lastWeek();
        break;
      case 'thisMonth':
        thisMonth();
        break;
      case 'lastMonth':
        lastMonth();
        break;
      case 'thisYear':
        thisYear();
        break;
      case 'lastYear':
        lastYear();
        break;
      default:
        today();
    }
  };
  handleChange(props);

  return [startDt, endDt];
};
