/* eslint-disable react-hooks/exhaustive-deps */
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import React from 'react';

export type NotificationType = 'error' | 'info' | 'success' | 'warning';

interface NotificationProps {}
const Notification = (props: NotificationProps) => {
  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState<NotificationType | undefined>();
  const [message, setMessage] = React.useState('');

  const handleReceiveMessage = (ev: Event) => {
    const { type, message } = (ev as CustomEvent).detail;

    setType(type);
    setMessage(message);
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 5000);
  };

  React.useEffect(() => {
    window.addEventListener('notify', handleReceiveMessage);
    return () => {
      window.removeEventListener('notify', handleReceiveMessage);
    };
  }, [0]);

  return (
    <Snackbar open={open} autoHideDuration={6000}>
      <Alert severity={type}>{message}</Alert>
    </Snackbar>
  );
};

export default Notification;
