import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import BackgroundTheme from '../../../Styles/BackgroundTheme';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      backgroundColor: BackgroundTheme.palette.primary.main,
      padding: '10px',
      minHeight: '90px',
    },
    title: {
      color: '#fff',
      float: 'left',
      lineHeight: '70px',
      fontWeight: 'bold',
      marginLeft: '1em',
      fontSize: '28px',
    },
    buttonWrapper: {
      margin: '5px',
      display: 'flex',
      float: 'right',
    },
  })
);

interface DashboardAdminHeaderProps {
  title: string;
  children: React.ReactNode;
}

const DashboardAdminHeader = (props: DashboardAdminHeaderProps) => {
  const classes = styles();

  return (
    <div className={classes.wrapper}>
      <Typography variant="h1" className={classes.title}>
        {props.title}
      </Typography>
      <div id="addbutton" className={classes.buttonWrapper}>
        {props.children}
      </div>
    </div>
  );
};

export default DashboardAdminHeader;
