import React, { Suspense } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { definitions } from '../../../Utils/types';
import Loading from '../../Atoms/Loading';
import { FacetValue } from '../../Atoms/Facets/Bases/FacetValue';
import useGetGadgetProfile from '../../Hooks/GetGadgetProfile';
import { useGetGadgetType } from '../../Hooks/AccessGadgetFacetMaster';

type Gadget = definitions['dashboard_gadgets']['gadget'];

const style = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      boxShadow: theme.shadows[2],
      backgroundColor: '#fff',
      //margin: theme.spacing(1),
    },
    gadgetHeader: {
      width: '100%',
      height: '35px',
      borderBottom: `1px solid ${theme.palette.divider}`,
      padding: theme.spacing(1),
    },
    gadgetTitle: {
      //fontWeight: 'bold',
      color: theme.palette.grey[600],
    },
    gadgetBody: {
      width: `calc(100%-${theme.spacing(1)}px)`,
      flex: 1,
      overflowY: 'auto',
      margin: theme.spacing(1),
    },
  })
);

interface AnalyticsGadgetProps {
  gadget: Gadget;
  defaultFacetValues: FacetValue<unknown>[];
  onFinishRender?: () => void;
}

const AnalyticsGadget = (props: AnalyticsGadgetProps) => {
  const classes = style();
  const getGadgetProfile = useGetGadgetProfile();
  const getGadgetType = useGetGadgetType();

  const { gadget, onFinishRender } = props;
  const gadgetType = getGadgetType(gadget);
  if (!gadgetType) throw new Error('Could not find any gadget type.');

  const GadgetComponent = React.lazy(
    () => import(`../../Atoms/Gadgets/${gadgetType}/${gadget}`)
  );

  return (
    <div className={classes.wrapper}>
      <div className={classes.gadgetHeader}>
        <Typography className={classes.gadgetTitle} variant="caption">
          {getGadgetProfile(gadget).name}
        </Typography>
      </div>
      <div className={classes.gadgetBody}>
        <Suspense fallback={<Loading />}>
          <GadgetComponent
            onFinishRender={onFinishRender}
            gadget={gadget}
            defaultFacetValues={props.defaultFacetValues}
          />
        </Suspense>
      </div>
    </div>
  );
};

export default AnalyticsGadget;
