import { definitions } from '../../Utils/types';
import i18n from 'i18next';

import enFacet from '../../Locales/en/Facet.json';
import jaFacet from '../../Locales/ja/Facet.json';

type Facet = definitions['dashboard_facets']['facet'];
type FacetMasterData = {
  name: string;
  item?: any;
};

const useGetFacetProfile = () => {
  return (facet: Facet) => {
    switch (i18n.language) {
      case 'en':
        return (enFacet as any)[facet] as FacetMasterData;
      case 'ja':
        return (jaFacet as any)[facet] as FacetMasterData;
      default:
        return (enFacet as any)[facet] as FacetMasterData;
    }
  };
};

export default useGetFacetProfile;
