import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enGlobal from '../Locales/en/Global.json';
import enAccount from '../Locales/en/Account.json';
import enAnalytics from '../Locales/en/Analytics.json';
import enAuth from '../Locales/en/Auth.json';
import enSession from '../Locales/en/Session.json';
import enSettings from '../Locales/en/Settings.json';
import enGadget from '../Locales/en/Gadget.json';
import enFacet from '../Locales/en/Facet.json';
import jaGlobal from '../Locales/ja/Global.json';
import jaAccount from '../Locales/ja/Account.json';
import jaAnalytics from '../Locales/ja/Analytics.json';
import jaAuth from '../Locales/ja/Auth.json';
import jaSession from '../Locales/ja/Session.json';
import jaSettings from '../Locales/ja/Settings.json';
import jaGadget from '../Locales/ja/Gadget.json';
import jaFacet from '../Locales/ja/Facet.json';
import { resolveBrowserLocale } from 'ra-core';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      Global: enGlobal,
      Gadget: enGadget,
      Facet: enFacet,
      Auth: enAuth,
      Session: enSession,
      Analytics: enAnalytics,
      Settings: enSettings,
      Account: enAccount,
    },
    ja: {
      Global: jaGlobal,
      Gadget: jaGadget,
      Facet: jaFacet,
      Auth: jaAuth,
      Session: jaSession,
      Analytics: jaAnalytics,
      Settings: jaSettings,
      Account: jaAccount,
    },
  },
  lng: resolveBrowserLocale(),
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
