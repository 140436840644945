import Login from '../Pages/Login';
import Forgot from '../Pages/Forgot';
import Register from '../Pages/Register';
import Session from '../Pages/Session/Session';
import { FunctionComponent } from 'react';
import Analytics from '../Pages/Analytics/Analytics';
import DashboardAdmin from '../Pages/Analytics/DashboardAdmin';
import DashboardEdit from '../Pages/Analytics/DashboardEdit';
import Settings from '../Pages/Settings/Settings';
import Account from '../Pages/Account/Account';

export interface PageSettingProps {
  path: string;
  module: FunctionComponent;
  guestAccess?: boolean;
}

export const pageSettings: Record<string, PageSettingProps> = {
  home: {
    path: '/',
    module: Session,
  },
  login: {
    path: '/login',
    module: Login,
    guestAccess: true,
  },
  forgot: {
    path: '/forgot',
    module: Forgot,
    guestAccess: true,
  },
  register: {
    path: '/register',
    module: Register,
    guestAccess: true,
  },
  session: {
    path: '/session',
    module: Session,
  },
  analytics: {
    path: '/analytics',
    module: Analytics,
  },
  'dashboard-admin': {
    path: '/analytics/dashboard-admin',
    module: DashboardAdmin,
  },
  'dashboard-edit': {
    path: '/analytics/dashboard-edit',
    module: DashboardEdit,
  },
  settings: {
    path: '/settings',
    module: Settings,
  },
  account: {
    path: '/account',
    module: Account,
  },
};
