import React from 'react';
import { PageSettingProps, pageSettings } from './Utils/pageSettings';
import WithContext from './WithContext';
import WithLayout from './WithLayout';
import WithPermission from './WithPermission';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Header from './Components/Organisms/Global/Header';

export interface PageBuildProps {
  componentName: string;
  pageSetting: PageSettingProps;
  children?: JSX.Element;
}

const PageBuilder = () => {
  const renderComponent = (name: string, ps: PageSettingProps) => {
    return (
      <WithPermission componentName={name} pageSetting={ps}>
        <WithLayout header={ps.guestAccess ? undefined : <Header />}>
          {React.createElement(ps.module)}
        </WithLayout>
      </WithPermission>
    );
  };

  const createComponent = (name: string, ps: PageSettingProps) => {
    return (
      <Route
        key={ps.path}
        exact={true}
        path={ps.path}
        render={() => renderComponent(name, ps)}
      />
    );
  };

  const routes = [];
  for (let key in pageSettings) {
    routes.push(createComponent(key, pageSettings[key]));
  }
  return (
    <WithContext>
      <Router>
        <Switch>{routes}</Switch>
      </Router>
    </WithContext>
  );
};

export default PageBuilder;
