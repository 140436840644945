import * as React from 'react';
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  Datagrid,
  TextField,
  DateField,
  SelectInput,
  ReferenceField,
  ReferenceInput,
  usePermissions,
  useRedirect,
  Loading,
  useNotify,
  FormDataConsumer,
} from 'react-admin';
import { useFormState, useForm } from 'react-final-form';
import useHandleOperationFailure from '../../Components/Hooks/HandleOperationFailure';
import SettingList from '../../Components/Molecules/Settings/SettingList';
import useTranslation from '../../Components/Hooks/Translate';
import useValidation from '../../Components/Hooks/Validation';
import CustomToolBar from '../../Components/Hooks/CreateCustomToolBar';

export const ContactReasonList = (props: any) => {
  const { loading, permissions } = usePermissions();
  const t = useTranslation('Settings');

  if (loading) {
    return <Loading loadingPrimary="" loadingSecondary="" />;
  } else {
    return (
      <SettingList
        {...props}
        permissions={permissions}
        create={permissions.hasCreate('contactReasons')}
        sort={{ field: 'jobId', order: 'DESC' }}
        confirmtitle={t('contactReasons.confirmTitle')}
      >
        <Datagrid rowClick="edit">
          <TextField source="name" label={t('contact_reasons.field.name')} />
          <ReferenceField
            source="jobId"
            reference="jobs"
            label={t('contact_reasons.field.job')}
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            source="contactReasonCategoryId"
            reference="contact_reason_categories"
            label={t('contact_reasons.field.contactReasonCategory')}
          >
            <TextField source="name" />
          </ReferenceField>
          <DateField source="createdAt" label={t('common.field.createdAt')} />
          <DateField source="updatedAt" label={t('common.field.modifiedAt')} />
        </Datagrid>
      </SettingList>
    );
  }
};

export const ContactReasonEdit = (props: any) => {
  const { loading, permissions } = usePermissions();
  const handleOperationFailure = useHandleOperationFailure();
  const t = useTranslation('Settings');
  const v = useValidation();

  const ContactReasonTitle = (prop: any) => {
    const title = `${t('contact_reasons.name')}: ${prop.record.name}`;
    return <span>{title}</span>;
  };

  const ReasonCategoryInput = (prop: any) => {
    const { values } = useFormState();

    return (
      <ReferenceInput
        source="contactReasonCategoryId"
        reference="contact_reason_categories"
        filter={{ jobId: values.jobId }}
        label={t('contact_reasons.field.contactReasonCategory')}
      >
        <SelectInput optionValue="id" optionText="name" validate={v.category} />
      </ReferenceInput>
    );
  };

  const CategoryAndJob = (formData: any) => {
    const form = useForm();

    return (
      <div>
        <ReferenceInput
          source="jobId"
          reference="jobs"
          onChange={() => {
            form.change('contactReasonCategoryId', '');
          }}
          label={t('contact_reasons.field.job')}
        >
          <SelectInput optionText="name" validate={v.job} />
        </ReferenceInput>
        <br />
        <ReasonCategoryInput />
      </div>
    );
  };

  if (loading) {
    return <Loading loadingPrimary="" loadingSecondary="" />;
  } else {
    const hasEdit = permissions.hasEdit('contactReasons');

    return (
      <Edit
        mutationMode="pessimistic"
        onFailure={handleOperationFailure}
        title={<ContactReasonTitle />}
        {...props}
      >
        <SimpleForm
          toolbar={
            <CustomToolBar
              permissions={permissions}
              component="contact_reasons"
              record={props.record}
              options={props.options}
            />
          }
        >
          <TextInput
            source="id"
            disabled={true}
            label={t('contact_reasons.field.id')}
          />
          <TextInput
            source="name"
            disabled={!hasEdit}
            label={t('contact_reasons.field.name')}
            validate={v.contact_reason}
          />
          <FormDataConsumer>
            {(formDataProps) => <CategoryAndJob {...formDataProps} />}
          </FormDataConsumer>
        </SimpleForm>
      </Edit>
    );
  }
};

export const ContactReasonCreate = (props: any) => {
  const handleOperationFailure = useHandleOperationFailure();
  const t = useTranslation('Settings');
  const v = useValidation();
  const redirect = useRedirect();
  const notify = useNotify();

  const ContactReasonTitle = (prop: any) => {
    return (
      <span>
        {t('contact_reasons.name')}: {prop.record.name}
      </span>
    );
  };

  const ReasonCategoryInput = (prop: any) => {
    const { values } = useFormState();

    return (
      <ReferenceInput
        source="contactReasonCategoryId"
        reference="contact_reason_categories"
        filter={{ jobId: values.jobId }}
        label={t('contact_reasons.field.contactReasonCategory')}
      >
        <SelectInput optionValue="id" optionText="name" validate={v.category} />
      </ReferenceInput>
    );
  };

  const CategoryAndJob = (formData: any) => {
    const form = useForm();

    return (
      <div>
        <ReferenceInput
          source="jobId"
          reference="jobs"
          onChange={() => {
            form.change('contactReasonCategoryId', '');
          }}
          label={t('contact_reasons.field.job')}
        >
          <SelectInput optionText="name" validate={v.job} />
        </ReferenceInput>
        <br />
        <ReasonCategoryInput />
      </div>
    );
  };

  return (
    <Create
      onSuccess={() => {
        notify('ra.notification.created', 'info', { smart_count: 1 });
        redirect(`/contact_reasons`);
      }}
      onFailure={handleOperationFailure}
      title={<ContactReasonTitle />}
      {...props}
    >
      <SimpleForm>
        <TextInput
          source="name"
          label={t('contact_reasons.field.name')}
          validate={v.contact_reason}
        />
        <FormDataConsumer>
          {(formDataProps) => <CategoryAndJob {...formDataProps} />}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};
