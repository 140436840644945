import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MUIAccordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface AccordionProps {
  title: string;
  children: React.ReactNode;
  ariaControls?: string;
  id?: string;
  disabled?: boolean;
}

const styles = makeStyles({
  accordion: {},
});

const Accordion = (props: AccordionProps) => {
  const classes = styles();

  return (
    <MUIAccordion className={classes.accordion} disabled={props.disabled}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={props.ariaControls}
        id={props.id}
      >
        <Typography>{props.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{props.children}</Typography>
      </AccordionDetails>
    </MUIAccordion>
  );
};

export default Accordion;
