import React, { useState } from 'react';
import { FormControl } from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import DropDown from '../../Atoms/DropDown';
import AnalyticsMenu from './AnalyticsMenu';
import BackgroundTheme from '../../../Styles/BackgroundTheme';
import FacetFormSelector from './FacetFormSelector';
import { definitions } from '../../../Utils/types';

type Dashboard = definitions['dashboards'];
type View = definitions['views'];

const style = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      backgroundColor: BackgroundTheme.palette.primary.main,
      width: '100%',
      height: '65px',
    },
    body: {},
    facets: {},
    gadget: {},
    facetsForm: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: '12px',
    },
    facetsFormSelectWrapper: {
      // width: '300px',
      minWidth: '150px',
      height: '50px',
      marginRight: '1em',
    },
    facetsFormSelect: {
      backgroundColor: '#fff',
      height: '40px',
    },
    iconButton: {
      color: '#fff',
      width: '50px',
      height: '50px',
      marginTop: '7px',
    },
  })
);

interface AnalyticsHeaderProps {
  dashboards: Dashboard[];
  views: View[];
  currentDashboard?: Dashboard;
  currentView?: View;
  onChangeDashboard: (dashboard?: Dashboard) => void;
  onChangeView: (view: View) => void;
  onCreateNewView: (name: string) => void;
  onDeleteView: (view: View) => void;
}

const AnalyticsHeader = (props: AnalyticsHeaderProps) => {
  const classes = style();
  const {
    dashboards,
    views,
    currentDashboard,
    currentView,
    onChangeDashboard,
    onChangeView,
    onCreateNewView,
    onDeleteView,
  } = props;
  const [openSettingMenu, setOpenSettingMenu] = useState<boolean>(false);

  const onClickSettings = () => {
    setOpenSettingMenu(true);
  };

  return (
    <div className={classes.wrapper}>
      {openSettingMenu && (
        <AnalyticsMenu
          currentDashboard={currentDashboard}
          onClose={() => {
            setOpenSettingMenu(false);
          }}
        />
      )}
      <FormControl fullWidth className={classes.facetsForm}>
        <div className={classes.facetsFormSelectWrapper}>
          <DropDown
            id="select-dashboard"
            variant="outlined"
            defaultKey={currentDashboard ? currentDashboard.id : ''}
            data={dashboards.map((db) => {
              return { key: db.id, value: db.name };
            })}
            onChange={(selected) =>
              onChangeDashboard(
                dashboards.find((db) => db.id === selected?.key)
              )
            }
            className={classes.facetsFormSelect}
          />
        </div>
        <div className={classes.facetsFormSelectWrapper}>
          <FacetFormSelector
            id="select-view"
            variant="outlined"
            selected={currentView ? currentView.id : ''}
            data={views}
            onChange={onChangeView}
            onDelete={onDeleteView}
            onCreateNew={onCreateNewView}
            className={classes.facetsFormSelect}
          />
        </div>
      </FormControl>
      <IconButton onClick={onClickSettings} className={classes.iconButton}>
        <Settings />
      </IconButton>
    </div>
  );
};

export default AnalyticsHeader;
