import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';

interface CheckBoxGroupProps {
  children: React.ReactNode;
}

const styles = makeStyles({
  checkboxGroup: {
    display: 'block',
  },
});

const CheckBoxGroup = (props: CheckBoxGroupProps) => {
  const classes = styles();

  return (
    <FormGroup className={classes.checkboxGroup}>{props.children}</FormGroup>
  );
};

export default CheckBoxGroup;
