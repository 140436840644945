import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useCubeQuery } from '@cubejs-client/react';
import { Query } from '@cubejs-client/core';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import backgroundTheme from '../../../Styles/BackgroundTheme';
import PageLogCard from '../../Molecules/Sessions/PageLogCard';
import React from 'react';

const style = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: '300px',
    },
    titleBox: {
      padding: '15px',
      backgroundColor: backgroundTheme.palette.primary.main,
      position: 'fixed',
      width: '300px',
      borderBottom: 'solid 1px #aaa',
    },
    title: {
      color: '#fff',
    },
    line: {
      position: 'absolute',
      width: '1px',
      animation: '$stretch linear 2s',
      backgroundColor: theme.palette.primary.light,
      left: '20px',
      opacity: 0.5,
      height: 'calc(100% - 55px)',
      top: '55px',
    },
    logs: {
      marginTop: '55px',
      overflow: 'auto',
      height: 'calc(100vh - 115px)',
    },
    '@keyframes stretch': {
      '0%': { height: 0 },
      '100%': { height: '1420px' },
    },
    loading: {
      margin: '10px auto',
    },
  })
);

const pageLogQueryBuilder = (customerId: string) =>
  ({
    measures: [],
    timeDimensions: [
      {
        dimension: 'Pages.originalTimestamp',
      },
    ],
    order: [['Pages.originalTimestamp', 'desc']],
    dimensions: [
      'Pages.id',
      'Pages.path',
      'Pages.originalTimestamp',
      'Pages.title',
      'Pages.url',
    ],
    limit: 5000,
    filters: [
      {
        member: 'Pages.userId',
        operator: 'equals',
        values: [customerId],
      },
    ],
  } as Query);

type PageLogsData = {
  'Pages.id': string;
  'Pages.originalTimestamp': string;
  'Pages.path': string;
  'Pages.title': string;
  'Pages.url': string;
};

interface PageLogsPaneProps {
  customerId: string;
  showDetail: (start: Date, end: Date) => void;
}
const PageLogs = (props: PageLogsPaneProps) => {
  const { customerId, showDetail } = props;
  const classes = style();

  const { resultSet, error, isLoading } = useCubeQuery<PageLogsData>(
    pageLogQueryBuilder(customerId)
  );

  if (isLoading) {
    return (
      <div className={classes.loading}>
        ページ履歴を取得しています...
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return <pre>{error.toString()}</pre>;
  }

  if (!resultSet) {
    return null;
  }

  const rawData = resultSet.rawData();

  let nextAction: PageLogsData;
  const pageLogCards = rawData.map((v, i) => {
    nextAction = rawData[i - 1];
    const start = new Date(v['Pages.originalTimestamp']);
    //Latest page history hides the duration
    const end = nextAction
      ? new Date(nextAction['Pages.originalTimestamp'])
      : start;
    return (
      <PageLogCard
        key={i.toString()}
        start={start}
        end={end}
        title={v['Pages.title']}
        path={v['Pages.path']}
        url={v['Pages.url']}
        onClick={showDetail}
      />
    );
  });

  return (
    <div className={classes.wrapper}>
      <Box className={classes.titleBox}>
        <Typography variant="h2" className={classes.title}>
          動線データ
        </Typography>
      </Box>
      <div className={classes.line}></div>
      <div className={classes.logs}>{pageLogCards}</div>
    </div>
  );
};

export default PageLogs;
