import { useNotify } from './Notify';

export type ErrorType = 'PostgresError' | 'CubeFetchError' | 'Unknown';

export const useHandleError = () => {
  const notify = useNotify();

  return (type: ErrorType, options?: { message?: string }) => {
    notify(type, 'error');
  };
};
