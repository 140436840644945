/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles } from '@material-ui/core';
import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import ModalBase from '../../Molecules/Global/ModalBase';
import { Typography } from '@material-ui/core';
import BackgroundTheme from '../../../Styles/BackgroundTheme';
import { definitions } from '../../../Utils/types';
import useTranslation from '../../Hooks/Translate';
import { useGetAllFacets } from '../../Hooks/AccessGadgetFacetMaster';
import useGetFacetProfile from '../../Hooks/GetFacetProfile';

type Facet = definitions['dashboard_facets']['facet'];

const styles = makeStyles({
  facetList: {
    width: '47%',
    margin: '20px 1% 5px',
  },
  usablefacetlist: {
    float: 'left',
  },

  selectedfacetlist: {
    float: 'right',
  },
  listItem: {
    backgroundColor: '#fff',
    margin: '10px 0',
    boxShadow: '#888 1px 2px 5px',
    borderRadius: '5px',
  },
  contentTitle: {
    marginLeft: '10px',
    fontSize: '1rem',
  },
  box6: {
    margin: '5px',
    height: '100%',
    padding: '10px 20px',
    backgroundColor: BackgroundTheme.palette.secondary.main,
  },

  box6p: {
    margin: '0',
    padding: '0',
  },

  listp: {},

  liststyle: {},
});

interface EditFacetModalProps {
  open: boolean;
  defaultSelected: Facet[];
  onAdd: (selected: Facet[]) => void;
  onCancel: () => void;
}

const EditFacetModal = (props: EditFacetModalProps) => {
  const classes = styles();
  const masterFacets = useGetAllFacets()();
  const getFacetProfile = useGetFacetProfile();
  const t = useTranslation('Analytics');

  const [selectedFacets, setSelectedFacets] = React.useState<Facet[]>(
    props.defaultSelected.slice()
  );

  const onClickApply = () => {
    props.onAdd(selectedFacets);
  };

  const UsableFacetList = () => {
    const rows = masterFacets.map((facet) => (
      <div key={facet}>
        <ListItem className={classes.listItem}>
          <ListItemText primary={getFacetProfile(facet).name} />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() =>
                setSelectedFacets(
                  selectedFacets.find((f) => f === facet)
                    ? selectedFacets
                    : [...selectedFacets, facet]
                )
              }
            >
              <AddIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </div>
    ));
    return (
      <>
        <List component="nav" aria-label="mailbox folders">
          {rows}
        </List>
      </>
    );
  };

  function deleteFacet(facets: Facet[], facet: Facet): Facet[] {
    const ans = facets.filter((f) => {
      return f !== facet;
    });
    return ans;
  }

  const SelectedFacetList = () => {
    const rows = selectedFacets.map((facet) => (
      <div key={facet}>
        <ListItem className={classes.listItem}>
          <ListItemText primary={getFacetProfile(facet).name} />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="close"
              onClick={() =>
                setSelectedFacets(deleteFacet(selectedFacets, facet))
              }
            >
              <CloseIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </div>
    ));
    return (
      <>
        <List component="nav" aria-label="mailbox folders">
          {rows}
        </List>
      </>
    );
  };

  return (
    <>
      <ModalBase
        open={props.open}
        title={t('facets.edit.title')}
        // onClickClose={onClickCancel}
        onClickCancel={props.onCancel}
        onClickApprove={onClickApply}
        maxWidth="md"
      >
        <div className={`${classes.facetList} ${classes.usablefacetlist}`}>
          <Typography className={classes.contentTitle}>
            <b>{t('facets.edit.available')}</b>
          </Typography>
          <div className={classes.box6}>
            <UsableFacetList />
          </div>
        </div>
        <div className={`${classes.facetList} ${classes.selectedfacetlist}`}>
          <Typography className={classes.contentTitle}>
            <b>{t('facets.edit.selected')}</b>
          </Typography>
          <div className={classes.box6}>
            <SelectedFacetList />
          </div>
        </div>
      </ModalBase>
    </>
  );
};
export default EditFacetModal;
