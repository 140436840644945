import React, { useState, useEffect } from 'react';
import AnalyticsHeader from '../../Components/Organisms/Analytics/AnalyticsHeader';
import AnalyticsFacetPane from '../../Components/Organisms/Analytics/AnalyticsFacetPane';
import AnalyticsGadgetPane from '../../Components/Organisms/Analytics/AnalyticsGadgetPane';
import { makeStyles } from '@material-ui/core/styles';
import Loading from '../../Components/Atoms/Loading';
import { Box } from '@material-ui/core';
import AnalyticsDrawerHeader from '../../Components/Organisms/Analytics/AnalyticsDrawerHeader';
import AnalyticsDrawerSideMenu from '../../Components/Organisms/Analytics/AnalyticsDrawerSideMenu';
import AnalyticsDrawerMainContents from '../../Components/Organisms/Analytics/AnalyticsDrawerMainContents';
import theme from '../../Styles/Theme';
import { definitions } from '../../Utils/types';
import { supabase } from '../../Utils/supabase';
import { useHandleError } from '../../Components/Hooks/HandleError';
import { OperatorInfoContext, PermissionContext } from '../../WithContext';
import { FacetValueContext } from '../../WithContext';
import { FacetValue } from '../../Components/Atoms/Facets/Bases/FacetValue';
import { useNotify } from '../../Components/Hooks/Notify';
import { PostgrestError } from '@supabase/supabase-js';
import useConfirm from '../../Components/Hooks/Confirm';
import useTranslation from '../../Components/Hooks/Translate';

type Dashboard = definitions['dashboards'];
type DashboardGadget = definitions['dashboard_gadgets'];
type DashboardFacet = definitions['dashboard_facets'];
type Facet = definitions['dashboard_facets']['facet'];
type View = definitions['views'];
type ViewFacetValue = definitions['view_facet_values'];

const drawerWidth = 280;

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    // extends React's HTMLAttributes
    open?: boolean;
  }
}

const useStyle = makeStyles({
  wrapper: { display: 'flex', width: '100%', height: '100%' },
  body: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: theme.palette.grey[50],
  },
});

const dbAccessor = {
  getOperatorDashboards: async (userId: string) => {
    return await supabase
      .from<Dashboard>('dashboards')
      .select()
      .order('default', { ascending: false, nullsFirst: false });
  },
  getOperatorViews: async () => {
    return await supabase.from<View>('views').select();
  },
  getDashboardFacets: async (dashboardId: string) => {
    return await supabase
      .from<DashboardFacet>('dashboard_facets')
      .select()
      .eq('dashboardId', dashboardId);
  },
  getDashboardGadgets: async (dashboardId: string) => {
    return await supabase
      .from<DashboardGadget>('dashboard_gadgets')
      .select()
      .eq('dashboardId', dashboardId);
  },
  getViewFacetValues: async (viewId: string) => {
    return await supabase
      .from<ViewFacetValue>('view_facet_values')
      .select()
      .eq('viewId', viewId);
  },
  insertOperatorView: async (name: string, dashboardId: string) => {
    return await supabase.from<View>('views').insert([{ name, dashboardId }]);
  },
  insertViewFilterValues: async (
    viewId: string,
    values: FacetValue<unknown>[]
  ) => {
    return await supabase.from<ViewFacetValue>('view_facet_values').insert(
      values.map((v) => {
        return {
          viewId,
          facet: v.type,
          value: JSON.stringify(v.payload),
        };
      })
    );
  },
  deleteOperatorView: async (view: View) => {
    return await supabase.from<View>('views').delete().match({ id: view.id });
  },
  deleteViewFilterValues: async (view: View) => {
    return await supabase
      .from<ViewFacetValue>('view_facet_values')
      .delete()
      .match({ viewId: view.id });
  },
};

const Analytics = () => {
  const classes = useStyle();
  const handleError = useHandleError();
  const notify = useNotify();
  const confirm = useConfirm();
  const t = useTranslation('Analytics');

  const operatorInfo = React.useContext(OperatorInfoContext);
  const facetValue = React.useContext(FacetValueContext);

  const [facets, setFacets] = useState<Facet[] | null>([]);
  const [defaultFacetValues, setDefaultFacetValues] = useState<
    FacetValue<unknown>[]
  >([]);

  const [dashboards, setDashboards] = useState<Dashboard[]>([]);
  const [currentDashboard, setCurrentDashboard] = useState<Dashboard>();
  const [dashboardGadgets, setDashboardGadgets] = useState<
    DashboardGadget[] | null
  >([]);

  const [views, setViews] = useState<View[]>([]);
  const [currentView, setCurrentView] = useState<View>();

  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = React.useState(true);

  useEffect(() => {
    if (permission?.isAdmin()) initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [0]);

  const permission = React.useContext(PermissionContext);
  // 管理者ユーザでなければ、強制的にトップページに遷移させる
  // ベルさん向けリリースでは対象外機能のため暫定的
  if (!permission?.isAdmin()) {
    window.location.href = '/';
    return <></>;
  }

  //#region Private methods which called by Component evnet handler.

  const __handlePostgresError = (error: PostgrestError | null) => {
    handleError('PostgresError', {
      message: error?.message || error?.message,
    });
    return Promise.reject();
  };

  const __fetchViews = async () => {
    const { data: vwData, error: vwError } =
      await dbAccessor.getOperatorViews();

    if (!vwData || vwError) return __handlePostgresError(vwError);

    return Promise.resolve(vwData);
  };

  const __fetchDashboards = async () => {
    const { data: dbData, error: dbError } =
      await dbAccessor.getOperatorDashboards(operatorInfo?.id || '');

    if (!dbData || dbError) return __handlePostgresError(dbError);

    return Promise.resolve(dbData);
  };

  const __fetchFacetValues = async (view: View) => {
    const { data, error } = await dbAccessor.getViewFacetValues(view.id);
    if (!data || error) return __handlePostgresError(error);

    const facetValues = data.map((d) =>
      FacetValue.create(d.facet, JSON.parse(d.value))
    );

    return Promise.resolve(facetValues);
  };

  const __fetchGadgetAndFacet = async (db: Dashboard) => {
    const { data: fcData, error: fcError } =
      await dbAccessor.getDashboardFacets(db.id);
    const { data: gdData, error: gdError } =
      await dbAccessor.getDashboardGadgets(db.id);

    if (fcError || gdError || !fcData || !gdData)
      return __handlePostgresError(fcError || gdError);

    return Promise.resolve({
      gadgets: gdData,
      facets: fcData,
    });
  };

  //#endregion

  //#region Public methods which called by Component or Hook directly.

  const initialize = async () => {
    let gadgets: DashboardGadget[] = [];
    let facets: Facet[] = [];
    let facetValues: FacetValue<unknown>[] = [];
    let currentDashboard: Dashboard | undefined;
    let currentView: View | undefined;

    const views = await __fetchViews();
    const dashboards = await __fetchDashboards();

    if (dashboards.length >= 1) {
      currentDashboard = dashboards[0];

      const res = await __fetchGadgetAndFacet(currentDashboard);
      gadgets = res.gadgets;
      facets = res.facets.map((f) => f.facet);
    }

    if (views.length >= 1) {
      currentView = views[0];

      facetValues = await __fetchFacetValues(currentView);
    }

    setDashboards(dashboards);
    setCurrentDashboard(currentDashboard);
    setViews(views);
    setCurrentView(currentView);
    setDefaultFacetValues(facetValues);
    setDashboardGadgets(gadgets.length >= 1 ? gadgets : null);
    setFacets(facets.length >= 1 ? facets : null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const displayGadgetAndFacetsOf = async (db?: Dashboard) => {
    setCurrentDashboard(db);
    setDashboardGadgets([]);
    setFacets([]);
    setLoading(true);

    if (db) {
      const { data: fcData, error: fcError } =
        await dbAccessor.getDashboardFacets(db.id);
      const { data: gdData, error: gdError } =
        await dbAccessor.getDashboardGadgets(db.id);

      if (fcError || gdError) return __handlePostgresError(fcError || gdError);

      const dispFcData = fcData?.map((f) => f.facet) || [];
      setFacets(dispFcData?.length >= 1 ? dispFcData : null);
      setDashboardGadgets(gdData?.length && gdData.length >= 1 ? gdData : null);

      setLoading(false);
    }
  };

  const createView = async (name: string) => {
    if (currentDashboard) {
      const { data: vwData, error: vwError } =
        await dbAccessor.insertOperatorView(name, currentDashboard?.id);
      if (!vwData || vwData.length === 0 || vwError)
        return __handlePostgresError(vwError);

      const { data: vfData, error: vfError } =
        await dbAccessor.insertViewFilterValues(
          vwData[0].id,
          facetValue.values.slice()
        );
      if (!vfData || vfError) return __handlePostgresError(vfError);

      reloadView(vwData[0]);
      notify(t('notify.save'), 'success');
    }
  };

  const deleteView = async (vw: View) => {
    // 削除確認ダイアログを表示します。
    if (
      (await confirm(
        t('confirm.deleteMessage'),
        t('confirm.deleteApprove')
      )) === false
    ) {
      return;
    }

    const { data: fvData, error: fvError } =
      await dbAccessor.deleteViewFilterValues(vw);
    if (!fvData || fvError) return __handlePostgresError(fvError);

    const { data: vwData, error: vwError } =
      await dbAccessor.deleteOperatorView(vw);
    if (!vwData || vwError) return __handlePostgresError(vwError);

    reloadView(currentView);
    notify(t('notify.delete'), 'success');
  };

  const changeView = async (vw: View) => {
    const facetValues = await __fetchFacetValues(vw);

    setCurrentView(vw);
    setDefaultFacetValues(facetValues);
  };

  const reloadView = async (currentView?: View) => {
    const views = await __fetchViews();
    const facetValues = await __fetchFacetValues(currentView || views[0]);

    setViews(views);
    setCurrentView(currentView || views[0]);
    setDefaultFacetValues(facetValues);
  };
  //#endregion

  return (
    <Box className={classes.wrapper} style={{}}>
      {loading && <Loading />}
      <AnalyticsDrawerHeader
        drawerWidth={drawerWidth}
        open={open}
        onClick={handleDrawerOpen}
      >
        <AnalyticsHeader
          dashboards={dashboards}
          views={views}
          currentDashboard={currentDashboard}
          currentView={currentView}
          onChangeDashboard={displayGadgetAndFacetsOf}
          onChangeView={changeView}
          onCreateNewView={createView}
          onDeleteView={deleteView}
        />
      </AnalyticsDrawerHeader>
      <AnalyticsDrawerSideMenu
        drawerWidth={drawerWidth}
        open={open}
        onClick={handleDrawerClose}
      >
        <AnalyticsFacetPane
          facets={facets}
          defaultValues={defaultFacetValues}
        />
      </AnalyticsDrawerSideMenu>

      <AnalyticsDrawerMainContents drawerWidth={drawerWidth} open={open}>
        <AnalyticsGadgetPane
          dashboardGadgets={dashboardGadgets}
          defaultFacetValues={defaultFacetValues}
        />
      </AnalyticsDrawerMainContents>
    </Box>
  );
};

export default Analytics;
