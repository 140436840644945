import AccountCircle from '@material-ui/icons/AccountCircle';
import MeetingRoom from '@material-ui/icons/MeetingRoom';
import { authProvider } from '../../../Utils/authProvider';
import { pageSettings } from '../../../Utils/pageSettings';
import ContextMenu from '../../Molecules/Global/ContextMenu';
import useTranslation from '../../Hooks/Translate';

interface UserMenuProps {
  className?: string;
  onClose?: () => void;
}
const UserMenu = (props: UserMenuProps) => {
  const t = useTranslation('Auth');
  const onClickProfile = () => {
    authProvider.editProfile();
    props.onClose?.();
  };
  const onClickLogout = async () => {
    props.onClose?.();
    await authProvider.logout('');
    window.location.href = pageSettings['login'].path;
  };
  return (
    <ContextMenu
      items={[
        // 2月リリース対象外
        // {
        //   title: t('usermenu.profile'),
        //   onClick: onClickProfile,
        //   icon: <AccountCircle fontSize="small" />,
        // },
        {
          title: t('usermenu.logout'),
          onClick: onClickLogout,
          icon: <MeetingRoom fontSize="small" />,
        },
      ]}
      width={250}
      top={40}
      right={20}
      onClose={props.onClose}
    />
  );
};

export default UserMenu;
