/* eslint-disable react-hooks/exhaustive-deps */
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import ModalBase from '../../Molecules/Global/ModalBase';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    message: {
      padding: '15px 0px 12px',
      fontSize: '16px',
      color: '#707070',
    },
  })
);

interface ConfirmModalProps {}

const ConfirmModal = (props: ConfirmModalProps) => {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [approveMessage, setApproveMessage] = React.useState<
    string | undefined
  >();
  const [title, setTitle] = React.useState<string | undefined>();
  const classes = useStyles();

  const handleReceiveConfirm = (ev: Event) => {
    const actualEv = ev as CustomEvent<{
      message: string;
      approveMessage?: string;
      title?: string;
    }>;

    setMessage(actualEv.detail.message);
    setApproveMessage(actualEv.detail.approveMessage);
    setOpen(true);
    setTitle(actualEv.detail.title);
  };

  const cancel = () => {
    window.dispatchEvent(new CustomEvent('respondConfirm', { detail: false }));
    setOpen(false);
  };

  const approve = () => {
    window.dispatchEvent(new CustomEvent('respondConfirm', { detail: true }));
    setOpen(false);
  };

  React.useEffect(() => {
    window.addEventListener('confirm', handleReceiveConfirm);
    return () => {
      window.removeEventListener('confirm', handleReceiveConfirm);
    };
  }, [0]);

  return (
    <ModalBase
      open={open}
      maxWidth="sm"
      onClickCancel={cancel}
      onClickApprove={approve}
      messageApprove={approveMessage}
      title={title}
    >
      <Typography className={classes.message}>{message}</Typography>
    </ModalBase>
  );
};

export default ConfirmModal;
