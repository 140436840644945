import * as React from 'react';
import { useEffect } from 'react';
import {
  BooleanInput,
  Edit,
  Create,
  SimpleForm,
  TextInput,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  SelectInput,
  ReferenceField,
  ReferenceInput,
  usePermissions,
  useRedirect,
  Loading,
  useNotify,
} from 'react-admin';
import useHandleOperationFailure from '../../Components/Hooks/HandleOperationFailure';
import SettingList from '../../Components/Molecules/Settings/SettingList';
import useTranslation from '../../Components/Hooks/Translate';
import { fetchJson } from '../../Utils/dataProvider';
import { Identifier, useRefresh } from 'ra-core';
import useValidation from '../../Components/Hooks/Validation';
import CustomToolBar from '../../Components/Hooks/CreateCustomToolBar';
import { OperatorInfoContext } from '../../WithContext';

export const UserList = (props: any) => {
  const { loading, permissions } = usePermissions();
  const t = useTranslation('Settings');
  const notify = useNotify();

  const onClickBulkDelete = async (selectedIds: Identifier[]) => {
    const bulkDeletePromises = selectedIds.map((id) =>
      fetchJson(`/users/management/${id}`, { method: 'DELETE' })
    );

    try {
      await Promise.all(bulkDeletePromises);
    } catch {
      notify('ra.page.error', 'error', { smart_count: 1 });
      window.location.reload();
    }

    notify('ra.notification.created', 'info', { smart_count: 1 });
    window.location.reload();
  };

  if (loading) {
    return <Loading loadingPrimary="" loadingSecondary="" />;
  } else {
    return (
      <SettingList
        {...props}
        permissions={permissions}
        create={permissions.hasCreate('users')}
        bulkDelete={onClickBulkDelete}
        confirmtitle={t('users.confirmTitle')}
      >
        <Datagrid rowClick="edit">
          <TextField source="name" label={t('users.field.name')} />
          <BooleanField source="isAdmin" label={t('users.field.isAdmin')} />
          {/* <ReferenceField
            source="roleId"
            reference="roles"
            label={t('users.field.role')}
          >
            <TextField source="name" />
          </ReferenceField> */}
          <DateField source="createdAt" label={t('common.field.createdAt')} />
          <DateField source="updatedAt" label={t('common.field.modifiedAt')} />
        </Datagrid>
      </SettingList>
    );
  }
};

let __hasSetIsAdminOnUserEdit = false;
export const UserEdit = (props: any) => {
  const [isAdmin, setIsAdmin] = React.useState(false);
  const { loading, permissions } = usePermissions();
  const t = useTranslation('Settings');
  const notify = useNotify();
  const redirect = useRedirect();
  const v = useValidation();
  const handleOperationFailure = useHandleOperationFailure();
  const operatorInfo = React.useContext(OperatorInfoContext);

  useEffect(() => {
    __hasSetIsAdminOnUserEdit = false;
  }, []);
  const UserTitle = (prop: any) => {
    const title = `${t('users.name')}: ${prop.record.name}`;
    return <span>{title}</span>;
  };

  const CheckRoles = (prop: any) => {
    // const hasEdit = permissions.hasEdit('users');
    if (!__hasSetIsAdminOnUserEdit) {
      setIsAdmin(prop.record.isAdmin);
      __hasSetIsAdminOnUserEdit = true;
    }
    return <></>;
    // if (!isAdmin ){
    //   return (
    //     <ReferenceInput
    //       source="roleId"
    //       reference="roles"
    //       disabled={!hasEdit}
    //       label={t('users.field.role')}
    //     >
    //       <SelectInput optionText="name" validate={v.user_role} />
    //     </ReferenceInput>
    //   )
    // } else {
    //   return (
    //     <></>
    //   )
    // }
  };

  const onClickDelete = async () => {
    try {
      await fetchJson(`/users/management/${props.id}`, { method: 'DELETE' });
    } catch {
      notify('ra.page.error', 'error', { smart_count: 1 });
      return Promise.reject();
    }

    notify('ra.notification.deleted', 'info', { smart_count: 1 });
    redirect(`/user_profiles`);
    // refreshフックを使うと、エラーになるので暫定的にこちらで対応
    window.location.reload();

    return Promise.resolve();
  };

  if (loading) {
    return <Loading loadingPrimary="" loadingSecondary="" />;
  } else {
    const hasEdit = permissions.hasEdit('users');
    return (
      <Edit
        mutationMode="pessimistic"
        onFailure={handleOperationFailure}
        title={<UserTitle />}
        {...props}
      >
        <SimpleForm
          toolbar={
            <CustomToolBar
              permissions={permissions}
              component="users"
              onClickDelete={onClickDelete}
              record={props.record}
              options={props.options}
              owner={props.id === (operatorInfo && operatorInfo.id)}
            />
          }
        >
          <TextInput source="id" disabled={true} label={t('users.field.id')} />
          <TextInput
            source="name"
            disabled={!hasEdit}
            label={t('users.field.name')}
            validate={v.name}
          />
          <BooleanInput
            source="isAdmin"
            disabled={!hasEdit}
            label={t('users.field.isAdmin')}
            onChange={(checked: boolean) => {
              setIsAdmin(checked);
            }}
          />
          {/* <CheckRoles />
          {!isAdmin ? (
            <ReferenceInput
              source="roleId"
              reference="roles"
              disabled={!hasEdit}
              label={t('users.field.role')}
            >
              <SelectInput optionText="name" validate={v.user_role} />
            </ReferenceInput>
          ) : (
            <></>
          )} */}
        </SimpleForm>
      </Edit>
    );
  }
};

export const UserCreate = (props: any) => {
  const [isAdmin, setIsAdmin] = React.useState(false);
  const redirect = useRedirect();
  const notify = useNotify();
  const refresh = useRefresh();
  const t = useTranslation('Settings');
  const v = useValidation();
  const UserTitle = (prop: any) => {
    return (
      <span>
        {t('users.name')}: {prop.record.name}
      </span>
    );
  };

  const onClickSave = async (ev: any) => {
    try {
      await fetchJson('/users/management', {
        method: 'POST',
        body: JSON.stringify({
          email: ev.email,
          name: ev.name,
          isAdmin: !!ev.isAdmin,
          roleId: ev.roleId,
        }),
      });
    } catch {
      notify('ra.page.error', 'error', { smart_count: 1 });
      return Promise.reject();
    }

    notify('ra.notification.created', 'info', { smart_count: 1 });
    redirect(`/user_profiles`);
    refresh(true);

    return Promise.resolve();
  };

  return (
    <Create title={<UserTitle />} {...props}>
      <SimpleForm save={onClickSave}>
        <TextInput
          source="name"
          label={t('users.field.name')}
          validate={v.name}
        />
        <TextInput
          source="email"
          type="email"
          label={t('users.field.email')}
          validate={v.email}
        />
        <BooleanInput
          source="isAdmin"
          label={t('users.field.isAdmin')}
          onChange={(checked: boolean) => {
            setIsAdmin(checked);
          }}
        />
        {/* {!isAdmin ? (
          <ReferenceInput
            source="roleId"
            reference="roles"
            label={t('users.field.role')}
          >
            <SelectInput optionText="name" validate={v.user_role} />
          </ReferenceInput>
        ) : (
          <></>
        )} */}
      </SimpleForm>
    </Create>
  );
};
