import * as React from 'react';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import TextBox from '../../Atoms/TextBox';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import useTranslation from '../../Hooks/Translate';
import { useNotify } from '../../Hooks/Notify';

const style = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      backgroundColor: '#fff',
      height: '40px',
    },
    comment: {
      margin: '10px 4% 5px',
    },
    saveField: {
      width: '90%',
      margin: '5px 4%',
    },
    saveBtn: {
      margin: '0 4%',
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
    },
  })
);

interface SaveNewViewProps {
  onCreateNew: (name: string) => void;
}

const SaveNewView = (props: SaveNewViewProps) => {
  const classes = style();
  const t = useTranslation('Analytics');

  const notify = useNotify();
  const [name, setName] = React.useState('');

  const createNewView = () => {
    if (!name) {
      notify(t('notify.inputName'), 'warning');
      return;
    }
    props.onCreateNew(name);
  };

  return (
    <Box>
      <Typography variant="body2" className={classes.comment}>
        {t('view.saveas')}
      </Typography>
      <TextBox
        id="facet view"
        variant="standard"
        className={classes.saveField}
        label={''}
        onChange={setName}
      />

      <Button
        variant="contained"
        className={classes.saveBtn}
        onClick={createNewView}
      >
        {t('view.save')}
      </Button>
    </Box>
  );
};

export default SaveNewView;
