import React, { useContext } from 'react';
import AccessDenied from './Components/Atoms/AccessDenied';
import useMoveTo from './Components/Hooks/Moveto';
import { PageBuildProps } from './PageBuilder';
import { PermissionContext } from './WithContext';

const WithPermission = (props: PageBuildProps) => {
  const { componentName, pageSetting } = props;
  const permission = useContext(PermissionContext);
  const moveTo = useMoveTo();

  if (
    (permission?.hasRead(componentName) && !permission.isGuest()) ||
    pageSetting.guestAccess
  ) {
    return props.children || <></>;
  } else {
    if (componentName === 'home') {
      moveTo('login');
      return <></>;
    } else {
      return <AccessDenied />;
    }
  }
};

export default WithPermission;
