import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import moment from 'moment';
import { Box, Divider } from '@material-ui/core';
import backgroundTheme from '../../../Styles/BackgroundTheme';
import Rating from '@material-ui/lab/Rating';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import useTranslation from '../../Hooks/Translate';

const style = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      padding: '15px',
      transitionDuration: '300ms',
      zIndex: 100,
      '&:hover': {
        backgroundColor: backgroundTheme.palette.secondary.main,
        cursor: 'pointer',
      },
    },
    point: {
      width: '20px',
      height: '100%',
      color: theme.palette.primary.light,
      fontSize: '20px',
    },
    body: {},
    date: {
      marginTop: '6px',
      marginBottom: '6px',
      color: theme.palette.grey[500],
    },
    detail: {
      display: 'flex',
      flexDirection: 'row',
    },
    action: {},
    actionIcon: {
      color: theme.palette.secondary.light,
      border: `1px solid ${theme.palette.secondary.light}`,
      height: '50px',
      width: '50px',
    },
    main: {
      marginLeft: '0.5em',
    },
    actionText: {
      margin: '0',
      fontSize: '12.5px',
      display: 'flex',
      flexDirection: 'row',
    },
    object: {
      fontSize: '16px',
      fontWeight: 'bold',
      margin: '0',
    },
    durationRate: {},
  })
);

interface ActionCardProps {
  start: Date;
  end: Date;
  title: string;
  path: string;
  url: string;
  onClick: (start: Date, end: Date) => void;
}
const PageLogCard = (props: ActionCardProps) => {
  const classes = style();
  const { start, end, title, path, url, onClick } = props;
  const duration = Math.floor((end.getTime() - start.getTime()) / 1000);
  const durationText =
    duration > 0
      ? ('0' + Math.floor(duration / 60)).slice(-2) +
        ':' +
        ('0' + (duration % 60)).slice(-2)
      : '';
  const t = useTranslation('Session');

  return (
    <>
      <div className={classes.wrapper} onClick={() => onClick(start, end)}>
        <div className={classes.point}>●</div>
        <div className={classes.body}>
          <Box className={classes.date}>
            {moment(start).format('YYYY/MM/DD hh:mm:ss')}
          </Box>
          <Box display="flex">
            {duration > 0 ? (
              <Rating
                name="time-10"
                className={classes.durationRate}
                value={Math.log10(duration)}
                max={5}
                precision={0.5}
                icon={<WatchLaterIcon fontSize="inherit" />}
                emptyIcon={<AccessTimeIcon fontSize="inherit" />}
                readOnly
              />
            ) : (
              t('actionLog.contactStart')
            )}
            <Box ml={2}>{durationText}</Box>
          </Box>
          <div className={classes.detail}>
            <div className={classes.main}>
              <p className={classes.object}>{title}</p>
              <p className={classes.object}>
                {path}
                {/* <a href={url}>{path}</a> */}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Divider />
    </>
  );
};
export default PageLogCard;
