import * as React from 'react';
import Card from '@material-ui/core/Card';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  ButtonGroup,
  IconButton,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Controller from '../../Components/Molecules/Sessions/CobrowsController';
import { ExitToApp, Power, PowerOff } from '@material-ui/icons';
import useStyles from '../../Styles/Console/Console';
import LinkContactReasonModal from '../../Components/Organisms/Sessions/LinkContactReasonModal';
import TextBox from '../../Components/Atoms/TextBox';
import ActionLogPane from '../../Components/Organisms/Sessions/ActionLogPane';
import { AgentContext, OperatorInfoContext } from '../../WithContext';
import useSearchUrlParam from '../../Components/Hooks/SearchUrlParam';
import { DrawConfig } from '@optipass/sdk/es/agent/types';
import { supabase } from '../../Utils/supabase';
import { definitions } from '../../Utils/types';
import { fetchJson } from '../../Utils/dataProvider';
import { useNotify } from '../../Components/Hooks/Notify';
import { Rnd } from 'react-rnd';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import MouseIcon from '@mui/icons-material/Mouse';
import CloseIcon from '@mui/icons-material/Close';
import useTranslation from '../../Components/Hooks/Translate';
import OperatorInfo from '../../Utils/OperatorInfo';

type windowSize = {
  width: number;
  height: number;
};
type Job = definitions['jobs'];

export default function Session() {
  const parentElement = React.useRef<HTMLDivElement>(null);
  const viewerElement = React.useRef<HTMLDivElement>(null);

  const classes = useStyles();
  const searchUrlParam = useSearchUrlParam();
  const notify = useNotify();
  const t = useTranslation('Session');

  const agent = React.useContext(AgentContext);
  const opInfo = React.useContext<OperatorInfo | undefined>(
    OperatorInfoContext
  );

  const [jobData, setJobData] = React.useState<Job[]>([]);
  const [job, setJob] = React.useState('');
  const [iid, setIid] = React.useState('');
  const [alias, setAlias] = React.useState('');
  const [sdkState, setSdkState] = React.useState(false);
  const [loadingState, setLoadingState] = React.useState(false);
  const [viewerRatio, setViewerRatio] = React.useState(1.0);
  const [floatPanel, setFloatPanel] = React.useState<panelVal>('small');
  type panelVal = 'close' | 'small' | 'full' | 'movable';

  const [localWindowSize, setLocalWindowSize] = React.useState<windowSize>({
    width: 1,
    height: 1,
  });
  const [remoteWindowSize, setRemoteWindowSize] = React.useState<windowSize>({
    width: 0,
    height: 0,
  });

  const [showActionLogs, setShowActionLogs] = React.useState(false);

  const [linkReasonModal, setLinkReasonModal] = React.useState(false);
  const [checkedContactReasonId, setCheckedContactReasonId] = React.useState<
    string | undefined
  >();
  const [contactReasonMemo, setContactReasonMemo] = React.useState<
    string | undefined
  >();

  React.useEffect(() => {
    (async () => {
      const { data, error } = await supabase
        .from<{ userId: string; jobs: Job }>('jobs_users')
        .select(`jobs (*)`)
        .match({ userId: opInfo?.id || '' });

      if (error || !data) {
        throw new Error();
      }

      setJobData(data.map((d) => d.jobs));
      setAlias(searchUrlParam('alias') || '');
      setJob(searchUrlParam('job') || '');
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const width = entries[0].contentRect.width;
      let height = entries[0].contentRect.height;
      if (width < 835) height -= 61;
      setLocalWindowSize({ width, height });
    });
    parentElement.current && resizeObserver.observe(parentElement.current);
    return (): void => {
      resizeObserver.disconnect();
    };
  }, []);

  React.useEffect(() => {
    const widthRatio = remoteWindowSize.width / (localWindowSize.width - 32);
    const heightRatio =
      (remoteWindowSize.height + 60) / (localWindowSize.height - 110); //65 + 10
    setViewerRatio(Math.min(1 / widthRatio, 1 / heightRatio));
  }, [localWindowSize, remoteWindowSize]);

  React.useEffect(() => {
    let ratio = viewerRatio;
    if (viewerRatio <= 0) {
      ratio = 1.0;
    } else if (1.5 <= viewerRatio) {
      ratio = 1.5;
    }
    if (viewerElement.current && viewerElement.current.firstChild) {
      const wrapper = viewerElement.current.firstChild as HTMLDivElement;
      wrapper.style.transformOrigin = 'left top';
      wrapper.style.transform = `scale(${ratio})`;
    }
  });

  async function getIIDByAlias(job_id: string, alias: string) {
    const { iid, userId } = await httpPostRequest(`/users/search`, {
      alias,
      job_id,
    });
    return { iid, userId };
  }

  async function getAccessToken(job_id: string, alias: string) {
    const { iid, userId } = await getIIDByAlias(job_id, alias);
    setIid(iid);
    agent?.getContextDataAccessor.setUserInfo({ userId });

    if (!iid) {
      throw new Error('Customer is not found!');
    }
    return await httpPostRequest(`/users/sessions`, {
      job: job_id,
      iid: iid,
      expire: 3600,
    });
  }

  async function httpPostRequest(url: string, body: object) {
    const result = await fetchJson(url, {
      method: 'POST',
      body: JSON.stringify(body),
    });
    return result.json;
  }

  async function connectButtonHandler(end?: boolean) {
    if (!agent) {
      notify(t('notification.test'), 'warning');
      return Promise.resolve();
    }
    if (!sdkState) {
      (async () => {
        agent.on('sdk-state-changed', (ev: unknown) => {
          const status = (ev as any).state as string;
          setSdkState(agent.isSessionActive());
          if (status === 'agent') {
            agent.on('agent-remote-window-size-changed', (ev: unknown) => {
              const size = ev as windowSize;
              setRemoteWindowSize(size);
            });
            agent.on('agent-customer-unload', () => {
              setLoadingState(true);
            });
            agent.on('agent-remote-window-size-changed', () => {
              setLoadingState(false);
            });
            agent.on('sdk-state-disconnected', () => {
              agent?.resetSdk();
            });
          }
        });
        try {
          const { key } = await getAccessToken(job, alias);
          agent.startSession(key);
        } catch (e: any) {
          if (!e.message) e.message = 'Unknown Error!';
          //notify(e.message, 'warning', 4000);
        }
      })();
    } else {
      if (end) {
        agent?.requestEndSession();
      } else {
        agent?.endSession();
      }
    }
  }

  async function sendImageDocHandler(files: FileList | null) {
    if (!sdkState) {
      //notify('Unknown Error!', 'error');
      return;
    }
    if (!files || files.length === 0) {
      return;
    }
    if (files.length > 1) {
      //notify('Cannot select more than one file.', 'warning');
      return;
    }

    switch (files[0].type) {
      case 'image/png':
      case 'image/gif':
      case 'image/jpeg':
      case 'application/pdf':
        await agent?.pushDocument(files[0]);
        return;
      default:
        //notify('File must be image or pdf.', 'warning');
        return;
    }
  }

  async function linkContactReason() {
    setLinkReasonModal(true);
  }

  async function toggleActionLogs() {
    setShowActionLogs(!showActionLogs);
  }

  const changeControllerMode = (mode: string) => {
    agent?.changeMode(mode);
  };

  const getFloatPanel = () => {
    return (
      <Rnd
        size={panelSize()}
        position={panelPosition()}
        minWidth={500}
        minHeight={220}
        bounds="window"
        style={panelStyle()}
        className={classes.rnd}
      >
        <IconButton
          onClick={() => {
            setFloatPanel('full');
          }}
        >
          <FullscreenIcon htmlColor={'#fff'} />
        </IconButton>
        <IconButton
          onClick={() => {
            setFloatPanel('small');
          }}
        >
          <FullscreenExitIcon htmlColor={'#fff'} />
        </IconButton>
        <IconButton
          onClick={() => {
            setFloatPanel('movable');
          }}
        >
          <MouseIcon htmlColor={'#fff'} />
        </IconButton>
        <IconButton
          onClick={() => {
            setFloatPanel('close');
          }}
          style={{ float: 'right' }}
        >
          <CloseIcon htmlColor={'#fff'} />
        </IconButton>
        <div
          style={{
            backgroundColor: '#fff',
            maxWidth: '90%',
            maxHeight: '80%',
            height: '1000px',
            width: '1000px',
            margin: 'auto',
            position: 'absolute',
            top: '25px',
            bottom: 0,
            right: 0,
            left: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography>Contents</Typography>
        </div>
      </Rnd>
    );
  };

  const panelSize = () => {
    if (floatPanel === 'small') {
      return { width: 600, height: localWindowSize.height / 3 };
    } else if (floatPanel === 'full') {
      return { width: localWindowSize.width, height: localWindowSize.height };
    } else {
      return;
    }
  };

  const panelPosition = () => {
    if (floatPanel === 'small') {
      return { x: localWindowSize.width, y: (localWindowSize.height * 2) / 3 };
    } else if (floatPanel === 'full') {
      return { x: 0, y: 0 };
    } else {
      return;
    }
  };

  const panelStyle = () => {
    if (floatPanel === 'close') {
      return { display: 'none' };
    } else if (floatPanel === 'movable') {
      return { display: 'inline-block', cursor: 'move' };
    } else {
      return { display: 'inline-block', cursor: 'default' };
    }
  };

  const canvasWidth = remoteWindowSize.width * viewerRatio;
  const canvasHeight = remoteWindowSize.height * viewerRatio;

  return (
    <div className={classes.root}>
      <Card className={classes.sessionPane} ref={parentElement}>
        <form className={classes.form} noValidate autoComplete="off">
          <FormControl className={classes.formControl}>
            <InputLabel id="job-select-label">{t('controller.job')}</InputLabel>
            <Select
              labelId="job-select-label"
              id="job-select"
              value={job}
              disabled={sdkState}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                setJob(event.target.value as string)
              }
            >
              {jobData?.map((job: any) => {
                const { id, name } = job;
                return (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <TextBox
            style={{ width: '15ch' }}
            label={t('controller.alias')}
            disabled={sdkState}
            value={alias}
            onChange={setAlias}
          />
          {!sdkState ? (
            <ButtonGroup size="large" className={classes.btnWrapper}>
              <Button
                onClick={() => connectButtonHandler()}
                variant="contained"
                color="primary"
                size="large"
                title={t('controller.connect')}
              >
                <Power />
                {t('controller.connect')}
              </Button>
            </ButtonGroup>
          ) : (
            <ButtonGroup size="large" variant="contained" color="default">
              <Button
                onClick={() => connectButtonHandler(true)}
                title={t('controller.disconnect')}
              >
                <PowerOff />
                {t('controller.disconnect')}
              </Button>
              <Button
                onClick={() => connectButtonHandler()}
                title={t('controller.leave')}
              >
                <ExitToApp />
                {t('controller.leave')}
              </Button>
            </ButtonGroup>
          )}
          {!sdkState ? (
            <></>
          ) : (
            <>
              <Controller
                changeMode={(mode: string) => changeControllerMode(mode)}
                setDrawConfig={(config: DrawConfig) => {
                  if (agent) {
                    agent.drawConfig = config;
                  }
                }}
                resetDrawingContent={() => agent?.resetDrawingContent()}
                pushDocument={sendImageDocHandler}
                linkReason={linkContactReason}
                showLogs={toggleActionLogs}
              />
              {linkReasonModal && (
                <LinkContactReasonModal
                  open={linkReasonModal}
                  jobId={job}
                  onClickClose={(contactReasonId, memo) => {
                    setCheckedContactReasonId(contactReasonId);
                    setContactReasonMemo(memo);
                    setLinkReasonModal(false);
                  }}
                  defaultContactReasonId={checkedContactReasonId}
                  defaultMemo={contactReasonMemo}
                />
              )}
            </>
          )}
        </form>
        <CardContent
          id="next-assist-viewer"
          className={classes.content}
          ref={viewerElement}
        />
        {loadingState && (
          <div
            style={{
              width: canvasWidth,
              height: canvasHeight,
              backgroundColor: 'rgba(0,0,0, 0.5)',
              marginTop: '-16px',
              marginLeft: '16px',
              position: 'absolute',
            }}
          >
            <div
              style={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                width: '100%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
                color: '#fff',
              }}
            >
              <CircularProgress />
              <Typography variant="body1">
                {t('leaveMessage.leaving')}
              </Typography>
              <Typography variant="caption">
                {t('leaveMessage.inoperable')}
              </Typography>
            </div>
          </div>
        )}
      </Card>
      {showActionLogs && (
        <>
          <CardContent className={classes.actionLogPane} style={{ padding: 0 }}>
            <ActionLogPane />
          </CardContent>
          {/* {getFloatPanel()} */}
        </>
      )}
    </div>
  );
}
