import { required, email } from 'react-admin';
import useTranslation from './Translate';

export const useValidation = () => {
  const t = useTranslation('Settings');
  return {
    name: [required()],
    email: [required(), email()],
    user_role: (value: any, allValues: any) => {
      if (!allValues.isAdmin && !value) {
        return t('users.validation.role');
      }
      return undefined;
    },
    job: [required()],
    role: [required()],
    category: [required()],
    contact_reason: [required()],
  };
};

export default useValidation;
