import cubejs from '@cubejs-client/core';
import { supabase } from './supabase';

const cubejsApi = cubejs(
  async () => {
    const session = supabase.auth.session();
    return session?.access_token || '';
  },
  {
    apiUrl: process.env.REACT_APP_CUBEJS_API_URL!,
    //apiUrl: 'https://stats.nadev.az.cba-japan.com/cubejs-api/v1',
  }
);

export default cubejsApi;
