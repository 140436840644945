import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    extendedIcon: {},
    controllerWrapper: {
      display: 'inline-flex',
      verticalAlign: 'bottom',
      position: 'relative',
      marginBottom: '13px !important',
    },
    inputFileBtnHide: {
      opacity: 0,
      appearance: 'none',
      position: 'absolute',
    },
    colorPickerWrapper: {
      marginLeft: '15px',
      position: 'relative',
    },
    colorPicker: {
      position: 'absolute',
      top: '43px',
      zIndex: 100,

      '& > div': {
        width: '213px !important',
      },
    },
    cdpCordinationWrapper: {
      marginLeft: '15px',
      position: 'relative',
    },
  })
);

export default useStyles;
