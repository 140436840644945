import { Typography } from '@material-ui/core';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <div>
      <Typography variant="h1">Access Denined</Typography>
      <Typography variant="body1">Please check your permission</Typography>
    </div>
  );
};
