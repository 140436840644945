import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MUIButton from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';

interface ButtonProps {
  label?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  onClick: () => void;
  variant?: 'text' | 'outlined' | 'contained';
  color?: 'inherit' | 'default' | 'primary' | 'secondary';
  size?: 'medium' | 'large' | 'small';
  children?: React.ReactNode;
  title?: string;
  style?: React.CSSProperties;
  type?: 'button' | 'reset' | 'submit';
  endIcon?: React.ReactNode;
}

const styles = makeStyles({
  btn: {
    border: 'none',
    margin: '5px 0px',
    padding: '10px 20px',
  },
});

const Button = (props: ButtonProps) => {
  const classes = styles();

  return (
    <MUIButton
      className={classes.btn}
      variant={props.variant}
      autoFocus={props.autoFocus}
      disabled={props.disabled}
      onClick={props.onClick}
      color={props.color}
      size={props.size}
      title={props.title}
      style={props.style}
      type={props.type}
      endIcon={props.endIcon}
    >
      {props.label && <Typography>{props.label}</Typography>}
      {props.children}
    </MUIButton>
  );
};

export default Button;
