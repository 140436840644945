import React, { useState } from 'react';
import { Button, ButtonGroup } from '@material-ui/core';
import {
  AttachFile,
  Delete,
  Visibility,
  Mouse,
  Create,
  Palette,
  History,
  Link,
} from '@material-ui/icons';
import { GithubPicker, ColorResult } from 'react-color';
import useStyles from '../../../Styles/Console/Controller';
import useTranslation from '../../Hooks/Translate';

interface Props {
  changeMode: CallableFunction;
  resetDrawingContent: CallableFunction;
  setDrawConfig: CallableFunction;
  pushDocument: CallableFunction;
  linkReason: CallableFunction;
  showLogs: CallableFunction;
}

export default function CobrowsController(props: Props) {
  const classes = useStyles();
  const t = useTranslation('Session');

  // Hooks
  const [mode, setMode] = useState('view');
  const [colorPicker, setColorPicker] = useState(false);

  const clicked = (newMode: string) => {
    props.changeMode(newMode);
    setMode(newMode);
    setColorPicker(false);
  };

  const resetDrawing = () => {
    props.resetDrawingContent();
  };

  const linkReason = () => {
    props.linkReason();
  };

  const showLogs = () => {
    props.showLogs();
  };

  const onChangeColor = (color: ColorResult) => {
    const config = {
      type: 'pen',
      color: color.hex,
      width: 5,
    };
    props.setDrawConfig(config);
  };

  const setFabColor = (value: string) => {
    return value === mode ? 'primary' : 'default';
  };

  return (
    <div className={classes.controllerWrapper}>
      <ButtonGroup>
        <Button
          variant="contained"
          onClick={() => clicked('view')}
          color={setFabColor('view')}
          size="large"
          title={t('controller.view')}
        >
          <Visibility className={classes.extendedIcon} />
        </Button>
        <Button
          variant="contained"
          onClick={() => clicked('control')}
          color={setFabColor('control')}
          size="large"
          title={t('controller.control')}
        >
          <Mouse className={classes.extendedIcon} />
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            clicked('drawing');
          }}
          color={setFabColor('drawing')}
          size="large"
          title={t('controller.drawing')}
        >
          <Create className={classes.extendedIcon} />
        </Button>

        <Button
          size="large"
          variant="contained"
          component="label"
          title={t('controller.attach')}
        >
          <AttachFile className={classes.extendedIcon} />
          <input
            type="file"
            hidden
            onChange={(event) => props.pushDocument(event.target.files)}
            accept=".png,.jpeg,.jpg,.gif,.pdf"
          />
        </Button>
      </ButtonGroup>

      {mode === 'drawing' ? (
        <ButtonGroup className={classes.colorPickerWrapper}>
          <Button
            variant="contained"
            size="large"
            color={colorPicker ? 'primary' : 'default'}
            title={t('controller.pallet')}
            onClick={() => setColorPicker(!colorPicker)}
          >
            <Palette className={classes.extendedIcon} />
          </Button>
          <Button
            variant="contained"
            size="large"
            color="default"
            title={t('controller.reset')}
            onClick={resetDrawing}
          >
            <Delete className={classes.extendedIcon} />
          </Button>
          {colorPicker && (
            <div className={classes.colorPicker}>
              <GithubPicker triangle="top-left" onChange={onChangeColor} />
            </div>
          )}
        </ButtonGroup>
      ) : (
        <></>
      )}

      <ButtonGroup className={classes.cdpCordinationWrapper}>
        <Button
          variant="contained"
          size="large"
          color="default"
          title={t('controller.log')}
          onClick={showLogs}
        >
          <History className={classes.extendedIcon} />
        </Button>
        <Button
          variant="contained"
          size="large"
          color="default"
          title={t('controller.reason')}
          onClick={linkReason}
        >
          <Link className={classes.extendedIcon} />
        </Button>
      </ButtonGroup>
    </div>
  );
}
