import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { FormControl, ListItem } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import SearchBar from '../../Atoms/SearchBar';
import SaveNewView from '../../Molecules/Analytics/SaveNewView';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { definitions } from '../../../Utils/types';

type View = definitions['views'];

const useStyles = makeStyles((theme) =>
  createStyles({
    viewItem: {
      position: 'relative',
    },
    deleteIcon: {
      position: 'absolute',
      right: '0',
      color: theme.palette.grey[400],
    },
  })
);

interface FacetFormSelectorProps {
  id: string;
  label?: string;
  variant?: 'filled' | 'standard' | 'outlined';
  selected?: string;
  data: View[];
  className?: string;
  onChange?: (view: View) => void;
  onCreateNew: (name: string) => void;
  onDelete: (view: View) => void;
}

const FacetFormSelector = (props: FacetFormSelectorProps) => {
  const classes = useStyles();

  const [searchResults, setSearchResults] = React.useState<string[]>(
    props.data.map((d) => d.name)
  );
  const onChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    const key = event.target.value as string;
    const selectedData = props.data.find((d) => d.id === key);
    if (selectedData) {
      props.onChange?.(selectedData);
    } else {
      throw new Error();
    }
  };
  const views = props.data.map((item) => {
    return item.name;
  });
  const getResults = (result: string[]) => {
    setSearchResults(result);
  };

  const deleteView = (ev: React.MouseEvent, view: View) => {
    ev.preventDefault();
    ev.stopPropagation();

    props.onDelete(view);
  };

  const filteredItems = props.data.filter((d) =>
    searchResults.includes(d.name)
  );

  return (
    <FormControl fullWidth variant={props.variant || 'filled'}>
      <InputLabel id={props.id}>
        <Typography variant="h6">{props.label}</Typography>
      </InputLabel>
      <Select
        labelId={props.id}
        value={
          !!filteredItems.find((d) => d.id === props.selected)
            ? props.selected
            : ''
        }
        label={props.label}
        onChange={onChange}
        className={props.className}
        native={false}
      >
        <SearchBar data={views} getResults={getResults} />
        {filteredItems.map((d) => (
          <ListItem
            key={d.id}
            value={d.id}
            className={classes.viewItem}
            dense={true}
          >
            <Typography variant="body1">{d.name}</Typography>
            {props.selected !== d.id && (
              <IconButton
                className={classes.deleteIcon}
                onClick={(ev) => deleteView(ev, d)}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </ListItem>
        ))}
        <Divider />
        <SaveNewView onCreateNew={props.onCreateNew} />
      </Select>
    </FormControl>
  );
};

export default FacetFormSelector;
