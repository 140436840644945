import { supabaseAuthProvider } from 'ra-supabase';
import { pageSettings } from './pageSettings';
import Permission from './Permission';
import { supabase } from './supabase';
import { definitions } from './types';

type ErrorObject = { message: string; status: number; body: Object };
export const authProvider = supabaseAuthProvider(supabase, {
  getIdentity: async (user) => {
    if (!user) {
      throw new Error();
    }

    const { data, error } = await supabase
      .from('user_profiles')
      .select('id, name')
      .match({ id: user.id })
      .single();

    if (!data || error) {
      throw new Error();
    }

    return {
      id: data.id,
      fullName: `${data.first_name} ${data.last_name}`,
    };
  },
});

authProvider.loginWithGoogle = async () => {
  const { error } = await supabase.auth.signIn({
    provider: 'google',
  });
  if (error) {
    throw error;
  }
  return undefined;
};

authProvider.loginWithMS = async () => {
  return undefined;
};

authProvider.checkError = async (error: ErrorObject) => {
  const status = error.status;
  if (status === 401 || status === 403) {
    localStorage.removeItem('auth');
    throw status;
  }
  // other error code (404, 500, etc): no need to log out
  return;
};

authProvider.getPermissions = async () => {
  const { data, error } = await supabase
    .from<definitions['user_permission']>('user_permission')
    .select()
    .single();
  if (error || !data) {
    const pathname = window.location.pathname;
    for (let key in pageSettings) {
      if (
        pageSettings[key].path === pathname &&
        pageSettings[key].guestAccess
      ) {
        return Promise.resolve(Permission.createGuest());
      }
    }

    return Promise.reject();
  } else {
    return Promise.resolve(new Permission(data));
  }
};

authProvider.editProfile = async () => {
  return Promise.resolve();
};
