import React from 'react';
import ModalBase from '../../Molecules/Global/ModalBase';
import DashboardNameContent from '../../Molecules/Analytics/DashboardNameContent';
import SelectGroupsContent from '../../Atoms/SelectGroupsContent';
import CheckAsDefaultContent from '../../Molecules/Analytics/CheckAsDefaultContent';
import { definitions } from '../../../Utils/types';
import {
  DashboardEditProfile,
  DashboardAddProfile,
} from '../../../Pages/Analytics/DashboardAdmin';
import useTranslation from '../../Hooks/Translate';

type Job = definitions['jobs'];
type Dashboard = definitions['dashboards'];

interface AddDashboardModalProps {
  open: boolean;
  jobs: Job[];
  defaultDashboard?: Dashboard;
  defaultJobIds?: string[];
  onClickSaveForNew: (data: DashboardAddProfile) => void;
  onClickSaveForEdit: (data: DashboardEditProfile) => void;
  onClickCancel: () => void;
}

const AddDashboardModal = (props: AddDashboardModalProps) => {
  const { defaultDashboard, defaultJobIds } = props;
  const t = useTranslation('Analytics');

  const [selectedJobIds, setSelectedJobIds] = React.useState<string[]>(
    defaultJobIds || []
  );
  const [dashboardName, setDashboardName] = React.useState(
    defaultDashboard ? defaultDashboard.name : ''
  );
  const [isDefault, setIsDefault] = React.useState(
    defaultDashboard ? defaultDashboard.default || false : false
  );
  const [error, setError] = React.useState(false);

  const onChangeJob = (event: { target: { value: any } }) => {
    const {
      target: { value },
    } = event;
    const selectedJobIds: string[] =
      typeof value === 'string' ? value.split(',') : value;
    setSelectedJobIds(selectedJobIds);
  };

  const onClickApprove = () => {
    if (dashboardName === '') {
      setError(true);
      return;
    }
    if (defaultDashboard) {
      props.onClickSaveForEdit({
        id: defaultDashboard.id,
        name: dashboardName,
        jobIds: selectedJobIds,
        isDefault,
      });
    } else {
      props.onClickSaveForNew({
        name: dashboardName,
        jobIds: selectedJobIds,
        isDefault,
      });
    }
  };

  return (
    <ModalBase
      open={props.open}
      title={
        defaultDashboard ? t('dashboardadmin.edit') : t('dashboardadmin.add')
      }
      maxWidth="sm"
      onClickCancel={props.onClickCancel}
      onClickApprove={onClickApprove}
    >
      <DashboardNameContent
        dashboardName={dashboardName}
        onChange={setDashboardName}
        error={error}
      ></DashboardNameContent>
      <div style={{ width: '90%', margin: '25px 5% 0px 5%' }}>
        <SelectGroupsContent
          title={t('dashboardedit.sharetojob')}
          caption="Job"
          onChange={onChangeJob}
          items={props.jobs.map((x) => ({ id: x.id, displayName: x.name }))}
          selectedItemIds={selectedJobIds}
        ></SelectGroupsContent>
      </div>
      <CheckAsDefaultContent
        onChange={(event) => setIsDefault(event.target.checked)}
        checked={isDefault}
      ></CheckAsDefaultContent>
    </ModalBase>
  );
};

export default AddDashboardModal;
