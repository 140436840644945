import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    common: {
      black: '#000',
      white: '#fff',
    },
    primary: {
      main: '#3364A0',
      dark: '#80a8cc',
      light: '#3364A0',
      contrastText: '#fff',
    },
    secondary: {
      main: '#E85151',
    },

    text: {
      primary: 'rgba(0,0,0,0.87)',
      secondary: 'rgba(0,0,0,0.6)',
      disabled: 'rgba(0,0,0,0.38)',
      hint: '#fff',
    },
    divider: 'rgba(0,0,0,0.12)',
  },
  typography: {
    h1: {
      fontSize: 'h1',
    },
    h2: {
      fontSize: 'h2',
    },
    h3: {
      fontSize: 'h3',
    },
    h4: {
      fontSize: 'h4',
    },
    h5: {
      fontSize: 'h5',
    },
    h6: {
      fontSize: 'h6',
    },
  },
});

export default theme;
