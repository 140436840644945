import * as React from 'react';
import { Admin, Resource } from 'react-admin';
import { UserList, UserEdit, UserCreate } from './Users';
import { JobCreate, JobEdit, JobList } from './Jobs';
import { RoleList, RoleEdit, RoleCreate } from './Roles';
import {
  ContactReasonCategoryList,
  ContactReasonCategoryEdit,
  ContactReasonCategoryCreate,
} from './ContactReasonCategory';
import {
  People,
  Assignment,
  VpnKey,
  Class,
  SpeakerNotes,
} from '@material-ui/icons';
import {
  ContactReasonList,
  ContactReasonEdit,
  ContactReasonCreate,
} from './ContactReasons';
import { dataProvider } from '../../Utils/dataProvider';
import { authProvider } from '../../Utils/authProvider';
import NotFound from '../../Components/Atoms/NotFound';
import Permission from '../../Utils/Permission';
import SettingsLayout from '../../Components/Organisms/Settings/SettingsLayout';
import japaneseMessages from '@bicstone/ra-language-japanese';
import englishMessages from 'ra-language-english';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import useTranslation from '../../Components/Hooks/Translate';
import i18n from 'i18next';
import { PermissionContext } from '../../WithContext';

const Settings = () => {
  const t = useTranslation('Settings');
  const permission = React.useContext(PermissionContext);
  // 管理者ユーザでなければ、強制的にトップページに遷移させる
  // ベルさん向けリリースでは対象外機能のため暫定的
  if (!permission?.isAdmin()) {
    window.location.href = '/';
    return <></>;
  }

  const generateComponents = (p: Permission) => {
    const components: React.ReactElement[] = [];
    if (p.hasRead('users')) {
      components.push(
        <Resource
          name="user_profiles"
          list={UserList}
          edit={UserEdit}
          create={p.hasCreate('users') ? UserCreate : undefined}
          icon={People}
          options={{ label: t('users.name') }}
        />
      );
    }
    if (p.hasRead('jobs')) {
      components.push(
        <Resource
          name="jobs"
          list={JobList}
          edit={JobEdit}
          create={p.hasCreate('jobs') ? JobCreate : undefined}
          icon={Assignment}
          options={{ label: t('jobs.name') }}
        />
      );
    }
    // ロール管理はベルさん向けリリースの対象外機能のためコメントアウト
    // if (p.hasRead('roles')) {
    //   components.push(
    //     <Resource
    //       name="roles"
    //       list={RoleList}
    //       edit={RoleEdit}
    //       create={p.hasCreate('roles') ? RoleCreate : undefined}
    //       icon={VpnKey}
    //       options={{ label: t('roles.name') }}
    //     />
    //   );
    // }
    if (p.hasRead('contactReasons')) {
      components.push(
        <Resource
          name="contact_reason_categories"
          list={ContactReasonCategoryList}
          edit={ContactReasonCategoryEdit}
          create={
            p.hasCreate('contactReasons')
              ? ContactReasonCategoryCreate
              : undefined
          }
          icon={Class}
          options={{ label: t('contact_reason_categories.name') }}
        />
      );
      components.push(
        <Resource
          name="contact_reasons"
          list={ContactReasonList}
          edit={ContactReasonEdit}
          create={
            p.hasCreate('contactReasons') ? ContactReasonCreate : undefined
          }
          icon={SpeakerNotes}
          options={{ label: t('contact_reasons.name') }}
        />
      );
    }

    return components;
  };

  return (
    <Admin
      catchAll={NotFound}
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={resolveRAi18nProvider(i18n.language)}
      disableTelemetry
      layout={SettingsLayout}
      loginPage={() => {
        window.location.href = '/';
        return <></>;
      }}
    >
      {(p: Permission) => {
        // if the user haven't logined, doesn't generate components.
        if (p.isGuest()) {
          return [<></>];
        } else {
          return generateComponents(p);
        }
      }}
    </Admin>
  );
};

export default Settings;

const resolveRAi18nProvider = (defaultLocale?: string) => {
  const messages = {
    ja: japaneseMessages,
    en: englishMessages,
  } as Record<string, any>;

  return polyglotI18nProvider(
    (locale) => (messages[locale] ? messages[locale] : messages.en),
    defaultLocale
  );
};
