import * as React from 'react';
import { Typography } from '@material-ui/core';
import TextBox from '../../Atoms/TextBox';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import useTranslation from '../../Hooks/Translate';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    setContent: {
      width: '90%',
      margin: '25px 5% 0px 5%',
    },
    setInnerContent: {
      width: '100%',
      marginTop: '5px',
    },
    title: {
      fontSize: '16px',
    },
  })
);

interface DashboardNameContentProps {
  dashboardName: string;
  onChange: (text: string) => void;
  error?: boolean;
}

const DashboardNameContent = (props: DashboardNameContentProps) => {
  const classes = useStyles();
  const t = useTranslation('Analytics');
  return (
    <div className={classes.setContent}>
      <Typography variant="h3" className={classes.title}>
        {t('dashboardadd.title')}
      </Typography>
      <TextBox
        className={classes.setInnerContent}
        value={props.dashboardName}
        id="outlined-basic"
        variant="outlined"
        onChange={props.onChange}
        error={props.error}
      />
    </div>
  );
};

export default DashboardNameContent;
