import * as React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  Create,
  Datagrid,
  TextField,
  DateField,
  Button,
  useRedirect,
  usePermissions,
  useNotify,
  useRecordContext,
  ResourceComponentPropsWithId,
  useRefresh,
} from 'react-admin';
import { fetchJson } from '../../Utils/dataProvider';
import ModalBase from '../../Components/Molecules/Global/ModalBase';
import CustomToolBar from '../../Components/Hooks/CreateCustomToolBar';
import useHandleOperationFailure from '../../Components/Hooks/HandleOperationFailure';
import Loading from '../../Components/Atoms/Loading';
import SettingList from '../../Components/Molecules/Settings/SettingList';
import useTranslation from '../../Components/Hooks/Translate';
import SelectGroupsContent from '../../Components/Atoms/SelectGroupsContent';
import useValidation from '../../Components/Hooks/Validation';

import { definitions } from '../../Utils/types';
import { supabase } from '../../Utils/supabase';
import { useEffect, useState } from 'react';
import { useHandleError } from '../../Components/Hooks/HandleError';
type UserProfile = definitions['user_profiles'];
type Job = definitions['jobs'];
type JobsUser = definitions['jobs_users'];

/** ジョブ一覧 */
export const JobList = (props: any) => {
  const { loading, permissions } = usePermissions();
  const t = useTranslation('Settings');

  if (loading) {
    return <Loading />;
  } else {
    return (
      <SettingList
        {...props}
        permissions={permissions}
        create={permissions.hasCreate('jobs')}
        confirmtitle={t('jobs.confirmTitle')}
      >
        <Datagrid rowClick="edit">
          <TextField source="name" label={t('jobs.field.name')} />
          <DateField source="createdAt" label={t('common.field.createdAt')} />
          <DateField source="updatedAt" label={t('common.field.modifiedAt')} />
        </Datagrid>
      </SettingList>
    );
  }
};

/** ユーザー一覧編集用のカスタムコントロール */
const UsersField = (props: { onSelectUsers: (ids: string[]) => void }) => {
  const record = useRecordContext<Job>();
  const [selectedUserIds, setSelectedUserIds] = React.useState<string[]>([]);
  const [userProfiles, setUserProfiles] = useState<UserProfile[]>([]);
  const [userLoaded, setUserLoaded] = useState(false);
  const handleError = useHandleError();
  const t = useTranslation('Settings');

  const onChangeUser = (event: { target: { value: any } }) => {
    const {
      target: { value },
    } = event;
    const selectedIds: string[] =
      typeof value === 'string' ? value.split(',') : value;
    setSelectedUserIds(selectedIds);
    props.onSelectUsers(selectedIds);
  };

  // 非同期データ取得パターン
  useEffect(() => {
    let unmounted = false;
    (async () => {
      try {
        // ユーザー一覧取得
        const userProfilesResult = await supabase
          .from<UserProfile>('user_profiles')
          .select();
        if (userProfilesResult.error != null) {
          throw userProfilesResult.error; // エラーであれば例外を投げる
        }
        const userProfiles = userProfilesResult.data ?? [];

        // ジョブに紐づくユーザー一覧取得
        const jobUsersResult = await supabase
          .from<JobsUser>('jobs_users')
          .select()
          .eq('jobId', record.id);
        if (jobUsersResult.error != null) {
          throw jobUsersResult.error; // エラーであれば例外を投げる
        }
        const jobUsers = jobUsersResult.data ?? [];

        if (!unmounted) {
          setUserProfiles(userProfiles);
          setSelectedUserIds(jobUsers.map((x) => x.userId));
        }
      } catch (error: any) {
        handleError('PostgresError', { message: error.message });
      }

      if (!unmounted) {
        setUserLoaded(true);
      }
    })();
    const cleanup = () => {
      unmounted = true;
    };
    return cleanup;
    // eslint-disable-next-line
  }, [record.id]);

  return (
    <>
      <div style={{ marginBottom: '35px', width: '90%', minWidth: '256px' }}>
        <SelectGroupsContent
          caption={t('jobs.field.users')}
          onChange={onChangeUser}
          items={userProfiles.map((x) => ({ id: x.id, displayName: x.name }))}
          selectedItemIds={selectedUserIds}
          disabled={userLoaded === false}
        ></SelectGroupsContent>
      </div>
    </>
  );
};

/** ジョブ編集画面 */
export const JobEdit = (
  props: ResourceComponentPropsWithId<{ id?: string }>
) => {
  const [spinner, setSpinner] = React.useState(false);
  const [cfmDialog, setCfmDialog] = React.useState(false);
  const [pwdDialog, setPwdDialog] = React.useState(false);
  const [primary, setPrimary] = React.useState(true);
  const [password, setPassowrd] = React.useState('');
  const { loading, permissions } = usePermissions();

  const [jobUsers, setJobUsers] = useState<string[] | undefined>(undefined);
  const handleOperationFailure = useHandleOperationFailure();
  const handleError = useHandleError();
  const redirect = useRedirect();
  const notify = useNotify();
  const t = useTranslation('Settings');
  const v = useValidation();
  const refresh = useRefresh();

  const JobTitle = (prop: any) => {
    const title = `${t('jobs.name')}: ${prop.record.name}`;
    return <span>{title}</span>;
  };

  // 保存処理
  const save = async (job: Job) => {
    try {
      // ジョブの保存
      const jobsResult = await supabase
        .from<Job>('jobs')
        .update(job)
        .match({ id: job.id });
      if (jobsResult.error != null) {
        throw jobsResult.error; // エラーであれば例外を投げる
      }

      // ユーザー関連の保存（現状のものを取得して差分更新）
      if (jobUsers != null) {
        const jobUsersResult = await supabase
          .from<JobsUser>('jobs_users')
          .select()
          .match({ jobId: props.id });
        if (jobUsersResult.error != null) {
          throw jobUsersResult.error; // エラーであれば例外を投げる
        }

        // 追加されているユーザーを追加
        const oldJobUserIds = (jobUsersResult.data ?? []).map((x) => x.userId);
        for (const addUserId of jobUsers.filter(
          (x) => oldJobUserIds.includes(x) === false
        )) {
          const jobUserInsertResult = await supabase
            .from<JobsUser>('jobs_users')
            .insert({ jobId: props.id, userId: addUserId });
          if (jobUserInsertResult.error != null) {
            throw jobUserInsertResult.error; // エラーであれば例外を投げる
          }
        }

        // 削除されているユーザーを削除
        for (const removeUserId of oldJobUserIds.filter(
          (x) => jobUsers.includes(x) === false
        )) {
          const jobsUsersInsertResult = await supabase
            .from<JobsUser>('jobs_users')
            .delete()
            .match({ jobId: props.id, userId: removeUserId });
          if (jobsUsersInsertResult.error != null) {
            throw jobsUsersInsertResult.error; // エラーであれば例外を投げる
          }
        }
      }
    } catch (error: any) {
      handleError('PostgresError', { message: error.message });
      return;
    }

    notify('ra.notification.updated', 'info', { smart_count: 1 });
    redirect(`/jobs`);
    refresh(true);
  };

  if (loading) {
    return <Loading />;
  } else {
    const hasEdit = permissions.hasEdit('jobs');

    return (
      <Edit
        mutationMode="pessimistic"
        onFailure={handleOperationFailure}
        title={<JobTitle />}
        {...props}
      >
        <SimpleForm
          toolbar={
            <CustomToolBar
              permissions={permissions}
              component="jobs"
              saveButtonForceEnabled={jobUsers != null}
              options={props.options}
            />
          }
          save={save}
        >
          <TextInput source="id" disabled label={t('jobs.field.id')} />

          <TextInput
            source="name"
            disabled={!hasEdit}
            label={t('jobs.field.name')}
            validate={v.job}
          />

          <UsersField onSelectUsers={setJobUsers} />

          <Button
            label={t('jobs.field.apiKey')}
            onClick={() => {
              setPrimary(true);
              setCfmDialog(true);
            }}
            disabled={!hasEdit}
            color="primary"
            variant="outlined"
            style={{ width: '300px', marginBottom: '1em' }}
          ></Button>
          <Button
            label={t('jobs.field.apikeySecondary')}
            onClick={() => {
              setPrimary(false);
              setCfmDialog(true);
            }}
            disabled={!hasEdit}
            color="primary"
            variant="outlined"
            style={{ width: '300px' }}
          ></Button>

          {spinner && <Loading />}
          <ModalBase
            open={cfmDialog}
            messageApprove={'agree'}
            title={t('jobs.message.alert.title')}
            onClickCancel={() => {
              setCfmDialog(false);
            }}
            onClickApprove={async () => {
              setCfmDialog(false);
              setSpinner(true);
              const to = primary ? 'primary' : 'secondary';
              const { body: data } = await fetchJson(
                `/jobs/passwords/${to}/${props.id}`
              );

              setSpinner(false);
              setPwdDialog(true);
              setPassowrd(data);
            }}
          >
            {t('jobs.message.alert.text')}
          </ModalBase>
          <ModalBase
            open={pwdDialog}
            title={t('jobs.message.newKey')}
            onClickApprove={() => setPwdDialog(false)}
          >
            {password}
          </ModalBase>
        </SimpleForm>
      </Edit>
    );
  }
};

export const JobCreate = (props: any) => {
  const [spinner, setSpinner] = React.useState(false);
  const [pwdDialog, setPwdDialog] = React.useState(false);
  const [jobId, setJobId] = React.useState('');
  const [pwdPrimary, setPwdPrimary] = React.useState('');
  const [pwdSecondary, setPwdSecondary] = React.useState('');
  const handleOperationFailure = useHandleOperationFailure();
  const t = useTranslation('Settings');
  const v = useValidation();
  const redirect = useRedirect();
  const notify = useNotify();

  return (
    <Create
      {...props}
      onFailure={handleOperationFailure}
      onSuccess={async ({ data }) => {
        notify('ra.notification.created', 'info', { smart_count: 1 });
        setSpinner(true);
        setJobId(data.id);

        const { body: dataPri } = await fetchJson(
          `/jobs/passwords/primary/${data.id}`
        );
        setPwdPrimary(dataPri);

        const { body: dataSec } = await fetchJson(
          `/jobs/passwords/secondary/${data.id}`
        );
        setPwdSecondary(dataSec);

        setSpinner(false);
        setPwdDialog(true);
      }}
    >
      <SimpleForm>
        {/* <TextInput source="id" /> */}
        <TextInput
          source="name"
          label={t('jobs.field.name')}
          validate={v.job}
        />
        <ModalBase
          open={pwdDialog}
          title={t('jobs.message.thisKey')}
          onClickApprove={() => {
            setPwdDialog(false);
            if (jobId) {
              redirect(`/jobs`);
            }
          }}
        >
          <p>
            <strong>{t('jobs.message.primary')}</strong>
          </p>
          <p>{pwdPrimary}</p>
          <p>
            <strong>{t('jobs.message.secondary')}</strong>
          </p>
          <p>{pwdSecondary}</p>
        </ModalBase>
        {spinner && <Loading />}
      </SimpleForm>
    </Create>
  );
};
