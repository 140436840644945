import * as React from 'react';
import PropTypes, { string } from 'prop-types';
import { styled } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '../../Atoms/Button';
import { Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import BackgroundTheme from '../../../Styles/BackgroundTheme';
import useTranslation from '../../Hooks/Translate';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuDialogContent-root': {
    //padding: theme.spacing(2),
  },
  '& .MuDialogActions-root': {
    //padding: theme.spacing(1),
  },
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {},
    titleWrapper: {
      width: '100%',
    },
    title: {
      color: '#fff',
      fontWeight: 'bold',
      fontSize: '21px',
    },
  })
);

const BootstrapDialogTitle = (props: any) => {
  const { children, onClose, style, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 2 }}
      {...other}
      style={{
        backgroundColor: BackgroundTheme.palette.primary.main,
        color: '#fff',
      }}
    >
      {children}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.any,
  maxWidth: string,
  bodyColor: string,
  footerColor: string,
};

interface ModalBaseProps {
  open: boolean;
  title?: string;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  bodyColor?: string;
  footerColor?: string;
  children?: React.ReactNode;
  messageApprove?: string;
  onClickApprove?: () => void;
  onClickCancel?: () => void;
}

export default function ModalBase(props: ModalBaseProps) {
  const classes = useStyles();
  const t = useTranslation('Global');

  return (
    <BootstrapDialog
      aria-labelledby="customized-dialog-title"
      open={props.open}
      className={classes.wrapper}
      // fullWidth={true}
      maxWidth={props.maxWidth}
    >
      <BootstrapDialogTitle
        onClose={() => {
          return;
        }}
        className={classes.titleWrapper}
      >
        <Typography className={classes.title}>
          {props.title ?? t('modal.title')}
        </Typography>
      </BootstrapDialogTitle>
      <DialogContent style={{ backgroundColor: props.bodyColor }}>
        {props.children}
      </DialogContent>
      <DialogActions style={{ backgroundColor: props.footerColor }}>
        {props.onClickCancel && (
          <Button
            variant={'contained'}
            onClick={props.onClickCancel}
            label={t('modal.cancel')}
            style={{ backgroundColor: '#e0e0e0' }}
          ></Button>
        )}
        {props.onClickApprove && (
          <Button
            autoFocus
            variant={'contained'}
            onClick={props.onClickApprove}
            color="primary"
            label={props.messageApprove ?? t('modal.approve')}
          ></Button>
        )}
      </DialogActions>
    </BootstrapDialog>
  );
}
