const useConfirm = () => {
  return async (message: string, approveMessage?: string, title?: string) => {
    return new Promise<boolean>((resolve) => {
      const responseListener = (ev: Event) => {
        resolve((ev as CustomEvent).detail as boolean);
        window.removeEventListener('respondConfirm', responseListener);
      };

      window.addEventListener('respondConfirm', responseListener);

      const event = new CustomEvent('confirm', {
        detail: {
          message,
          approveMessage,
          title,
        },
      });
      window.dispatchEvent(event);
    });
  };
};

export default useConfirm;
