import React from 'react';
import { Layout, usePermissions } from 'react-admin';
import Permission from '../../../Utils/Permission';
import SettingsAppBar from './SettingsAppBar';
import SettingsSidebar from './SettingsSidebar';
import { makeStyles } from '@material-ui/core';

const styles = makeStyles({
  layout: {
    '& #main-content': {
      height: 'calc(100vh - 105px)',
      overflow: 'auto',
    },
    '& main': {
      position: 'absolute',
      height: 'calc(100% - 100px)',
      width: '100%',
    },
  },
});

const SettingsLayout = (props: any) => {
  const classes = styles();
  const { loading, permissions } = usePermissions();
  if (loading || (permissions as Permission).isGuest()) {
    return <Layout {...props} appBar={() => <></>} sidebar={() => <></>} />;
  } else {
    return (
      <Layout
        {...props}
        appBar={SettingsAppBar}
        sidebar={SettingsSidebar}
        className={classes.layout}
      />
    );
  }
};

export default SettingsLayout;
