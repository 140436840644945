import { createTheme } from '@material-ui/core/styles';
import { ThemeOptions } from '@material-ui/core';
import { Overrides } from '@material-ui/core/styles/overrides';

const theme = createTheme({
  palette: {
    common: {
      black: '#000',
      white: '#fff',
    },
    primary: {
      main: '#80A8CC',
      // dark: '#80a8cc',
      // light: '#3364A0',
      contrastText: '#fff',
    },
    secondary: {
      main: '#D0E1EE',
    },
    text: {
      primary: 'rgba(0,0,0,0.87)',
      secondary: 'rgba(0,0,0,0.6)',
      disabled: 'rgba(0,0,0,0.38)',
      hint: '#fff',
    },
    divider: 'rgba(0,0,0,0.12)',
  },
  typography: {
    h1: {
      fontSize: 'h1',
    },
    h2: {
      fontSize: 'h2',
    },
    h3: {
      fontSize: 'h3',
    },
    h4: {
      fontSize: 'h4',
    },
    h5: {
      fontSize: 'h5',
    },
    h6: {
      fontSize: 'h6',
    },
  },
});

export default theme;

export interface RaThemeOverrides extends Overrides {
  [key: string]: any;
}

export interface RaThemeOptions extends ThemeOptions {
  sidebar?: {
    width?: number;
    closedWidth?: number;
  };
  overrides?: RaThemeOverrides;
}
