import { makeStyles } from '@material-ui/core';
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  ListItemSecondaryAction,
} from '@material-ui/core';
import Button from '../../Atoms/Button';
import SvgIcon from '@material-ui/core/SvgIcon';
import BackgroundTheme from '../../../Styles/BackgroundTheme';
import theme from '../../../Styles/Theme';
import { ReactComponent as Area } from '../../../Images/GadgetIcons/area.svg';
import { ReactComponent as Bar } from '../../../Images/GadgetIcons/bar.svg';
import { ReactComponent as Codegraph } from '../../../Images/GadgetIcons/codegraph.svg';
import { ReactComponent as Heatmap } from '../../../Images/GadgetIcons/heatmap.svg';
import { ReactComponent as Line } from '../../../Images/GadgetIcons/line.svg';
import { ReactComponent as Number } from '../../../Images/GadgetIcons/number.svg';
import { ReactComponent as Other } from '../../../Images/GadgetIcons/other.svg';
import { ReactComponent as Pie } from '../../../Images/GadgetIcons/pie.svg';
import { ReactComponent as Sanburst } from '../../../Images/GadgetIcons/sanburst.svg';
import { ReactComponent as Sankey } from '../../../Images/GadgetIcons/sankey.svg';
import { ReactComponent as Table } from '../../../Images/GadgetIcons/table.svg';
import { ReactComponent as WordCrowd } from '../../../Images/GadgetIcons/wordcrowd.svg';
import { definitions } from '../../../Utils/types';
import { useGetGadgetType } from '../../Hooks/AccessGadgetFacetMaster';
import useTranslation from '../../Hooks/Translate';
import useGetGadgetProfile from '../../Hooks/GetGadgetProfile';

const styles = makeStyles({
  wrapper: {
    backgroundColor: BackgroundTheme.palette.secondary.main,
    margin: '20px 5%',
    padding: '20px',
    borderRadius: '5px',
    width: '90%',
  },
  avatar: { backgroundColor: '#fff' },
  listdescription: {
    width: '100%',
    marginRight: '120px',
  },
});

type Gadget = definitions['dashboard_gadgets']['gadget'];

interface GadgetListProps {
  alreadyExistings: Gadget[];
  gadgets: Gadget[];
  onSelect: (gadget: Gadget) => void;
}
const GadgetList = (props: GadgetListProps) => {
  const t = useTranslation('Analytics');
  const { gadgets, onSelect } = props;
  const classes = styles();
  const getGadgetType = useGetGadgetType();
  const getGadgetProfile = useGetGadgetProfile();

  const selectImage = (g: Gadget) => {
    switch (getGadgetType(g)) {
      case 'TimelineChart':
        return <Line />;
      case 'Area':
        return <Area />;
      case 'BarChart':
        return <Bar />;
      case 'PieChart':
        return <Pie />;
      case 'Table':
        return <Table />;
      case 'Number':
        return <Number />;
      case 'Heatmap':
        return <Heatmap />;
      case 'Sankey':
        return <Sankey />;
      case 'BipartiteGraph':
        return <Other />;
      case 'CodeGraph':
        return <Codegraph />;
      case 'Wordcrowd':
        return <WordCrowd />;
      case 'Sanburst':
        return <Sanburst />;
      default:
        return <></>;
    }
  };

  const rows = gadgets.map((gadget) => {
    const isAlreadyExist = !!props.alreadyExistings.find((g) => g === gadget);
    const unimplemented = !getGadgetProfile(gadget).implemented;
    const label = () => {
      if (unimplemented) {
        return t('gadgetlist.unimplemented');
      } else if (isAlreadyExist) {
        return t('gadgetlist.exist');
      } else {
        return t('gadgetlist.add');
      }
    };
    return (
      <ListItem key={gadget} className={classes.wrapper}>
        <ListItemAvatar>
          <Avatar className={classes.avatar}>
            <SvgIcon>{selectImage(gadget)}</SvgIcon>
          </Avatar>
        </ListItemAvatar>
        <div className={classes.listdescription}>
          <ListItemText
            primary={getGadgetProfile(gadget).name}
            secondary={getGadgetProfile(gadget).description}
          />
        </div>
        <ListItemSecondaryAction>
          <Button
            variant="contained"
            color="primary"
            disabled={unimplemented || isAlreadyExist}
            onClick={() => onSelect(gadget)}
            label={label()}
            style={{
              backgroundColor:
                unimplemented || isAlreadyExist ? '#b6c5d1' : '#fff',
              color:
                unimplemented || isAlreadyExist
                  ? '#88939c'
                  : theme.palette.primary.light,
              fontWeight: 'bold',
              right: '50px',
            }}
          ></Button>
        </ListItemSecondaryAction>
      </ListItem>
    );
  });
  return <List>{rows}</List>;
};

export default GadgetList;
