import * as React from 'react';
import { authProvider } from '../Utils/authProvider';
import logo from '../Images/Optipass.png';
import { Button, TextField } from '@material-ui/core';
import { useState } from 'react';
import Loading from '../Components/Atoms/Loading';
import { Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import useTranslation from '../Components/Hooks/Translate';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useNotify } from '../Components/Hooks/Notify';
import SSOLoginForm from '../Components/Organisms/Global/SSOLoginForm';
import { supabase } from '../Utils/supabase';
import useMoveTo from '../Components/Hooks/Moveto';

const style = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translateX(-50%) translateY(-50%)',
      textAlign: 'center',
      width: '330px',
    },
    logo: {
      width: '320px',
      height: '320px',
    },
    buttonGroup: {
      width: '100%',
      textAlign: 'center',
      marginTop: '1em',
    },
    buttonWithSpinner: { position: 'relative' },
    signInButton: {
      width: '215px',
      marginTop: '0.5em',
    },
  })
);

const Login = () => {
  const classes = style();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const t = useTranslation('Auth');
  const notify = useNotify();
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);

  const login = async () => {
    if (!email) {
      setErrorEmail(true);
    } else {
      setErrorEmail(false);
    }
    if (!password) {
      setErrorPassword(true);
    } else {
      setErrorPassword(false);
    }
    if (!email || !password) {
      return;
    }
    try {
      setLoading(true);
      await authProvider.login({ email, password });
      setLoading(false);
      window.location.href = '/';
    } catch (e) {
      notify(t('login.invalidEmailOrPassword'), 'warning');
      setLoading(false);
    }
  };

  const moveTo = useMoveTo();
  //If already logged in, redirect to session(top)
  if (supabase.auth.session()) {
    moveTo('session');
  }

  return (
    <div className={classes.wrapper}>
      <img src={logo} alt="Optipass" className={classes.logo} />
      <form noValidate autoComplete="off">
        <TextField
          id="email"
          label={t('login.Email')}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          error={errorEmail}
          helperText={errorEmail ? t('login.EmailRequired') : ''}
        />
        <TextField
          type="password"
          id="password"
          label={t('common.password')}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          error={errorPassword}
          helperText={errorPassword ? t('login.PasswordRequired') : ''}
        />
        <div className={classes.buttonGroup}>
          <div className={classes.buttonWithSpinner}>
            <Button
              className={classes.signInButton}
              type="button"
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={login}
            >
              <Typography variant="body2">{t('login.signin')}</Typography>
            </Button>
            {loading && <Loading />}
          </div>

          {/* <SSOLoginForm /> */}

          <Typography>
            <Link href="/forgot">{t('login.forgot')}</Link>
          </Typography>
        </div>
      </form>
    </div>
  );
};

export default Login;
