import { authProvider } from './authProvider';
import { definitions } from './types';

export default class Permission {
  private _users: number;
  private _tenants: number;
  private _roles: number;
  private _jobs: number;
  private _contactReasons: number;
  private _isAdmin: boolean;

  private readonly MAX_PERMISSION_VAL = 4;

  private readonly permissionList: (
    | 'none'
    | 'read'
    | 'update'
    | 'create'
    | 'delete'
  )[] = ['none', 'read', 'update', 'create', 'delete'];

  constructor(param: definitions['user_permission']) {
    if (param.isAdmin) {
      this._isAdmin = true;
      this._users = this.MAX_PERMISSION_VAL;
      this._tenants = this.MAX_PERMISSION_VAL;
      this._roles = this.MAX_PERMISSION_VAL;
      this._jobs = this.MAX_PERMISSION_VAL;
      this._contactReasons = this.MAX_PERMISSION_VAL;
    } else {
      this._isAdmin = false;
      const { users, tenants, roles, jobs, contactReasons } = param;
      this._users = this.parsePermissionStr(users);
      this._tenants = this.parsePermissionStr(tenants);
      this._roles = this.parsePermissionStr(roles);
      this._jobs = this.parsePermissionStr(jobs);
      this._contactReasons = this.parsePermissionStr(contactReasons);
    }
  }

  hasRead(componentName: string) {
    return this.hasPermission(componentName, 1);
  }
  hasEdit(componentName: string) {
    return this.hasPermission(componentName, 2);
  }
  hasCreate(componentName: string) {
    return this.hasPermission(componentName, 3);
  }
  hasDelete(componentName: string) {
    return this.hasPermission(componentName, 4);
  }

  getNameOf(component: string) {
    switch (component) {
      case 'users':
        return this.getName(this._users);
      case 'tenants':
        return this.getName(this._tenants);
      case 'roles':
        return this.getName(this._roles);
      case 'jobs':
        return this.getName(this._jobs);
      case 'contactReasons':
        return this.getName(this._contactReasons);
      default:
        return '';
    }
  }

  getName(permission: number) {
    return this.permissionList[permission];
  }

  getList() {
    return this.permissionList.map((p: string, idx: number) => {
      return { id: idx, name: p };
    });
  }

  isGuest() {
    return (
      this._users === 0 &&
      this._jobs === 0 &&
      this._roles === 0 &&
      this._tenants === 0 &&
      this._contactReasons === 0
    );
  }
  isAdmin() {
    return this._isAdmin;
  }

  static createGuest() {
    return new Permission({
      users: '0',
      tenants: '0',
      roles: '0',
      jobs: '0',
      contactReasons: '0',
    });
  }

  static async load() {
    return await authProvider.getPermissions('');
  }

  private hasPermission(componentName: string, permission: number) {
    let objComponent: number = 0;
    switch (componentName) {
      case 'users':
        objComponent = this._users;
        break;
      case 'tenants':
        objComponent = this._tenants;
        break;
      case 'roles':
        objComponent = this._roles;
        break;
      case 'jobs':
        objComponent = this._jobs;
        break;
      case 'contactReasons':
        objComponent = this._contactReasons;
        break;
      default:
        // FIXME: to false
        return true;
    }

    return objComponent >= permission;
  }

  private parsePermissionStr(str: string | undefined) {
    return parseInt(str || '0');
  }
}
