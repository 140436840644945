import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import Button from '../../Components/Atoms/Button';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import { Typography } from '@material-ui/core';
import EditFacetModal from '../../Components/Organisms/Analytics/EditFacetModal';
import EditGadgetModal from '../../Components/Organisms/Analytics/EditGadgetModal';
import DashboardAdminHeader from '../../Components/Organisms/Analytics/DashboardAdminHeader';
import useMoveTo from '../../Components/Hooks/Moveto';
import BackgroundTheme from '../../Styles/BackgroundTheme';
import useTranslation from '../../Components/Hooks/Translate';
import Loading from '../../Components/Atoms/Loading';
import useSearchUrlParam from '../../Components/Hooks/SearchUrlParam';
import { definitions } from '../../Utils/types';
import { supabase } from '../../Utils/supabase';
import { useHandleError } from '../../Components/Hooks/HandleError';
import { useNotify } from '../../Components/Hooks/Notify';
import theme from '../../Styles/Theme';
import useConfirm from '../../Components/Hooks/Confirm';
import useGetFacetProfile from '../../Components/Hooks/GetFacetProfile';
import useGetGadgetProfile from '../../Components/Hooks/GetGadgetProfile';
import { gadgetsLimit } from '../../Utils/constants';

type Facet = definitions['dashboard_facets']['facet'];
type Gadget = definitions['dashboard_gadgets']['gadget'];
type Dashboard = definitions['dashboards'];
type DashboardGadgets = definitions['dashboard_gadgets'];
type DashboardFacets = definitions['dashboard_facets'];
type GadgetWithLayout = {
  gadget: Gadget;
  x: number;
  y: number;
  width: number;
  height: number;
};

const useStyles = makeStyles({
  dashboardContents: {},
  listItemWrapper: {
    width: '80%',
    marginLeft: '8%',
    marginRight: '8%',
  },
  listItem: {
    width: '100%',
    margin: '20px 0',
    backgroundColor: '#fff',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#eee',
    },
  },
  facetlist: {
    width: '30%',
    float: 'left',
    paddingTop: '10px',
    backgroundColor: BackgroundTheme.palette.secondary.main,
    height: 'calc(100vh - 150px)',
    overflow: 'overlay',
  },
  gadgetlist: {
    width: '65%',
    height: 'calc(100vh - 160px)',
    float: 'right',
    paddingTop: '10px',
    overflowY: 'auto',
  },
  facetItem: {
    boxShadow: '#999 1px 2px 5px',
  },
  gadgetItem: {
    border: '#eee solid 1px',
    boxShadow: '#ddd 1px 3px 5px',
  },

  listIcon: {
    right: '5%',
  },
  iconButton: {
    backgroundColor: 'rgba(250,250,250,0.87)',
    borderRadius: '5px',
    margin: '30px 0',
    left: '8%',
    '&:hover': {
      backgroundColor: '#eee',
    },
  },
});

const dbAccessor = {
  getDashboardOf: async (id: string) => {
    return await supabase
      .from<Dashboard>('dashboards')
      .select()
      .eq('id', id)
      .single();
  },
  getDashboardFacets: async (dashboardId: string) => {
    return await supabase
      .from<DashboardFacets>('dashboard_facets')
      .select()
      .eq('dashboardId', dashboardId);
  },
  getDashboardGadgets: async (dashboardId: string) => {
    return await supabase
      .from<DashboardGadgets>('dashboard_gadgets')
      .select()
      .eq('dashboardId', dashboardId);
  },
  deleteDashboard: async (dashboardId: string) => {
    return await supabase
      .from<Dashboard>('dashboards')
      .delete()
      .eq('id', dashboardId);
  },
  deleteDashboardGadgets: async (dashboardId: string) => {
    return await supabase
      .from<DashboardGadgets>('dashboard_gadgets')
      .delete()
      .eq('dashboardId', dashboardId);
  },
  deleteDashboardFacets: async (dashboardId: string) => {
    return await supabase
      .from<DashboardFacets>('dashboard_facets')
      .delete()
      .eq('dashboardId', dashboardId);
  },
  insertDashboardFacets: async (dashboardId: string, facets: Facet[]) => {
    return await supabase.from<DashboardFacets>('dashboard_facets').insert(
      facets.map((f) => {
        return {
          facet: f,
          dashboardId,
        };
      })
    );
  },
  insertDashboardGadgets: async (gadgetsWithLayout: GadgetWithLayout[]) => {
    return await supabase
      .from<DashboardGadgets>('dashboard_gadgets')
      .insert(gadgetsWithLayout);
  },
};

const DashboardEdit = () => {
  const classes = useStyles();
  const handleError = useHandleError();
  const moveTo = useMoveTo();
  const notify = useNotify();
  const searchUrlParam = useSearchUrlParam();
  const confirm = useConfirm();
  const t = useTranslation('Analytics');

  const [dashboardId, setDashbordId] = React.useState('');
  const [facets, setFacets] = React.useState<Facet[] | undefined>();
  const [gadgets, setGadgets] = React.useState<Gadget[] | undefined>();
  const [title, setTitle] = React.useState<string>('');
  const [loading, setLoading] = React.useState(false);

  const readDashboardIdFromUrlParam = () => {
    const id = searchUrlParam('did');
    if (!id) {
      moveTo('dashboard-admin');
      return '';
    } else {
      setDashbordId(id);
      return id;
    }
  };

  const displayGadgetAndFacetsOf = async (dashboardId: string) => {
    const { data: fcData, error: fcError } =
      await dbAccessor.getDashboardFacets(dashboardId);
    const { data: gdData, error: gdError } =
      await dbAccessor.getDashboardGadgets(dashboardId);

    if (fcError || gdError) {
      handleError('PostgresError', {
        message: fcError?.message || gdError?.message,
      });
      return Promise.reject();
    }

    const dispFcData = fcData?.map((f) => f.facet) || [];
    const dispGdData = gdData?.map((g) => g.gadget) || [];

    setFacets(dispFcData);
    setGadgets(dispGdData);
  };

  const displayDashboardTitleOf = async (dashboardId: string) => {
    const { data, error } = await dbAccessor.getDashboardOf(dashboardId);

    if (!error && data) {
      setTitle(data.name);
    } else if (error && data) {
      handleError('PostgresError', {
        message: error.message,
      });
      return Promise.reject();
    } else if (error && !data) {
      // FIXME: No Data Error Code
      moveTo('dashboard-admin');
      return Promise.reject();
    }
  };

  const displayAllDashboardData = async (dashboardId: string) => {
    setLoading(true);
    await displayDashboardTitleOf(dashboardId);
    await displayGadgetAndFacetsOf(dashboardId);
    setLoading(false);
  };

  const deleteDashboard = async () => {
    // 削除確認ダイアログを表示します。
    if (
      (await confirm(
        t('confirm.deleteMessage'),
        t('confirm.deleteApprove')
      )) === false
    ) {
      return;
    }

    setLoading(true);

    const { error: dErrorr } = await dbAccessor.deleteDashboard(dashboardId);
    if (dErrorr) {
      handleError('PostgresError', { message: dErrorr.message });
      setLoading(false);
      return Promise.reject(dErrorr);
    }

    try {
      await deleteAllDashboardData();
    } catch (e) {
      return Promise.reject(e);
    }

    setLoading(false);
    moveTo('dashboard-admin');
    notify(t('notify.delete'), 'success');
  };

  const deleteAllDashboardData = async () => {
    setLoading(true);

    const { data: fcData, error: fcError } =
      await dbAccessor.deleteDashboardFacets(dashboardId);
    if (fcError) {
      handleError('PostgresError', { message: fcError.message });
      setLoading(false);
      return Promise.reject(fcError);
    }

    const { data: gdData, error: gdError } =
      await dbAccessor.deleteDashboardGadgets(dashboardId);
    if (gdError) {
      handleError('PostgresError', { message: gdError.message });
      setLoading(false);
      return Promise.reject(gdError);
    }

    setLoading(false);
    return Promise.resolve({ deletedFacets: fcData, deletedGadgets: gdData });
  };

  const insertAllDashboardData = async () => {
    setLoading(true);

    const { deletedGadgets } = await deleteAllDashboardData();

    const { data: fcData, error: fcError } =
      await dbAccessor.insertDashboardFacets(dashboardId, facets || []);
    if (!fcData || fcError) {
      handleError('PostgresError', { message: fcError?.message });
      setLoading(false);
      return Promise.reject();
    }

    const gadgetsWithLayout = initializeGadgetLayouts(
      dashboardId,
      deletedGadgets || []
    );

    const { data: gdData, error: gdError } =
      await dbAccessor.insertDashboardGadgets(gadgetsWithLayout);
    if (!gdData || gdError) {
      handleError('PostgresError', { message: gdError?.message });
      setLoading(false);
      return Promise.reject();
    }

    setLoading(false);
    notify(t('notify.save'), 'success');
    moveTo('dashboard-admin');
  };

  const initializeGadgetLayouts = (
    dashboardId: string,
    defaultGadgets: DashboardGadgets[]
  ) => {
    if (!gadgets) return [];
    return gadgets?.map<GadgetWithLayout>((g, idx) => {
      const stillExistGadget = defaultGadgets.find((dg) => dg.gadget === g);
      return stillExistGadget
        ? {
            dashboardId,
            gadget: g,
            width: stillExistGadget.width,
            height: stillExistGadget.height,
            x: stillExistGadget.x,
            y: stillExistGadget.y,
          }
        : {
            dashboardId,
            gadget: g,
            width: 12,
            height: 3,
            x: 0,
            y: 0,
          };
    });
  };

  React.useEffect(() => {
    const dashboardId = readDashboardIdFromUrlParam();
    displayAllDashboardData(dashboardId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveButton = () => {
    return (
      <>
        <Button
          variant="contained"
          endIcon={<SaveIcon />}
          onClick={insertAllDashboardData}
          style={{
            marginRight: '10px',
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
          }}
        >
          <Typography>{t('dashboardedit.save')}</Typography>
        </Button>
        <Button
          variant="contained"
          endIcon={<DeleteIcon />}
          onClick={deleteDashboard}
          style={{
            marginRight: '10px',
            backgroundColor: theme.palette.secondary.main,
            color: '#fff',
          }}
        >
          <Typography>{t('dashboardedit.delete')}</Typography>
        </Button>
        <Button
          variant="contained"
          color="default"
          onClick={() => {
            moveTo('dashboard-admin');
          }}
        >
          <Typography variant="body1">{t('dashboardedit.cancel')}</Typography>
        </Button>
      </>
    );
  };

  const FacetList = () => {
    const classes = useStyles();
    const getFacetProfile = useGetFacetProfile();

    const [openFacetEdit, setOpenFacetEdit] = React.useState(false);

    const addNewFacets = (flist: Facet[]) => {
      setOpenFacetEdit(false);
      setFacets(flist?.slice());
    };

    const removeFacet = (idx: number) => {
      facets?.splice(idx, 1);
      setFacets(facets?.slice());
    };

    const openFacet = () => {
      return setOpenFacetEdit(true);
    };

    const createRow = (f: Facet, idx: number) => {
      return (
        <ListItem
          key={f}
          button
          className={`${classes.listItem} ${classes.facetItem}`}
        >
          <ListItemText primary={getFacetProfile(f).name} />
          <ListItemSecondaryAction className={classes.listIcon}>
            <IconButton
              edge="end"
              aria-label="close"
              onClick={() => removeFacet(idx)}
            >
              <CloseIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      );
    };

    let rows: JSX.Element[] = [];
    if (facets) {
      if (facets.length >= 1) {
        rows = facets.map(createRow);
      } else {
        rows = [<Typography>{t('dashboardedit.nofacet')}</Typography>];
      }
    }

    return (
      <>
        <List component="nav">
          <div className={classes.listItemWrapper}>{rows}</div>
          <IconButton
            onClick={openFacet}
            className={`${classes.iconButton} ${classes.facetItem}`}
          >
            <AddIcon />
          </IconButton>
          <EditFacetModal
            open={openFacetEdit}
            onCancel={() => setOpenFacetEdit(false)}
            onAdd={addNewFacets}
            defaultSelected={facets || []}
          />
        </List>
      </>
    );
  };

  const GadgetList = () => {
    const classes = useStyles();
    const getGadgetProfile = useGetGadgetProfile();

    const [openGadgetEdit, setOpenGadgetEdit] = React.useState(false);
    const openGadget = () => {
      if (gadgets && gadgets.length >= gadgetsLimit) {
        notify(
          `${t('dashboardedit.limit1')}${gadgetsLimit}${t(
            'dashboardedit.limit2'
          )}`,
          'error'
        );
        return;
      }
      return setOpenGadgetEdit(true);
    };

    const addNewGadget = (g: Gadget) => {
      gadgets?.push(g);
      setOpenGadgetEdit(false);
      setGadgets(gadgets?.slice());
    };

    const removeGadget = (idx: number) => {
      gadgets?.splice(idx, 1);
      setGadgets(gadgets?.slice());
    };

    const createRow = (g: Gadget, idx: number) => {
      return (
        <ListItem
          button
          className={`${classes.listItem} ${classes.gadgetItem}`}
          key={g}
        >
          <ListItemText primary={getGadgetProfile(g).name} />
          <ListItemSecondaryAction className={classes.listIcon}>
            <IconButton
              edge="end"
              aria-label="close"
              onClick={() => removeGadget(idx)}
            >
              <CloseIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      );
    };

    let rows: JSX.Element[] = [];
    if (gadgets) {
      if (gadgets.length >= 1) {
        rows = gadgets.map(createRow);
      } else {
        rows = [<Typography>{t('dashboardedit.nogadget')}</Typography>];
      }
    }

    return (
      <>
        {loading && <Loading />}
        <List component="nav">
          <div className={classes.listItemWrapper}>{rows}</div>
          <IconButton
            onClick={openGadget}
            className={`${classes.iconButton} ${classes.facetItem}`}
          >
            <AddIcon />
          </IconButton>
          <EditGadgetModal
            alreadyExistings={gadgets || []}
            open={openGadgetEdit}
            onCancel={() => setOpenGadgetEdit(false)}
            onAdd={addNewGadget}
          />
        </List>
      </>
    );
  };

  return (
    <>
      <DashboardAdminHeader title={title}>{saveButton()}</DashboardAdminHeader>
      <div className={classes.dashboardContents}>
        <div className={classes.facetlist}>
          <FacetList />
        </div>
        <div className={classes.gadgetlist}>
          <GadgetList />
        </div>
      </div>
    </>
  );
};
export default DashboardEdit;
