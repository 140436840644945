import gadgetMaster from '../../Locales/en/Gadget.json';
import facetMaster from '../../Locales/en/Facet.json';
import { definitions } from '../../Utils/types';

type Gadget = definitions['dashboard_gadgets']['gadget'];

type GadgetType =
  | 'Sankey'
  | 'Wordcrowd'
  | 'Heatmap'
  | 'TimelineChart'
  | 'CodeGraph'
  | 'BipartiteGraph'
  | 'BarChart'
  | 'Table'
  | 'Number'
  | 'PieChart'
  | 'Sanburst'
  | 'Area';
const GadgetTypes: GadgetType[] = [
  'Sankey',
  'Wordcrowd',
  'Heatmap',
  'TimelineChart',
  'CodeGraph',
  'BipartiteGraph',
  'BarChart',
  'Table',
  'Number',
  'PieChart',
  'Sanburst',
  'Area',
];

export type GadgetCategory = 'chart' | 'table' | 'kpi' | 'pie';
const GadgetCategories: GadgetCategory[] = ['chart', 'table', 'kpi', 'pie'];

export const useGetGadgets = () => {
  return (category: GadgetCategory) => {
    const gadgetListStr = Object.keys(gadgetMaster) as Gadget[];

    return gadgetListStr.filter((g) => {
      if (g.endsWith('Number')) {
        return category === 'kpi';
      } else if (g.endsWith('PieChart')) {
        return category === 'pie';
      } else if (g.endsWith('Table')) {
        return category === 'table';
      } else {
        return category === 'chart';
      }
    });
  };
};
export const useGetAllGadgetCategory = () => {
  return () => {
    return GadgetCategories.slice();
  };
};
export const useGetGadgetType = () => {
  return (gadget: Gadget) => GadgetTypes.find((gt) => gadget.endsWith(gt));
};

type Facet = definitions['dashboard_facets']['facet'];
export const useGetAllFacets = () => {
  return () => {
    return Object.keys(facetMaster) as Facet[];
  };
};
