import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  BulkDeleteButton,
  BulkDeleteButtonProps,
  CreateButton,
  ExportButton,
  List,
  Pagination,
  TopToolbar,
} from 'ra-ui-materialui';
import BackgroundTheme from '../../../Styles/BackgroundTheme';
import theme from '../../../Styles/Theme';
import { Identifier } from 'ra-core';
import useTranslation from '../../Hooks/Translate';
import { OperatorInfoContext } from '../../../WithContext';

const styles = makeStyles({
  list: {
    margin: '20px',
    '& th': {
      backgroundColor: BackgroundTheme.palette.primary.main,
      color: '#fff',
      fontWeight: 'bold',
      padding: '1em 0.5em',
      minWidth: '5em',
    },
    '& tr': {
      height: '3.5em',
    },
    '& .toolbar': {
      backgroundColor: '#999',
    },
  },
  pagination: {
    '& div': {
      backgroundColor: '#ddd',
      minHeight: '30px',
      lineHeight: '30px',
    },
  },
  button: {
    color: '#fff',
    padding: '10px',
    margin: '0 5px',
  },
  mainButton: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  secondaryButton: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
});

interface SettingListProps {
  children: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  bulkDelete?: (...data: any) => void;
  permissions?: any;
  create?: boolean;
  export?: boolean;
  confirmtitle: string;
}

const SettingList = (props: SettingListProps) => {
  const t = useTranslation('Settings');
  const classes = styles();
  const PostListActions = () => (
    <TopToolbar>
      {props.create && (
        <CreateButton className={`${classes.mainButton} ${classes.button}`} />
      )}
      <ExportButton
        className={`${classes.secondaryButton} ${classes.button}`}
      />
    </TopToolbar>
  );

  const { bulkDelete, ...propsForList } = props;
  delete propsForList.create;
  delete propsForList.export;

  return (
    <List
      {...propsForList}
      actions={<PostListActions />}
      pagination={<Pagination className={classes.pagination} />}
      bulkActionButtons={
        propsForList.permissions.hasDelete('users') ? (
          props.bulkDelete ? (
            <CustomBulkActionButtons
              onClick={props.bulkDelete}
              confirmTitle={props.confirmtitle}
            />
          ) : (
            <BulkDeleteButton
              undoable={false}
              confirmTitle={props.confirmtitle}
              confirmContent={t('common.confirm_delete')}
            />
          )
        ) : (
          false
        )
      }
      className={classes.list}
    >
      {propsForList.children}
    </List>
  );
};

const CustomBulkActionButtons = (props: any) => (
  <React.Fragment>
    <CustomBulkDeleteButton {...props} />
  </React.Fragment>
);

const CustomBulkDeleteButton = (
  props: BulkDeleteButtonProps & {
    onClick: (selectedIds?: Identifier[]) => void;
    confirmTitle: string;
  }
) => {
  const t = useTranslation('Settings');
  const operatorInfo = React.useContext(OperatorInfoContext);
  const check =
    operatorInfo &&
    props.selectedIds &&
    props.selectedIds.includes(operatorInfo.id);
  return (
    <BulkDeleteButton
      {...props}
      confirmTitle={props.confirmTitle}
      confirmContent={t('common.confirm_delete')}
      disabled={check}
      undoable={false}
      onClick={(ev) => props.onClick(props.selectedIds)}
    />
  );
};

export default SettingList;
