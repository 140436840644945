import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DashboardTable from '../../Components/Molecules/Analytics/DashboardTable';
import AddDashboardModal from '../../Components/Organisms/Analytics/AddDashboardModal';
import Button from '../../Components/Atoms/Button';
import { Typography } from '@material-ui/core';
import DashboardAdminHeader from '../../Components/Organisms/Analytics/DashboardAdminHeader';
import BackgroundTheme from '../../Styles/BackgroundTheme';
import useTranslation from '../../Components/Hooks/Translate';
import { useTheme } from '@material-ui/core';
import { definitions } from '../../Utils/types';
import { supabase } from '../../Utils/supabase';
import { useHandleError } from '../../Components/Hooks/HandleError';
import Loading from '../../Components/Atoms/Loading';
import { useNotify } from '../../Components/Hooks/Notify';
import { OperatorInfoContext } from '../../WithContext';

type Job = definitions['jobs'];
type Dashboard = definitions['dashboards'];
type JobDashboard = definitions['jobs_dashboards'];
export type DashboardAddProfile = {
  name: string;
  jobIds: string[];
  isDefault: boolean;
};
export type DashboardEditProfile = DashboardAddProfile & {
  id: string;
};

const useStyles = makeStyles({
  table: {
    margin: '50px auto',
    width: '80%',
  },
});

const dbAccessor = {
  getDashboards: async (userId: string) => {
    return await supabase
      .from<Dashboard>('dashboards')
      .select()
      .match({ userId })
      .order('id');
  },
  getJobs: async () => {
    return await supabase.from<Job>('jobs').select();
  },
  insertDashboard: async (data: DashboardAddProfile, userId: String) => {
    if (data.isDefault) {
      const { error: upError } = await supabase
        .from<Dashboard>('dashboards')
        .update({
          default: false,
        })
        .match({ userId });

      if (upError && (upError as any).length !== 0) {
        return Promise.resolve({ data: null, error: upError });
      }
    }
    return await supabase
      .from<Dashboard>('dashboards')
      .insert([{ name: data.name, default: data.isDefault }]);
  },
  insertJobDashboard: async (jobIds: string[], dashboardId: string) => {
    return await supabase.from<JobDashboard>('jobs_dashboards').insert(
      jobIds.map((jid) => {
        return {
          dashboardId,
          jobId: jid,
        };
      })
    );
  },
  updateDashboard: async (data: DashboardEditProfile, userId: String) => {
    const { name, isDefault, id } = data;

    if (isDefault) {
      const { data: upData, error } = await supabase
        .from<Dashboard>('dashboards')
        .update({
          default: false,
        })
        .match({ userId });

      if (!upData || error) {
        return Promise.resolve({ data: null, error });
      }
    }

    return await supabase
      .from<Dashboard>('dashboards')
      .update({ name, default: isDefault })
      .match({ id });
  },
  updateJobDashboard: async (jobIds: string[], dashboardId: string) => {
    const { data, error } = await supabase
      .from<JobDashboard>('jobs_dashboards')
      .delete()
      .match({ dashboardId });

    if (!data || error) {
      return Promise.resolve({ data, error });
    }

    if (jobIds.length <= 0) {
      return Promise.resolve({ data: [], error: null });
    } else {
      return await supabase.from<JobDashboard>('jobs_dashboards').insert(
        jobIds.map((jid) => {
          return {
            dashboardId,
            jobId: jid,
          };
        })
      );
    }
  },
};

const DashboardAdmin = () => {
  const classes = useStyles();
  const theme = useTheme();
  const notify = useNotify();
  const handleError = useHandleError();
  const operatorInfo = useContext(OperatorInfoContext);
  const t = useTranslation('Analytics');

  const [dashboards, setDashboards] = React.useState<Dashboard[]>([]);
  const [openAddModal, setOpenAddModal] = React.useState(false);
  const [objEditDashboard, setObjEditDashboard] = React.useState<
    Dashboard | undefined
  >();
  const [objEditJobIds, setObjEditJobIds] = React.useState<
    string[] | undefined
  >();
  const [jobs, setJobs] = React.useState<Job[] | undefined>();
  const [loading, setLoading] = React.useState(false);

  const displayDashboards = async () => {
    const { data, error } = await dbAccessor.getDashboards(
      operatorInfo?.id || ''
    );

    if (!error) {
      setDashboards(data || []);
    } else {
      handleError('PostgresError', { message: error.message });
      return Promise.reject();
    }
  };

  const fetchJobs = async () => {
    const { data, error } = await dbAccessor.getJobs();

    if (!error) {
      setJobs(data || []);
    } else {
      handleError('PostgresError', { message: error.message });
      return Promise.reject();
    }
  };

  React.useEffect(() => {
    displayDashboards();
    fetchJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const insertNewDashboard = async (data: DashboardAddProfile) => {
    setOpenAddModal(false);
    setLoading(true);

    const { data: dbData, error: dbError } = await dbAccessor.insertDashboard(
      data,
      operatorInfo?.id || ''
    );
    if (!dbData || dbData.length !== 1 || dbError) {
      handleError('PostgresError', { message: dbError?.message });
      setLoading(false);
      return Promise.reject();
    }

    if (data.jobIds.length >= 1) {
      const { data: jdData, error: jdError } =
        await dbAccessor.insertJobDashboard(data.jobIds, dbData[0].id);
      if (!jdData || jdData.length === 0 || jdError) {
        handleError('PostgresError', { message: jdError?.message });
        setLoading(false);
        return Promise.reject();
      }
    }

    notify(t('notify.add'), 'success');

    await displayDashboards();
    setLoading(false);
  };

  const updateDashboard = async (data: DashboardEditProfile) => {
    setOpenAddModal(false);
    setLoading(true);

    const { data: dbData, error: dbError } = await dbAccessor.updateDashboard(
      data,
      operatorInfo?.id || ''
    );
    if (!dbData || dbData.length !== 1 || dbError) {
      handleError('PostgresError', { message: dbError?.message });
      setLoading(false);
      return Promise.reject();
    }

    const { data: jdData, error: jdError } =
      await dbAccessor.updateJobDashboard(data.jobIds, dbData[0].id);
    if (!jdData || jdError) {
      handleError('PostgresError', { message: jdError?.message });
      setLoading(false);
      return Promise.reject();
    }

    notify(t('notify.edit'), 'success');

    await displayDashboards();
    setLoading(false);
  };

  return (
    <>
      {loading && <Loading />}
      <DashboardAdminHeader title={t('dashboardadmin.title')}>
        <Button
          variant="contained"
          disabled={!jobs}
          style={{
            backgroundColor: BackgroundTheme.palette.secondary.main,
            color: theme.palette.primary.main,
          }}
          onClick={() => {
            setOpenAddModal(true);
            setObjEditDashboard(undefined);
            setObjEditJobIds(undefined);
          }}
        >
          <Typography variant="body1" style={{ fontWeight: 'bold' }}>
            {t('dashboardadmin.add')}
          </Typography>
        </Button>
        {openAddModal && (
          <AddDashboardModal
            open={openAddModal}
            defaultDashboard={objEditDashboard}
            jobs={jobs || []}
            defaultJobIds={objEditJobIds}
            onClickCancel={() => setOpenAddModal(false)}
            onClickSaveForNew={insertNewDashboard}
            onClickSaveForEdit={updateDashboard}
          />
        )}
      </DashboardAdminHeader>
      <div id="content">
        <div id="table" className={classes.table}>
          <DashboardTable
            dashboards={dashboards}
            jobCondidates={jobs || []}
            onRequestEdit={(dashboard: Dashboard, jobIds: string[]) => {
              setOpenAddModal(true);
              setObjEditDashboard(dashboard);
              setObjEditJobIds(jobIds);
            }}
          ></DashboardTable>
        </div>
      </div>
    </>
  );
};
export default DashboardAdmin;
