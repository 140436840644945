import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

// CSS定義をここに書く
const styles = makeStyles({
  wrapper: {},
});

// コンポーネント名はURLのページ名と同じにする
const Account = () => {
  const classes = styles();

  return <div className={classes.wrapper}></div>;
};

export default Account;
