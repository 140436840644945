import * as React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import useTranslation from '../../Hooks/Translate';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    setContent: {
      width: '90%',
      margin: '25px 5% 0px 5%',
    },
  })
);

interface CheckAsDefaultContentProps {
  onChange: (event: any) => void;
  checked?: boolean;
}

const CheckAsDefaultContent = (props: CheckAsDefaultContentProps) => {
  const classes = useStyles();
  const t = useTranslation('Analytics');

  return (
    <div className={classes.setContent}>
      <FormControlLabel
        control={
          <Checkbox
            onChange={props.onChange}
            color="primary"
            checked={props.checked}
          />
        }
        label={t('dashboardadd.default')}
      />
    </div>
  );
};

export default CheckAsDefaultContent;
