/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import useTranslation from '../Hooks/Translate';

interface SearchBarProps {
  data: string[];
  getResults: (result: string[]) => void;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: '10px 3%',
      width: 'max-content',
    },
    input: { padding: '5px' },
    iconbutton: {
      marginLeft: '5px',
      verticalAlign: 'text-bottom',
    },
  })
);

const SearchBar = (props: SearchBarProps) => {
  const classes = useStyle();
  const [searchTerm, setSearchTerm] = React.useState('');
  const t = useTranslation('Global');

  const handleChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSearchTerm(event.target.value);
  };

  React.useEffect(() => {
    if (searchTerm === '') {
      props.getResults(props.data);
    } else {
      const results = props.data.filter((item) =>
        item.toLowerCase().includes(searchTerm)
      );
      props.getResults(results);
    }
  }, [searchTerm]);

  return (
    <Paper component="form" className={classes.paper}>
      <SearchIcon className={classes.iconbutton} />
      <InputBase
        className={classes.input}
        placeholder={t('searchbar.search')}
        inputProps={{ 'aria-label': 'search' }}
        onChange={handleChange}
      />
    </Paper>
  );
};

export default SearchBar;
