import { styled } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import ActionDelete from '@material-ui/icons/Delete';
import {
  DeleteButton,
  SaveButton,
  Toolbar,
  Button,
  useRedirect,
  useDelete,
  CRUD_DELETE,
  Mutation,
} from 'react-admin';
import Permission from '../../Utils/Permission';
import useTranslation from './Translate';
import useConfirm from './Confirm';
import { useNotify } from './Notify';

const StyledToolbar = styled(Toolbar)({
  //Toolbarに、保存や削除のボタンを左右に振り分けるスタイル定義
  display: 'flex',
  justifyContent: 'space-between',
});

// カスタム削除ボタンに、RAの削除ボタンスタイルを充てるHook
const useCustomDeleteButtonStyles = makeStyles(
  (theme: any) => ({
    deleteButton: {
      color: theme.palette.error.main,
      '&:hover': {
        backgroundColor: alpha(theme.palette.error.main, 0.12),
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
  }),
  { name: 'RaDeleteWithConfirmButton' }
);

// 削除後のリスト遷移後エラーに対応するための、カスタムツールバーコンポーネント
const ServiceEditToolbar = (props: any) => {
  const { saveButtonForceEnabled, ...toolbarProps } = props;
  const t = useTranslation('Settings');
  const notify = useNotify();
  const redirect = useRedirect();
  const [deleteOne, { loading }] = useDelete(
    props['resource'],
    props['record'].id,
    props['record'],
    {
      action: CRUD_DELETE,
      mutationMode: 'pessimistic',
      onSuccess: (response) => {
        notify(t('customToolBar.deleted'), 'info');
        redirect(props['basePath']);
      },
      onFailure: (error) => {
        notify(error.message, 'warning');
      },
    }
  );

  const confirmtitle = `${props.options.label}: ${props.record.name}`;

  return (
    <StyledToolbar {...toolbarProps}>
      <SaveButton
        disabled={
          (props as any).pristine && props.saveButtonForceEnabled !== true
        }
      />
      <CustomDeleteButton
        owner={props.owner}
        onClickDelete={deleteOne}
        confirmTitle={confirmtitle}
        loading={loading}
      />
    </StyledToolbar>
  );
};

const CustomToolBar = (props: {
  permissions: Permission;
  component: string;
  /** 強制的に保存ボタンを有効化するか */
  saveButtonForceEnabled?: boolean;
  /** 削除処理を上書きする場合のコールバック関数 */
  onClickDelete?: () => void;
  /** 削除確認ダイアログのタイトル */
  confirmtitle?: string;
  record?: any;
  options?: any;
  owner?: boolean;
}) => {
  const { permissions, component, saveButtonForceEnabled, ...toolbarProps } =
    props;
  delete toolbarProps.onClickDelete;
  const confirmtitle = `${props.options.label}: ${props.record.name}`;

  if (!props.permissions) {
    return <Toolbar {...toolbarProps}>{''}</Toolbar>;
  } else if (props.permissions.hasDelete(props.component)) {
    return props.onClickDelete ? (
      <StyledToolbar {...toolbarProps}>
        <SaveButton
          disabled={(props as any).pristine && saveButtonForceEnabled !== true}
        />
        <CustomDeleteButton
          onClickDelete={props.onClickDelete}
          owner={props.owner}
          confirmTitle={confirmtitle}
          loading={false}
        />
      </StyledToolbar>
    ) : (
      <ServiceEditToolbar
        {...toolbarProps}
        saveButtonForceEnabled={saveButtonForceEnabled}
      />
    );
  } else if (props.permissions.hasEdit(props.component)) {
    return (
      <StyledToolbar {...toolbarProps}>
        <SaveButton
          disabled={(props as any).pristine && saveButtonForceEnabled !== true}
        />
      </StyledToolbar>
    );
  } else {
    return <Toolbar>{''}</Toolbar>;
  }
};

interface CustomDeleteButtonProps {
  owner?: boolean;
  onClickDelete: () => void;
  confirmTitle: string;
  loading: boolean;
}
const CustomDeleteButton = (props: CustomDeleteButtonProps) => {
  const customDeleteBtnClasses = useCustomDeleteButtonStyles();
  const notify = useNotify();
  const t = useTranslation('Settings');
  const confirm = useConfirm();

  const handleDelete = props.owner
    ? () => {
        notify(t('users.includesOwner'), 'error');
      }
    : async () => {
        if (
          await confirm(
            t('customToolBar.deleteMsg'),
            t('customToolBar.confirm'),
            props.confirmTitle
          )
        )
          props.onClickDelete();
      };

  return (
    <Button
      label={t('customToolBar.delete')}
      className={customDeleteBtnClasses.deleteButton}
      key="button"
      onClick={handleDelete}
      disabled={props.loading}
    >
      <ActionDelete />
    </Button>
  );
};

export default CustomToolBar;
