import * as React from 'react';
import ModalBase from '../../Molecules/Global/ModalBase';
import Checkbox from '../../Atoms/Checkbox';
import Accordion from '../../Atoms/Accordion';
import useTranslation from '../../Hooks/Translate';
import { AgentContext, OperatorInfoContext } from '../../../WithContext';
import { useHandleError } from '../../Hooks/HandleError';
import { useNotify } from '../../Hooks/Notify';
import TextBox from '../../Atoms/TextBox';
import { Agent } from '@optipass/sdk';
import OperatorInfo from '../../../Utils/OperatorInfo';
import { definitions } from '../../../Utils/types';
import { supabase } from '../../../Utils/supabase';
import CheckBoxGroup from '../../Molecules/Global/CheckboxGroup';

type ContactReason = definitions['contact_reasons'] & {
  category: definitions['contact_reason_categories'];
};

const dbAccessor = {
  getContactReasons: async (jobId: string) => {
    return await supabase
      .from<ContactReason>('contact_reasons')
      .select(
        `*,
        category:contact_reason_categories (*)
      `
      )
      .match({ jobId });
  },
};

interface LinkContactReasonModalProps {
  open: boolean;
  jobId: string;
  defaultContactReasonId?: string;
  defaultMemo?: string;
  onClickClose: (ContactReason?: string, memo?: string) => void;
}
const LinkContactReasonModal = (props: LinkContactReasonModalProps) => {
  const handleError = useHandleError();
  const notify = useNotify();
  const t = useTranslation('Session');

  const agent = React.useContext<Agent | undefined>(AgentContext);
  const opInfo = React.useContext<OperatorInfo | undefined>(
    OperatorInfoContext
  );

  const [contactReasons, setContactReasons] = React.useState<ContactReason[]>(
    []
  );
  const [checkedContactReasonId, setCheckedContactReasonId] = React.useState<
    string | undefined
  >(props.defaultContactReasonId);
  const [memo, setMemo] = React.useState<string | undefined>(props.defaultMemo);

  const generateCheckboxes = () => {
    const categoryIds = Array.from(
      new Set(contactReasons.map((r) => r.contactReasonCategoryId))
    );

    return categoryIds.map((id) => {
      const childReasons = contactReasons.filter(
        (r) => r.contactReasonCategoryId === id
      );
      return (
        <ContactReasonCategory
          key={id}
          title={childReasons[0].category.name}
          contactReasons={childReasons}
          checkedId={checkedContactReasonId}
          onCheck={setCheckedContactReasonId}
        />
      );
    });
  };

  //データを送信↓↓
  const sendData = async () => {
    if (!checkedContactReasonId) {
      notify(t('notification.noselectedEntity'), 'warning');
      return;
    }

    props.onClickClose(checkedContactReasonId, memo);

    if (!agent || !opInfo) {
      handleError('Unknown', {
        message: 'Agent SDK has not been established.',
      });
      return;
    }
    await agent.getContextDataAccessor.registerContactReason(
      checkedContactReasonId,
      opInfo.id,
      memo
    );

    notify(t('notification.save'), 'success');
  };

  React.useEffect(() => {
    dbAccessor.getContactReasons(props.jobId).then((res) => {
      const { data, error } = res;
      if (!data || error) {
        handleError('PostgresError', { message: error?.message });
        return Promise.reject();
      }

      setContactReasons(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [0]);

  return (
    <ModalBase
      open={props.open}
      title={t('controller.reason')}
      onClickCancel={() => {
        props.onClickClose(checkedContactReasonId, memo);
      }}
      onClickApprove={sendData}
      messageApprove={'send'}
      maxWidth="xs"
      footerColor="#bbb"
    >
      {generateCheckboxes()}
      <TextBox
        style={{
          width: '100%',
          marginTop: '10px',
        }}
        label={t('registerReason.memo')}
        value={memo}
        onChange={setMemo}
      />
    </ModalBase>
  );
};

export default LinkContactReasonModal;

interface ContactReasonCategoryProps {
  title: string;
  contactReasons: ContactReason[];
  checkedId?: string;
  onCheck: (checkedId?: string) => void;
}
export const ContactReasonCategory = (props: ContactReasonCategoryProps) => {
  const { title, contactReasons, checkedId } = props;

  const handleCheck = (reasonId: string, checked: boolean) => {
    props.onCheck(checked ? reasonId : undefined);
  };

  return (
    <Accordion
      aria-controls="panel1a-content"
      id="panel1a-header"
      title={title}
    >
      <CheckBoxGroup>
        {contactReasons.map((reason) => {
          return (
            <Checkbox
              key={reason.id}
              label={reason.name}
              checked={reason.id === checkedId}
              onChange={(checked) => handleCheck(reason.id, checked)}
            />
          );
        })}
      </CheckBoxGroup>
    </Accordion>
  );
};
