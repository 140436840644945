import { useHistory } from 'react-router';
import { pageSettings } from '../../Utils/pageSettings';

const useMoveTo = () => {
  const history = useHistory();
  return (to: string, parameter?: { key: string; value: string }[]) => {
    let query: string = '';
    if (parameter) {
      query += '?';
      query += parameter.map((p) => `${p.key}=${p.value}`).join('&');
    }

    const href = pageSettings[to].path + query;
    if (history) {
      history.push(href);
    } else {
      window.location.href = href;
    }
  };
};

export default useMoveTo;
