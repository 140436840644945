import Paper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { ReactElement } from 'react';

const style = makeStyles((theme: Theme) =>
  createStyles({
    superWrapper: {
      position: 'fixed',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      zIndex: 500,
    },
    wrapper: {
      position: 'absolute',
      maxWidth: '100%',
    },
  })
);

interface ContextMenuItem {
  title: string;
  onClick?: () => void;
  icon?: ReactElement;
}

interface ContextMenuProps {
  width: number;
  left?: number;
  right?: number;
  top?: number;
  bottom?: number;
  items: ContextMenuItem[];
  className?: string;
  onClose?: () => void;
}
const ContextMenu = (props: ContextMenuProps) => {
  const classes = style();

  return (
    <div className={classes.superWrapper} onClick={props.onClose}>
      <Paper
        className={`${classes.wrapper} ${props.className}`}
        style={{
          width: props.width,
          left: props.left,
          top: props.top,
          right: props.right,
          bottom: props.bottom,
        }}
      >
        <MenuList>
          {props.items.map((it, idx) => {
            return (
              <MenuItem key={idx.toString()} onClick={it.onClick}>
                {it.icon && <ListItemIcon>{it.icon}</ListItemIcon>}
                <ListItemText>
                  <Typography variant="body1">{it.title}</Typography>
                </ListItemText>
              </MenuItem>
            );
          })}
        </MenuList>
      </Paper>
    </div>
  );
};

export default ContextMenu;
