import { definitions } from '../../../../Utils/types';
import {
  BinaryFilter,
  LogicalOrFilter,
  UnaryFilter,
} from '@cubejs-client/core';
import moment from 'moment';
import { RangeChoosePeriod } from '../../../Molecules/Analytics/ChoosePeriod';
import { convertFromPeriodExp } from '../../../../Utils/PeriodConverter';

type Facet = definitions['dashboard_facets']['facet'];

export type FacetChangeListener = (ev: FacetValue<unknown>) => void;
export type BaseFilter = UnaryFilter | BinaryFilter | LogicalOrFilter;

export class FacetValue<T> {
  constructor(private _type: Facet, private _payload: T) {}

  get type() {
    return this._type;
  }

  get payload() {
    return this._payload;
  }
  set payload(val: T) {
    this._payload = val;
  }

  filter(tableName: string): BaseFilter | undefined {
    return undefined;
  }

  isDefault(): boolean {
    return true;
  }

  static create(facet: Facet, payload: unknown) {
    switch (facet) {
      case 'ContactReasonFacet':
        return new ContactReasonFacetValue(
          payload as ContactReasonFacetValuePayload
        );
      case 'DateTimeRangeFacet':
        return new DateTimeRangeFacetValue(
          payload as DateTimeRangeFacetValuePayload
        );
      case 'JobFacet':
        return new JobFacetValue(payload as JobFacetValuePayload);
      case 'OperatorFacet':
        return new OperatorFacetValue(payload as OperatorFacetValuePayload);
      case 'PathFacet':
        return new PathFacetValue(payload as PathFacetValuePayload);
      case 'ChannelFacet':
        return new ChannelFacetValue(payload as ChannelFacetValuePayload);
      case 'NoteFacet':
        return new NoteFacetValue(payload as NoteFacetValuePayload);
      case 'LocaleFacet':
        return new LocaleFacetValue(payload as LocaleFacetValuePayload);
      default:
        throw new Error('Could not found facet');
    }
  }
}

export type OperatorFacetValuePayload = { operatorIds: string[] };
export class OperatorFacetValue extends FacetValue<OperatorFacetValuePayload> {
  constructor(payload: OperatorFacetValuePayload) {
    super('OperatorFacet', payload);
  }

  filter(tableName: string): BaseFilter | undefined {
    if (this.payload.operatorIds.length === 0) {
      return undefined;
    } else {
      return {
        member: `${tableName}.contextOperator`,
        operator: 'equals',
        values: this.payload.operatorIds,
      };
    }
  }

  isDefault(): boolean {
    return this.payload.operatorIds.length === 0;
  }
}

export type DateTimeRangeFacetValuePayload = RangeChoosePeriod;
export class DateTimeRangeFacetValue extends FacetValue<DateTimeRangeFacetValuePayload> {
  constructor(payload: DateTimeRangeFacetValuePayload) {
    super('DateTimeRangeFacet', payload);
  }

  arrangeDateRange = (
    tableName: string,
    startDt: Date | undefined,
    endDt: Date | undefined
  ): BinaryFilter => {
    const format = 'YYYY-MM-DD';
    return {
      member: `${tableName}.receivedAt`,
      operator: 'inDateRange',
      values: [
        moment(startDt, format).toString(),
        moment(endDt, format).toString(),
      ],
    };
  };

  filter(tableName: string): BaseFilter | undefined {
    if (this.payload.type === 'none') {
      return undefined;
    } else if (this.payload.type === 'custom') {
      if (!this.payload.range) throw new Error('unexpected range undefined');
      const startDt = this.payload.range.start;
      const endDt = this.payload.range.end;
      return this.arrangeDateRange(tableName, startDt, endDt);
    } else {
      const [startDt, endDt] = convertFromPeriodExp(this.payload.type);
      return this.arrangeDateRange(tableName, startDt, endDt);
    }
  }

  isDefault(): boolean {
    return this.payload.type === 'none';
  }
}

export type SessionType =
  | 'cobrowse'
  | 'video'
  | 'call'
  | 'cobrowseCall'
  | 'cobrowseVideo'
  | 'other';
export type ChannelFacetValuePayload = {
  sessionTypes: SessionType[];
};
export class ChannelFacetValue extends FacetValue<ChannelFacetValuePayload> {
  constructor(payload: ChannelFacetValuePayload) {
    super('ChannelFacet', payload);
  }

  convertSessionTypeExistence(type: SessionType) {
    switch (type) {
      case 'cobrowse':
        return {
          cobrowse: true,
          call: false,
          video: false,
        };
      case 'call':
        return {
          cobrowse: false,
          call: true,
          video: false,
        };
      case 'video':
        return {
          cobrowse: false,
          call: true,
          video: true,
        };
      case 'cobrowseCall':
        return {
          cobrowse: true,
          call: true,
          video: false,
        };
      case 'cobrowseVideo':
        return {
          cobrowse: true,
          call: true,
          video: true,
        };
      default:
        throw new Error('Could not found any SessionType');
    }
  }

  static convertXAxisName(values: string[]): SessionType {
    const exp = values.slice(0, 3).join('');
    console.log(exp);
    switch (exp) {
      case '111':
        return 'cobrowseVideo';
      case '110':
        return 'cobrowseCall';
      case '011':
        return 'video';
      case '010':
        return 'call';
      case '100':
        return 'cobrowse';
      default:
        return 'other';
    }
  }

  filter(tableName: string): BaseFilter | undefined {
    if (this.payload.sessionTypes.length === 0) {
      return undefined;
    }

    const types = this.payload.sessionTypes.map(
      this.convertSessionTypeExistence
    );

    return {
      or: types.map((t) => {
        return {
          and: [
            {
              member: `${tableName}.contextSessionTypeCobrowse`,
              operator: t.cobrowse ? 'equals' : 'notEquals',
              values: ['1'],
            },
            {
              member: `${tableName}.contextSessionTypeCall`,
              operator: t.call ? 'equals' : 'notEquals',
              values: ['1'],
            },
            {
              member: `${tableName}.contextSessionTypeVideo`,
              operator: t.video ? 'equals' : 'notEquals',
              values: ['1'],
            },
          ],
        };
      }),
    };
  }

  isDefault(): boolean {
    return this.payload.sessionTypes.length === 0;
  }
}

export type ContactReasonFacetValuePayload = { reasons: string[] };
export class ContactReasonFacetValue extends FacetValue<ContactReasonFacetValuePayload> {
  constructor(payload: ContactReasonFacetValuePayload) {
    super('ContactReasonFacet', payload);
  }

  filter(tableName: string): BaseFilter | undefined {
    if (this.payload.reasons.length === 0) {
      return undefined;
    } else {
      return {
        member: `${tableName}.contextContactReason`,
        operator: 'equals',
        values: this.payload.reasons,
      };
    }
  }

  isDefault(): boolean {
    return this.payload.reasons.length === 0;
  }
}

export type JobFacetValuePayload = { checkedJobIds: string[] };
export class JobFacetValue extends FacetValue<JobFacetValuePayload> {
  constructor(payload: JobFacetValuePayload) {
    super('JobFacet', payload);
  }

  filter(tableName: string): BaseFilter | undefined {
    if (this.payload.checkedJobIds.length === 0) {
      return undefined;
    } else {
      return {
        member: `${tableName}.contextSessionInfoJob`,
        operator: 'equals',
        values: this.payload.checkedJobIds,
      };
    }
  }

  isDefault(): boolean {
    return this.payload.checkedJobIds.length === 0;
  }
}

export type NoteFacetValuePayload = { text: string };
export class NoteFacetValue extends FacetValue<NoteFacetValuePayload> {
  constructor(payload: NoteFacetValuePayload) {
    super('NoteFacet', payload);
  }

  filter(tableName: string): BaseFilter | undefined {
    if (this.payload.text === '') {
      return undefined;
    } else {
      return {
        member: `${tableName}.contextMemo`,
        operator: 'contains',
        values: this.payload.text.split(/ |　|/g),
      };
    }
  }

  isDefault(): boolean {
    return this.payload.text === '';
  }
}

export type LocaleFacetValuePayload = { locales: string[] };
export class LocaleFacetValue extends FacetValue<LocaleFacetValuePayload> {
  constructor(payload: LocaleFacetValuePayload) {
    super('LocaleFacet', payload);
  }

  filter(tableName: string): BaseFilter | undefined {
    if (this.payload.locales.length === 0) {
      return undefined;
    } else {
      return {
        member: `${tableName}.contextLocale`,
        operator: 'equals',
        values: this.payload.locales,
      };
    }
  }

  isDefault(): boolean {
    return this.payload.locales.length === 0;
  }
}

export type PathFacetValuePayload = { paths: string[] };
export class PathFacetValue extends FacetValue<PathFacetValuePayload> {
  constructor(payload: PathFacetValuePayload) {
    super('PathFacet', payload);
  }

  filter(tableName: string): BaseFilter | undefined {
    if (this.payload.paths.length === 0) {
      return undefined;
    } else {
      const pageNames = this.payload.paths.map((p) => {
        const splited = p.split('/');
        if (splited.length <= 1) {
          return '';
        } else {
          return splited[1];
        }
      });

      return {
        or: new Array(10).fill('').map((v, i) => {
          return {
            member: `${tableName}.step${i + 1}`,
            operator: 'equals',
            values: pageNames,
          };
        }),
      };
    }
  }

  isDefault(): boolean {
    return this.payload.paths.length === 0;
  }
}
