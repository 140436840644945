import {
  Edit,
  SimpleForm,
  TextInput,
  Create,
  Datagrid,
  TextField,
  DateField,
  SelectInput,
  usePermissions,
  useRedirect,
  useNotify,
  Loading,
  ReferenceField,
  ReferenceInput,
} from 'react-admin';
import useHandleOperationFailure from '../../Components/Hooks/HandleOperationFailure';
import SettingList from '../../Components/Molecules/Settings/SettingList';
import useTranslation from '../../Components/Hooks/Translate';
import useValidation from '../../Components/Hooks/Validation';
import CustomToolBar from '../../Components/Hooks/CreateCustomToolBar';
import React from 'react';
import { useState } from 'react';

export const ContactReasonCategoryList = (props: any) => {
  const { loading, permissions } = usePermissions();
  const t = useTranslation('Settings');

  if (loading) {
    return <Loading loadingPrimary="" loadingSecondary="" />;
  } else {
    return (
      <SettingList
        {...props}
        permissions={permissions}
        create={true}
        sort={{ field: 'jobId', order: 'DESC' }}
        confirmtitle={t('contact_reason_categories.confirmTitle')}
      >
        <Datagrid rowClick="edit">
          <TextField
            source="name"
            label={t('contact_reason_categories.field.name')}
          />
          <ReferenceField
            source="jobId"
            reference="jobs"
            label={t('contact_reason_categories.field.job')}
          >
            <TextField source="name" />
          </ReferenceField>
          <DateField source="createdAt" label={t('common.field.createdAt')} />
          <DateField source="updatedAt" label={t('common.field.modifiedAt')} />
        </Datagrid>
      </SettingList>
    );
  }
};

export const ContactReasonCategoryEdit = (props: any) => {
  const { loading, permissions } = usePermissions();
  const handleOperationFailure = useHandleOperationFailure();
  const t = useTranslation('Settings');
  const v = useValidation();

  const ReasonCategoryTitle = (prop: any) => {
    const title = `${t('contact_reason_categories.name')}: ${prop.record.name}`;
    return <span>{title}</span>;
  };

  if (loading) {
    return <Loading loadingPrimary="" loadingSecondary="" />;
  } else {
    const hasEdit = permissions.hasEdit('contactReasons');
    return (
      <Edit
        mutationMode="pessimistic"
        onFailure={handleOperationFailure}
        title={<ReasonCategoryTitle />}
        {...props}
      >
        <SimpleForm
          toolbar={
            <CustomToolBar
              permissions={permissions}
              component="contact_reason_categories"
              record={props.record}
              options={props.options}
            />
          }
        >
          <TextInput source="id" disabled={true} label={t('users.field.id')} />
          <TextInput
            source="name"
            disabled={!hasEdit}
            label={t('contact_reason_categories.field.name')}
            validate={v.category}
          />
          <ReferenceInput
            source="jobId"
            reference="jobs"
            disabled={!hasEdit}
            label={t('contact_reason_categories.field.job')}
            validate={v.job}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        </SimpleForm>
      </Edit>
    );
  }
};

export const ContactReasonCategoryCreate = (props: any) => {
  const handleOperationFailure = useHandleOperationFailure();
  const t = useTranslation('Settings');
  const v = useValidation();
  const redirect = useRedirect();
  const notify = useNotify();

  const ReasonCategoryTitle = (prop: any) => {
    return (
      <span>
        {t('contact_reason_categories.name')}: {prop.record.name}
      </span>
    );
  };

  return (
    <Create
      onSuccess={() => {
        notify('ra.notification.created', 'info', { smart_count: 1 });
        redirect(`/contact_reason_categories`);
      }}
      onFailure={handleOperationFailure}
      title={<ReasonCategoryTitle />}
      {...props}
    >
      <SimpleForm>
        <TextInput
          source="name"
          label={t('contact_reason_categories.field.name')}
          validate={v.category}
        />
        <ReferenceInput
          source="jobId"
          reference="jobs"
          label={t('contact_reasons.field.job')}
          validate={v.job}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
