import * as React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Typography } from '@material-ui/core';

interface DropDownData {
  key: string;
  value: string;
}

interface DropDownProps {
  id: string;
  label?: string;
  variant?: 'filled' | 'standard' | 'outlined';
  defaultKey?: string;
  data: DropDownData[];
  className?: string;
  onChange?: (data?: DropDownData) => void;
}
const DropDown = (props: DropDownProps) => {
  const [selected, setSelected] = React.useState(props.defaultKey || '');

  React.useEffect(() => {
    setSelected(props.defaultKey || '');
  }, [props.defaultKey]);

  const onChange = (event: any) => {
    const key = event.target.value;
    setSelected(key);
    const selectedData = props.data.find((d) => d.key === key);
    props.onChange?.(selectedData);
  };

  return (
    <FormControl fullWidth variant={props.variant || 'filled'}>
      <InputLabel id={props.id}>
        <Typography variant="h6">{props.label}</Typography>
      </InputLabel>
      <Select
        labelId={props.id}
        value={selected}
        label={props.label}
        onChange={onChange}
        className={props.className}
      >
        {props.data.map((d) => {
          return (
            <MenuItem key={d.key} value={d.key}>
              <Typography variant="body1">{d.value}</Typography>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
export default DropDown;
