import * as React from 'react';
import { useState } from 'react';
import Loading from '../Components/Atoms/Loading';
import TextBox from '../Components/Atoms/TextBox';
import Button from '../Components/Atoms/Button';
import { Typography } from '@material-ui/core';
import useTranslation from '../Components/Hooks/Translate';
import { supabase } from '../Utils/supabase';
import { useNotify } from '../Components/Hooks/Notify';

const Forgot = () => {
  const [email, setEmail] = useState('');
  const [isSend, setIsSend] = useState(false);
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  const t = useTranslation('Auth');
  const notifyForResetPassword = async () => {
    const { data, error } = await supabase.auth.api.resetPasswordForEmail(
      email,
      {
        redirectTo: '/register',
      }
    );
    if (!data || error) {
      return Promise.reject();
    } else {
      return Promise.resolve();
    }
  };

  const onClickSend = async () => {
    if (!email) {
      return;
    }
    try {
      setLoading(true);
      await notifyForResetPassword();
      setLoading(false);
      setIsSend(true);
    } catch (e) {
      notify(t('forgot.invaridemail'), 'warning');
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
        textAlign: 'center',
        width: '330px',
      }}
    >
      {!isSend ? (
        <form noValidate autoComplete="off">
          <Typography>{t('forgot.inputemail')}</Typography>
          <TextBox
            id="email"
            label={t('forgot.email')}
            value={email}
            onChange={setEmail}
          />
          <Button
            style={{ width: '200px', marginTop: '1em' }}
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={onClickSend}
          >
            <Typography>{t('common.send')}</Typography>
          </Button>
          {loading && <Loading />}
        </form>
      ) : (
        <Typography>{t('forgot.checkinbox')}</Typography>
      )}
    </div>
  );
};

export default Forgot;
