import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { styled, useTheme } from '@mui/material/styles';
import BackgroundTheme from '../../../Styles/BackgroundTheme';
import { CSSProperties } from '@material-ui/styles';

const styles = makeStyles({
  marginTop: {
    '&>div': {
      marginTop: '60px',
    },
  },
});

interface AnalyticsDrawerSideMenuProps {
  children: React.ReactNode;
  drawerWidth: number;
  open: boolean;
  onClick: () => void;
}

const AnalyticsDrawerSideMenu = (props: AnalyticsDrawerSideMenuProps) => {
  const classes = styles();
  const theme = useTheme();

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...(theme.mixins.toolbar as CSSProperties),
    justifyContent: 'flex-end',
  }));

  return (
    <Drawer
      sx={{
        width: props.drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: props.drawerWidth,
          boxSizing: 'border-box',
        },
        overflow: 'hidden',
      }}
      variant="persistent"
      anchor="left"
      open={props.open}
      className={classes.marginTop}
      style={{ border: 'none' }}
    >
      <DrawerHeader
        style={{
          backgroundColor: BackgroundTheme.palette.primary.main,
          minHeight: '65px',
          position: 'fixed',
          width: '280px',
        }}
      >
        <IconButton onClick={props.onClick}>
          {theme.direction === 'ltr' ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </DrawerHeader>
      {props.children}
    </Drawer>
  );
};

export default AnalyticsDrawerSideMenu;
