import { HttpError, useNotify, useTranslate } from 'react-admin';

const useHandleOperationFailure = () => {
  const translate = useTranslate();
  const notify = useNotify();

  return (e: HttpError) => {
    switch (e.body.statusCode) {
      case 422:
        notify(
          translate('resource.common.error.unprocessableEntity'),
          'warning'
        );
        break;
      default:
        //notify(translate('resource.common.error.unknown'), 'error');
        const msg =
          e.body.message && e.body.message.length >= 1
            ? e.body.message[0]
            : translate('resource.common.error.unknown');
        notify(msg, 'warning');
        break;
    }
  };
};

export default useHandleOperationFailure;
