import React from 'react';
import OperatorInfo from './Utils/OperatorInfo';
import Permission from './Utils/Permission';
import Loading from './Components/Atoms/Loading';
import { Agent } from '@optipass/sdk';
import { CubeProvider } from '@cubejs-client/react';
import cubejsApi from './Utils/cubejs';
import { definitions } from './Utils/types';
import useUpdateFacetValueStore from './Components/Hooks/UpdateFacetValueStore';
import { FacetValue } from './Components/Atoms/Facets/Bases/FacetValue';

type Gadget = definitions['dashboard_gadgets']['gadget'];
type CubeResultCache = {
  type: Gadget;
  query: string;
  component: JSX.Element;
};

const contextDefaultValue = {
  operatorInfo: OperatorInfo.createGuest(),
  permission: Permission.createGuest(),
  agent: new Agent(),
  cubeResultCache: [],
  facetFilter: {
    values: [],
    updateValues: () => {
      return;
    },
  },
};

interface FacetValueProps {
  values: FacetValue<unknown>[];
  updateValues: (values: FacetValue<unknown>[]) => void;
}
export const OperatorInfoContext: React.Context<OperatorInfo | undefined> =
  React.createContext<OperatorInfo | undefined>(undefined);
export const PermissionContext: React.Context<Permission | undefined> =
  React.createContext<Permission | undefined>(contextDefaultValue.permission);
export const AgentContext: React.Context<Agent | undefined> =
  React.createContext<Agent | undefined>(contextDefaultValue.agent);
export const CubeResultCacheContext: React.Context<CubeResultCache[]> =
  React.createContext<CubeResultCache[]>([]);
export const FacetValueContext: React.Context<FacetValueProps> =
  React.createContext<FacetValueProps>(contextDefaultValue.facetFilter);

const WithContext = (props: { children?: JSX.Element }) => {
  const [operatorInfo, setOperatorInfo] = React.useState<OperatorInfo>();
  const [permission, setPermission] = React.useState<Permission>();
  const [preparedAgent, setPreparedAgent] = React.useState<Agent | undefined>();
  const facetValueStore = useUpdateFacetValueStore();

  const fetchOperatorContext = async () => {
    try {
      const op = await OperatorInfo.load();
      setOperatorInfo(op);
    } catch (e) {
      setOperatorInfo(OperatorInfo.createGuest());
    }
  };

  const fetchPermissionContext = async () => {
    try {
      const perm = await Permission.load();
      setPermission(perm);
    } catch (e) {
      setPermission(Permission.createGuest());
    }
  };

  React.useEffect(() => {
    fetchOperatorContext();
    fetchPermissionContext();
    contextDefaultValue.agent.startAccessContext().then(() => {
      setPreparedAgent(contextDefaultValue.agent);
    });
  }, []);

  if (operatorInfo && permission) {
    return (
      <OperatorInfoContext.Provider value={operatorInfo}>
        <PermissionContext.Provider value={permission}>
          <AgentContext.Provider value={preparedAgent}>
            <CubeResultCacheContext.Provider
              value={contextDefaultValue.cubeResultCache}
            >
              <CubeProvider cubejsApi={cubejsApi}>
                <FacetValueContext.Provider value={facetValueStore}>
                  {props.children}
                </FacetValueContext.Provider>
              </CubeProvider>
            </CubeResultCacheContext.Provider>
          </AgentContext.Provider>
        </PermissionContext.Provider>
      </OperatorInfoContext.Provider>
    );
  } else {
    return <Loading />;
  }
};

export default WithContext;
