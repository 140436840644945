import React from 'react';
import { AppBar } from 'react-admin';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import BackgroundTheme from '../../../Styles/BackgroundTheme';

const style = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      top: '60px',
      backgroundColor: BackgroundTheme.palette.primary.main,
      boxShadow: 'none',
    },
  })
);

const SettingsAppBar = (props: any) => {
  const classes = style();
  return <AppBar className={classes.wrapper} {...props} userMenu={false} />;
};

export default SettingsAppBar;
