import { createClient } from '@supabase/supabase-js';

export const supabase = createClient(
  process.env.REACT_APP_API_SUPABASE_ENDPOINT!,
  process.env.REACT_APP_API_SUPABASE_ANON_KEY!
);

export const supabaseEnv = {
  RESET_TOKEN_KEY_NAME: 'access_token',
};
