import { FacetValue } from '../Atoms/Facets/Bases/FacetValue';

const useUpdateFacetValueStore = () => {
  const facetValues: FacetValue<unknown>[] = [];
  const updateValues = (values: FacetValue<unknown>[]) => {
    facetValues.splice(
      0,
      facetValues.length,
      ...values.filter((v) => !v.isDefault())
    );
  };

  return {
    values: facetValues,
    updateValues,
  };
};

export default useUpdateFacetValueStore;
