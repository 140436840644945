import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const style = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
    },
    sessionPane: {
      flex: 1,
      height: '100%',
    },
    actionLogPane: {
      // width: '600px',
      width: '300px',
      height: '100%',
      overflowY: 'auto',
      overflowX: 'hidden',
      animation: '$show_pane linear 0.2s',
    },
    '@keyframes show_pane': {
      '0%': { opacity: 0 },
      '100%': { opacity: 1 },
    },
    form: {
      display: 'inline-block',
      paddingTop: '10px',
      paddingLeft: '15px',
      height: '65px',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    textbox: {
      width: '15ch',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    content: {
      height: '0px',
      width: '0px',
      boxSizing: 'border-box',
    },
    btnWrapper: {
      verticalAlign: 'bottom',
    },
    rnd: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      overflow: 'hidden',
    },
  })
);

export default style;
