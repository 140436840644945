import { Options } from 'ra-core/esm/dataProvider/fetch';
import { cacheDataProviderProxy, fetchUtils } from 'ra-core';
import { supabaseDataProvider } from 'ra-supabase';
import { supabase } from './supabase';

const resources = {
  // user_profiles: ['id', 'name', 'isAdmin','roleId', 'createdAt', 'updatedAt'],
  user_profiles: ['id', 'name', 'isAdmin', 'createdAt', 'updatedAt'],
  jobs: ['id', 'name', 'isActive', 'createdAt', 'updatedAt'],
  tenants: ['id', 'createdAt', 'updatedAt'],
  roles: [
    'id',
    'name',
    'users',
    'jobs',
    'tenants',
    'roles',
    'contactReasons',
    'dashboards',
    'createdAt',
    'updatedAt',
  ],
  contact_reasons: [
    'id',
    'name',
    'contactReasonCategoryId',
    'jobId',
    'createdAt',
    'updatedAt',
  ],
  contact_reason_categories: ['id', 'name', 'jobId', 'createdAt', 'updatedAt'],
};

export const dataProvider = cacheDataProviderProxy(
  supabaseDataProvider(supabase, resources)
);

export const fetchJson = async (url: string, options: Options = {}) => {
  const session = supabase.auth.session();
  const token = session?.access_token || '';
  if (!options.headers) {
    options.headers = new Headers();
    options.headers.set('apikey', process.env.REACT_APP_API_SUPABASE_ANON_KEY!);
    options.headers.set('Authorization', `Bearer ${token}`);
  }
  return fetchUtils.fetchJson(
    `${process.env.REACT_APP_API_GATEWAY!}${url}`,
    options
  );
};
