import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AnalyticsGadget from '../../Molecules/Analytics/AnalyticsGadget';
import ReactGridLayout, { Responsive, WidthProvider } from 'react-grid-layout';
import { Typography } from '@material-ui/core';
import { definitions } from '../../../Utils/types';
import useTranslation from '../../Hooks/Translate';
import { supabase } from '../../../Utils/supabase';
import { useHandleError } from '../../Hooks/HandleError';
import useSearchUrlParam from '../../Hooks/SearchUrlParam';
import { FacetValue } from '../../Atoms/Facets/Bases/FacetValue';
import Loading from '../../Atoms/Loading';

const ResponsiveReactGridLayout = WidthProvider(Responsive);
type DashboardGadget = definitions['dashboard_gadgets'];

const style = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      padding: theme.spacing(2),
      overflowY: 'auto',
      height: '100%',
    },
  })
);

const dbAccessor = {
  updateGadgetLayout: async (withNewLayout: DashboardGadget) => {
    const { x, y, width, height, id } = withNewLayout;
    return await supabase
      .from<DashboardGadget>('dashboard_gadgets')
      .update({
        x,
        y,
        width,
        height,
      })
      .match({ id });
  },
};
interface AnalyticsGadgetPaneProps {
  dashboardGadgets: DashboardGadget[] | null;
  defaultFacetValues: FacetValue<unknown>[];
}

const AnalyticsGadgetPane = (props: AnalyticsGadgetPaneProps) => {
  const t = useTranslation('Analytics');
  const classes = style();
  const handleError = useHandleError();
  const searchUrlParam = useSearchUrlParam();

  const isEditLayoutMode = searchUrlParam('edit') === '1';

  const createLayout = (dg: DashboardGadget, idx: number) => {
    return {
      i: dg.id,
      x: dg.x,
      y: dg.y,
      w: dg.width,
      h: dg.height,
      moved: false,
      static: !isEditLayoutMode,
    };
  };

  const createGadgetComponent = (dg: DashboardGadget) => {
    return (
      <div key={dg.id}>
        <AnalyticsGadget
          key={dg.id}
          gadget={dg.gadget}
          defaultFacetValues={props.defaultFacetValues}
          onFinishRender={() => {
            // TODO:
            return;
          }}
        />
      </div>
    );
  };

  const updateGadgetLayouts = async (layouts: ReactGridLayout.Layout[]) => {
    if (!isEditLayoutMode) return;

    const updateWorkers = layouts.map((l) => {
      return new Promise<void>(async (resolve, reject) => {
        const gadgetId = l.i;
        const objDashGadget = props.dashboardGadgets?.find(
          (dg) => dg.id === gadgetId
        );
        if (!objDashGadget) {
          reject();
          return;
        }

        objDashGadget.x = l.x;
        objDashGadget.y = l.y;
        objDashGadget.width = l.w;
        objDashGadget.height = l.h;

        const { data, error } = await dbAccessor.updateGadgetLayout(
          objDashGadget
        );

        if (!data || error) {
          reject(error);
        } else {
          resolve();
        }
      });
    });

    try {
      await Promise.all(updateWorkers);
    } catch (e: any) {
      handleError('PostgresError', { message: e || '' });
    }
  };

  if (!props.dashboardGadgets) {
    return (
      <div className={classes.wrapper}>
        <Typography variant="body1">{t('dashboard.nogadget')}</Typography>
      </div>
    );
  } else if (props.dashboardGadgets.length === 0) {
    return (
      <div className={classes.wrapper}>
        <Loading />
      </div>
    );
  } else {
    return (
      <div className={classes.wrapper}>
        <ResponsiveReactGridLayout
          className="layout"
          measureBeforeMount
          onLayoutChange={updateGadgetLayouts}
          layouts={{
            lg: props.dashboardGadgets.map(createLayout),
          }}
          useCSSTransforms={true}
        >
          {props.dashboardGadgets.map(createGadgetComponent)}
        </ResponsiveReactGridLayout>
      </div>
    );
  }
};

export default AnalyticsGadgetPane;
