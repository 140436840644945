import * as React from 'react';
import { TextField } from '@material-ui/core';
import useTranslation from '../Hooks/Translate';

interface TextBoxProps {
  label?: string;
  style?: React.CSSProperties;
  id?: string;
  value?: any;
  variant?: 'filled' | 'standard' | 'outlined';
  disabled?: boolean;
  defaultText?: string;
  multiline?: boolean;
  rows?: number;
  onChange?: (text: string) => void;
  className?: any;
  error?: boolean;
}

// const styles = makeStyles({
//   textbox: {
//   }
// });

const TextBox = (props: TextBoxProps) => {
  // const classes = styles();
  const t = useTranslation('Global');

  return (
    <TextField
      // className={classes.textbox}
      style={props.style}
      label={props.label}
      id={props.id}
      value={props.value}
      variant={props.variant}
      disabled={props.disabled}
      defaultValue={props.defaultText}
      multiline={props.multiline}
      rows={props.rows}
      onChange={(ev) => props.onChange?.(ev.target.value)}
      className={props.className}
      error={props.error}
      helperText={props.error ? t('modal.pre') : ''}
    />
  );
};

export default TextBox;
