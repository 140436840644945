import { definitions } from '../../Utils/types';
import i18n from 'i18next';

import enGadget from '../../Locales/en/Gadget.json';
import jaGadget from '../../Locales/ja/Gadget.json';

type Gadget = definitions['dashboard_gadgets']['gadget'];
type GadgetMasterData = {
  name: string;
  description: string;
  item?: any;
  implemented?: boolean;
};

const useGetGadgetProfile = () => {
  return (gadget: Gadget) => {
    switch (i18n.language) {
      case 'en':
        return (enGadget as any)[gadget] as GadgetMasterData;
      case 'ja':
        return (jaGadget as any)[gadget] as GadgetMasterData;
      default:
        return (enGadget as any)[gadget] as GadgetMasterData;
    }
  };
};

export default useGetGadgetProfile;
