/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import BackgroundTheme from '../../../Styles/BackgroundTheme';
import { Box, Typography } from '@material-ui/core';
import AnalyticsFacet from '../../Molecules/Analytics/AnalyticsFacet';
import { definitions } from '../../../Utils/types';
import useTranslation from '../../Hooks/Translate';
import { FacetValue } from '../../Atoms/Facets/Bases/FacetValue';
import Loading from '../../Atoms/Loading';

type Facet = definitions['dashboard_facets']['facet'];

const style = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      height: 'calc(100% - 65px)',
      marginTop: '65px',
    },
    wrapper: {
      backgroundColor: BackgroundTheme.palette.secondary.main,
      padding: theme.spacing(2),
      overflow: 'overlay',
      width: '100%',
      height: 'calc(100% - 60px)',
      '&::-webkit-scrollbar': {
        width: '7px',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        borderRadius: '5px',
      },
    },
  })
);

interface AnalyticsFacetPaneProps {
  facets: Facet[] | null;
  defaultValues?: FacetValue<unknown>[];
}

const AnalyticsFacetPane = (props: AnalyticsFacetPaneProps) => {
  const classes = style();
  const t = useTranslation('Analytics');

  const facetComps = props.facets?.map((f) => {
    const facetValue = props.defaultValues?.find((fv) => fv.type === f);
    return <AnalyticsFacet key={f} facet={f} defaultValue={facetValue} />;
  });

  return (
    <Box className={classes.drawer}>
      <Box className={classes.wrapper}>
        {facetComps ? (
          facetComps.length >= 1 ? (
            facetComps
          ) : (
            <Loading />
          )
        ) : (
          <Typography variant="body1">{t('dashboard.nofacet')}</Typography>
        )}
      </Box>
    </Box>
  );
};

export default AnalyticsFacetPane;
