import React, { Suspense } from 'react';
import { definitions } from '../../../Utils/types';
import ErrorBoundary from '../../Atoms/ErrorBoundary';
import { FacetValue } from '../../Atoms/Facets/Bases/FacetValue';
import Loading from '../../Atoms/Loading';

type Facet = definitions['dashboard_facets']['facet'];

export interface AnalyticsFacetProps {
  facet: Facet;
  defaultValue?: FacetValue<unknown>;
}
const AnalyticsFacet = (props: AnalyticsFacetProps) => {
  const { facet, defaultValue } = props;

  const FacetComponent = React.lazy(
    () => import(`../../Atoms/Facets/${facet}`)
  );

  return (
    <ErrorBoundary>
      <Suspense fallback={<Loading />}>
        <FacetComponent defaultValue={defaultValue} facet={facet} />
      </Suspense>
    </ErrorBoundary>
  );
};

export default AnalyticsFacet;
