import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import logo from '../../../Images/logo.png';
import logoText from '../../../Images/Optipass-text.png';
import GlobalNavigation from '../../Molecules/Global/GlobalNavigation';
import { Box } from '@material-ui/core';

const style = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      position: 'relative',
    },
    title: {
      marginTop: '14px',
      marginLeft: '14px',
      height: '30px',
    },
    left: {
      position: 'absolute',
      top: 0,
      left: 0,
      display: 'flex',
    },
    right: {
      position: 'absolute',
      top: 0,
      right: 0,
      paddingTop: '5px',
    },
    logo: {
      width: '45px',
      height: '45px',
      marginTop: '7px',
      marginLeft: '7px',
    },
  })
);

const Header = () => {
  const classes = style();

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.left}>
        <img className={classes.logo} src={logo} alt="Optipass" />
        <img className={classes.title} src={logoText} alt="Optipass" />
      </Box>

      <Box className={classes.right}>
        <GlobalNavigation />
      </Box>
    </Box>
  );
};

export default Header;
