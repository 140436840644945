import { Button, TextField } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import Loading from '../Components/Atoms/Loading';
import { Typography } from '@material-ui/core';
import useTranslation from '../Components/Hooks/Translate';
import { supabase } from '../Utils/supabase';
import useSearchUrlParam from '../Components/Hooks/SearchUrlParam';
import { useNotify } from '../Components/Hooks/Notify';

const Register = () => {
  const [errMsg, setErrMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const searchUrlParam = useSearchUrlParam();
  const t = useTranslation('Auth');
  const notify = useNotify();

  const updateUser = async (password: string | undefined) => {
    const { error } = await supabase.auth.update({
      password,
    });
    if (error) {
      return Promise.reject();
    } else {
      return Promise.resolve();
    }
  };

  useEffect(() => {
    if (Number(searchUrlParam('error_code', '#')) >= 400) {
      const errorDescription = searchUrlParam('error_description', '#');

      // エラー表示が必要なものはここで変換する
      switch (errorDescription) {
        case 'Confirmation+Token+not+found':
          setErrMsg(t('register.ConfirmationTokenNotFoundErr'));
          break;
        default:
          setErrMsg(t('register.supabaseUnknownErr'));
          break;
      }
    }
  }, []);

  const PasswordForm = () => {
    const [password, setPassword] = useState('');
    return (
      <form noValidate autoComplete="off">
        <Typography>{t('register.pleasefill')}</Typography>
        <TextField
          type="password"
          id="password"
          label={t('common.password')}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          style={{ width: '200px', marginTop: '1em' }}
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading || !password}
          onClick={async () => {
            try {
              setLoading(true);
              await updateUser(password);
              setLoading(false);
              window.location.href = '/';
            } catch (e) {
              notify(t('register.invalidPassword'), 'warning');
              setLoading(false);
            }
          }}
        >
          <Typography style={{ textTransform: 'none' }}>
            {t('common.send')}
          </Typography>
        </Button>
        {loading && <Loading />}
      </form>
    );
  };

  const DisplayError = () => (
    <>
      <Typography variant="h1">{errMsg}</Typography>
      <Typography>{t('register.pleaseAskAdmin')}</Typography>
    </>
  );

  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
        textAlign: 'center',
        width: '330px',
      }}
    >
      {errMsg ? <DisplayError /> : <PasswordForm />}
    </div>
  );
};

export default Register;
