import * as React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import BackgroundTheme from '../../../Styles/BackgroundTheme';
import MuiAppBar from '@material-ui/core/AppBar';
import { styled } from '@mui/material/styles';

// const styles = makeStyles({
//   wrapper: {},
// });

interface AnalyticsDrawerHeaderProps {
  children: React.ReactNode;
  drawerWidth: number;
  open: boolean;
  onClick: () => void;
}

const AnalyticsDrawerHeader = (props: AnalyticsDrawerHeaderProps) => {
  //   const classes = styles();

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${props.drawerWidth}px)`,
      marginLeft: `${props.drawerWidth}px`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  return (
    <AppBar
      position="fixed"
      open={props.open}
      style={{
        backgroundColor: BackgroundTheme.palette.primary.main,
        boxShadow: 'none',
        marginTop: '60px',
        zIndex: 1,
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={props.onClick}
          edge="start"
          sx={{ mr: 2, ...(props.open && { display: 'none' }) }}
        >
          <MenuIcon />
        </IconButton>
        {props.children}
      </Toolbar>
    </AppBar>
  );
};

export default AnalyticsDrawerHeader;
